import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RightOutlined } from '@ant-design/icons';
import { Form, FormInstance, Button, Input, Select, Tooltip, Radio } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';

import { IInventory, InventoryCategory, InventoryType } from 'models/inventory';
import { ITract, TractStatus } from 'models/tract';
import { IClient } from 'models/client';
import { LoanStatus, LoanType } from 'models/loan';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';
import { getInventoriesForSelectedOrg } from 'store/selectors/inventories';
import { getTracts } from 'store/selectors/tracts';
import { fetchTractsForInventory } from 'store/actions/tracts';
import { fetchLandInventories } from 'store/actions/inventories';

import { ClientAutoComplete, IClientAutoCompleteValue } from 'components/clients/autocomplete';
import { IInventoryAutoCompleteValue, InventoryAutoComplete } from 'components/inventories/autocomplete';
import { getTractByID } from 'api/tracts';
import { getClientByID } from 'api/clients';
import { getInventoryByID } from 'api/inventories';

import { INewLoanFormValues } from './formValues';

const mapStateToProps = (state: GlobalState) => ({
    orgId: getSelectedOrgShortId(state),
    landInventories: getInventoriesForSelectedOrg(state, InventoryCategory.Land),
    tractsByInventoryId: getTracts(state),
});

interface INewLoanFirstStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    landInv?: IInventory;
    tracts?: ITract[];
    client?: IClient;
    resInv?: IInventory;
    onRelatedSelected: (tracts?: ITract[], client?: IClient, resHouse?: IInventory, landInv?: IInventory) => void;
    goNext: () => void;
}

interface INewLoanFormFirstStepState {
    residentialInventories: IInventory[];
    loadingTracts: boolean;
}

class NewLoanFormFirstStepBase extends React.PureComponent<INewLoanFirstStepProps, INewLoanFormFirstStepState> {
    state: Readonly<INewLoanFormFirstStepState> = {
        residentialInventories: [],
        loadingTracts: false,
    };

    async componentDidMount() {
        await this.props.dispatch(fetchLandInventories('', InventoryType.Finance) as any);
    }

    get loanStatus() {
        return (
            <Form.Item
                name="status"
                label="Loan Status"
                extra="The status of the loan effects how we handle it. For example, if it is a draft then we won't apply late fees."
                rules={[{ required: true, message: 'Please select the item for the loan!' }]}
            >
                <Select<LoanStatus>>
                    <Select.Option key={LoanStatus.Draft} value={LoanStatus.Draft}>Draft</Select.Option>
                    <Select.Option key={LoanStatus.Active} value={LoanStatus.Active}>Active</Select.Option>
                    <Select.Option key={LoanStatus.Inactive} value={LoanStatus.Inactive}>Inactive</Select.Option>
                    <Select.Option key={LoanStatus.Late} value={LoanStatus.Late}>Late</Select.Option>
                    <Select.Option key={LoanStatus.InDefault} value={LoanStatus.InDefault}>In Default</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get loanTypeDescription() {
        return (
            <React.Fragment>
                What type of loan is this? The type determines what the loan can be attached to.&nbsp;
                <a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-loan#step1-type`} target="_blank" rel="noopener noreferrer">Click here for more information</a>.
            </React.Fragment>
        );
    }

    get loanType() {
        return (
            <Form.Item
                name="type"
                label="Loan Type"
                extra={this.loanTypeDescription}
                rules={[{ required: true, message: 'Please select the loan type.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={LoanType.Cash}>Cash</Radio.Button>
                    <Radio.Button value={LoanType.Tract}>Tract of Land</Radio.Button>
                    <Radio.Button value={LoanType.Residential}>Residential</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    //#region loan tract selection
    onInventoryChange = async (inventoryId: string) => {
        this.setState({ loadingTracts: true });
        await this.props.dispatch(fetchTractsForInventory(inventoryId) as any);
        this.props.onRelatedSelected([], this.props.client, undefined, this.props.landInventories.find((i) => i.id === inventoryId));
        this.setState({ loadingTracts: false });
    }

    get landInventorySelection() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanType.Tract) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="inventory"
                            label="Inventory Selection"
                            extra="First, select the inventory/development which the loan pertains to. Then, you can select a tract."
                            rules={[{ required: true, message: 'Please select the inventory for the loan!' }]}
                        >
                            <Select disabled={this.state.loadingTracts} onChange={this.onInventoryChange}>
                                {this.props.landInventories.map((i) => (
                                    <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    tractSelectedChange = async (tractIds: any[]) => {
        console.log('tract changed to:', tractIds);
        if (!this.props.landInv) {
            return;
        }

        if (!tractIds || !Array.isArray(tractIds) || tractIds.length === 0) {
            this.props.form.setFieldsValue({ label: '' });
            this.props.onRelatedSelected([], this.props.client, undefined, this.props.landInv);
            return;
        }

        const tracts = await Promise.all(tractIds.map((id) => getTractByID(this.props.orgId, this.props.landInv!.id, id)));
        const tractsLabel = tracts?.map((t) => t.number).join(', ') || '(none selected)';

        const tractWithOwner = tracts.find((t) => t.owner?.id !== '' || false);
        let client: IClient | undefined = undefined;
        if (tractWithOwner && tractWithOwner.owner && tractWithOwner.owner.id && !this.props.client) {
            client = await getClientByID(this.props.orgId, tractWithOwner.owner.id);

            this.props.form.setFieldsValue({ label: `${client.displayName.trim()}'s loan for ${ this.props.landInv?.name } Tract${ tracts.length === 1 ? '' : 's' } ${tractsLabel}` });
        } else if (this.props.client) {
            this.props.form.setFieldsValue({ label: `${this.props.client.displayName.trim()}'s loan for ${ this.props.landInv?.name } Tract${ tracts.length === 1 ? '' : 's' } ${tractsLabel}` });
        }

        this.props.onRelatedSelected(tracts, client || this.props.client, undefined, this.props.landInv);
    }

    get tractSelection() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.type !== curr.type || prev.inventory !== curr.inventory}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanType.Tract) {
                        return null;
                    }

                    const inventoryId = getFieldValue('inventory') as string;
                    let disabled = false;
                    if (!inventoryId) {
                        disabled = true;
                    }

                    let options: DefaultOptionType[] = [{ label: '', key: '', value: '' }];

                    if (inventoryId && this.props.tractsByInventoryId[inventoryId]) {
                        options = Object.values(this.props.tractsByInventoryId[inventoryId]).map((t) => {
                            const hasLoan = typeof t.loan.id === 'string' && t.loan.id.length !== 0;
                            let label: React.ReactNode = t.label;
                            if (hasLoan) {
                                label = (
                                    <Tooltip overlay="A loan already exists.">{t.label}</Tooltip>
                                );
                            } else if (t.status === TractStatus.Sold) {
                                label = (
                                    <Tooltip overlay="The tract is marked as sold.">{t.label}</Tooltip>
                                );
                            } else if (t.status === TractStatus.PaidOff) {
                                label = (
                                    <Tooltip overlay="The tract is sold and paid off.">{t.label}</Tooltip>
                                );
                            }

                            return {
                                value: t.id,
                                label,
                                isLeaf: true,
                                disabled: hasLoan || t.status === TractStatus.Sold || t.status === TractStatus.PaidOff,
                            };
                        });
                    }

                    return (
                        <Form.Item
                            name="tract"
                            label="Tract Selection"
                            extra="Select the tract which the contract is for. More than one can be selected."
                            rules={[{ required: true, message: 'Please select the tract(s) for the loan!' }]}
                        >
                            <Select
                                mode="multiple"
                                showSearch={false}
                                disabled={disabled || this.state.loadingTracts}
                                loading={this.state.loadingTracts}
                                options={options}
                                onChange={this.tractSelectedChange}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion loan tract selection

    residentialChanged = async (id: string) => {
        const res = await getInventoryByID(this.props.orgId, id);

        this.props.onRelatedSelected(undefined, this.props.client, res, undefined);
    }

    getResidentialInventoryValueFromEvent = (inv: IInventoryAutoCompleteValue) => {
        if (inv.id && inv.name) {
            this.residentialChanged(inv.id);
        }

        return inv;
    }

    get residentialHouseSelection() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanType.Residential) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="residential"
                            label="House Selection"
                            rules={[{ required: true, message: 'Please select the residential house for the loan!' }]}
                            getValueFromEvent={this.getResidentialInventoryValueFromEvent}
                        >
                            <InventoryAutoComplete
                                orgId={this.props.orgId}
                                category={InventoryCategory.Residential}
                                type={InventoryType.Finance}
                                withLoansDisabled
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    clientChanged = async (clientId?: string) => {
        if (!clientId) {
            if (!this.props.client) {
                return;
            }

            this.props.form.setFieldsValue({ label: '' });
            this.props.onRelatedSelected(this.props.tracts, undefined, undefined, this.props.landInv);
            return;
        }

        const loanType = this.props.form.getFieldValue('type');
        const client = await getClientByID(this.props.orgId, clientId);

        switch (loanType) {
            case LoanType.Tract:
                const tracts = this.props.tracts?.map((t) => t.number).join(', ') || '(none selected)';
                this.props.form.setFieldsValue({ label: `${client.displayName.trim()}'s loan for ${ this.props.landInv?.name } Tract${ this.props.tracts?.length === 1 ? '' : 's' } ${tracts}` });
                break;
            case LoanType.Residential:
                this.props.form.setFieldsValue({ label: `${client.displayName.trim()}'s loan for ${this.props.resInv?.name || 'property'}` });
                break;
            case LoanType.Cash:
                this.props.form.setFieldsValue({ label: `${client.displayName.trim()}'s ${loanType} loan` });
                break;
        }

        this.props.onRelatedSelected(this.props.tracts, client, this.props.resInv, this.props.landInv);
    }

    getClientValueFromEvent = (client: IClientAutoCompleteValue) => {
        if (client.id && client.displayName) {
            this.clientChanged(client.id);
        } else {
            this.clientChanged();
        }

        return client;
    }

    get ownerSelection() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    let disabled = false;
                    if (!getFieldValue('type')) {
                        disabled = true;
                    }

                    if (getFieldValue('type') === LoanType.Tract) {
                        const tractValue = this.props.form.getFieldValue('tract') as string[];
                        disabled = !tractValue || tractValue.length === 0;
                    }

                    return (
                        <Form.Item
                            name="client"
                            label="Client Selection"
                            extra="Who is the buyer? Just start typing, to search, if the client exists. Otherwise, you can add a new client via the plus icon."
                            rules={[{ required: true, message: 'Please select the item for the loan!' }]}
                            getValueFromEvent={this.getClientValueFromEvent}
                        >
                            <ClientAutoComplete orgId={this.props.orgId} disabled={disabled} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get labelInput() {
        return (
            <Form.Item name="label" label="Loan Label" rules={[{ required: true, message: 'Please select the item for the loan!' }]}>
                <Input />
            </Form.Item>
        );
    }

    get nextButton() {
        const { getFieldValue } = this.props.form;

        const status = getFieldValue('status') as LoanStatus;
        const loanType = getFieldValue('type') as LoanType;
        const landInvVal = getFieldValue('inventory') as string;
        const tractValue = getFieldValue('tract') as string[];
        const resHouseValue = getFieldValue('residential') as IInventoryAutoCompleteValue;
        const clientValue = getFieldValue('client') as IClientAutoCompleteValue;
        const label = getFieldValue('label') as string;

        let disabled = !status || !loanType || !clientValue.id || !label;

        switch (loanType) {
            case LoanType.Tract:
                disabled = disabled || !landInvVal || !tractValue || tractValue.length === 0;
                break;
            case LoanType.Residential:
                disabled = disabled || !resHouseValue || !resHouseValue.id || !this.props.resInv;
                break;
            default:
                break;
        }

        return (
            <Button type="primary" onClick={this.props.goNext} disabled={disabled}>
                Next <RightOutlined />
            </Button>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.loanStatus}
                {this.loanType}

                {this.landInventorySelection}
                {this.tractSelection}
                {this.residentialHouseSelection}
                {this.ownerSelection}
                {this.labelInput}

                {this.nextButton}
            </React.Fragment>
        );
    }
}

export const NewLoanStepOne = connect(mapStateToProps)(NewLoanFormFirstStepBase);

import React, { useState, Suspense, lazy } from 'react';
import { Modal, Form, Select, notification, DatePicker, Skeleton } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { EditorState } from 'braft-editor';

import { ILoan, ILoanTransaction, LoanTransactionStatus, LoanStatus, LoanTransactionReversalReason } from 'models/loan';
import { ILoanTransactionStatusChangePayload } from 'models/payloads/loans';

import { setLoanTransactionStatus } from 'api/loans';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

const BraftEditorInput = lazy(() => import('components/misc/braftEditorInput'));

interface IFormValues {
    status: LoanTransactionStatus;
    when: Dayjs;
    failureReason: LoanTransactionReversalReason;
    failureComment?: any;
}

interface ITransactionStatusChangeModalProps {
    loan: Partial<ILoan>;
    transaction?: ILoanTransaction;
    isVisible: boolean;
    close: (saved: boolean) => Promise<void>;
}

export const TransactionStatusChangeModal: React.FC<ITransactionStatusChangeModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const onCancelClick = () => {
        props.close(false);
        form.resetFields();
    };

    const onSaveClick = () => {
        form
            .validateFields()
            .then(async (values) => {
                if (props.transaction!.status === values.status) {
                    notification.error({ message: 'Transaction status has not been changed, it is the same as it was.' });
                    return;
                }

                if (!props.loan || !props.transaction) {
                    notification.error({ message: 'Ummm you have an invalid state, please refresh.' });
                    return
                }

                const payload: Partial<ILoanTransactionStatusChangePayload> = {
                    when: values.when.toDate(),
                    status: values.status,
                };

                if (values.failureReason && values.failureComment) {
                    payload.failureReason = values.failureReason;
                    payload.failureComment = values.failureComment.toHTML();
                }

                try {
                    setSaving(true);
                    await setLoanTransactionStatus(props.loan.organization!.id, props.loan.id!, props.transaction.id, payload);
                    trackUmami('Change Loan Transaction Status');
                } catch (e) {
                    displayErrorNotification(e);
                    return;
                } finally {
                    setSaving(false);
                }

                props.close(true);
            })
            .catch((e) => {
                console.warn('failed to validate the transaction status change fields:', e);
            });
    };

    const disabledDate = (current: Dayjs | null): boolean => {
        const { loan, transaction } = props;


        if (!current || !loan || !transaction || !transaction.date) {
            return false;
        }

        const transactionDate = dayjs(transaction.date);
        if (!transactionDate.isValid()) {
            return false;
        }

        return current.isBefore(transactionDate);
    }

    return (
        <Modal
            open={props.isVisible}
            title="Transaction Status Change"
            okText="Save"
            onOk={onSaveClick}
            onCancel={onCancelClick}
            closable={!isSaving}
            forceRender
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                onFinish={onSaveClick}
                initialValues={{
                    status: props.transaction ? props.transaction.status : LoanTransactionStatus.Pending,
                    when: dayjs(),
                }}
            >
                <Form.Item
                    name="status"
                    label="Transaction Status"
                    rules={[{ required: true, message: 'Please select the new status of the transaction.' }]}
                >
                    <Select<LoanTransactionStatus> disabled={isSaving}>
                        <Select.Option key={LoanTransactionStatus.Pending}>Pending</Select.Option>
                        <Select.Option key={LoanTransactionStatus.Success}>Success</Select.Option>
                        <Select.Option key={LoanTransactionStatus.Failure}>Failure</Select.Option>
                        { props.loan.status === LoanStatus.Draft ?
                            <Select.Option key={LoanTransactionStatus.Reversed}>Reversed</Select.Option>
                        : null }
                    </Select>
                </Form.Item>

                <Form.Item name="when" label="Status Change Date" rules={[{ required: true, message: 'Date of the status change is required.' }]}>
                    <DatePicker
                        format="MM/DD/YYYY"
                        disabledDate={disabledDate}
                        disabled={isSaving}
                    />
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.status !== curr.status}>
                    {({ getFieldValue }) =>
                        getFieldValue('status') === LoanTransactionStatus.Failure ? (
                            <React.Fragment>
                                <Form.Item name="failureReason" label="Failure Reason" rules={[{ required: true, message: 'Please state the reason why the transaction was a failure.' }]}>
                                    <Select<LoanTransactionReversalReason> disabled={isSaving}>
                                        <Select.Option key={LoanTransactionReversalReason.InsufficientFunds}>Insufficient Funds</Select.Option>
                                        <Select.Option key={LoanTransactionReversalReason.CheckBounced}>Check Bounced</Select.Option>
                                        <Select.Option key={LoanTransactionReversalReason.ClericalError}>Clerical Error</Select.Option>
                                        <Select.Option key={LoanTransactionReversalReason.Other}>Other</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Suspense fallback={<Skeleton.Input block active />}>
                                    <BraftEditorInput
                                        name="failureComment"
                                        label="Reversal Comment"
                                        className="last-form-item"
                                        validateTrigger="onBlur"
                                        rules={[{
                                            required: true, message: 'Please provide more details about why the transaction was a failure and being reversed!',
                                            validator: async (rule, value: EditorState) => {
                                                if (!value || value.isEmpty()) {
                                                    throw new Error('Please provide more details about why the transaction was a failure and being reversed!');
                                                }
                                            },
                                        }]}
                                        disabled={isSaving}
                                        height={110}
                                    />
                                </Suspense>
                            </React.Fragment>
                        ) : null
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
}

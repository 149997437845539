import { post, postForm, get, put, del } from './index';

import { IOrganization, IOrganizationStripeConnect } from 'models/organization';
import {
    IOrganizationCreationPayload,
    IOrganizationPaymentMethodAddPayload,
    IOrganizationSubscribePayload,
    IOrganizationUpdateBasicDetailsPayload,
    IOrganizationUpdateBillingDetailsPayload,
    IOrganizationStripeConnectStart,
    IOrganizationSubscriptionUpdatePayload,
} from 'models/payloads/organization';
import { ISetDefaultPaymentMethodPayload } from 'models/payloads/billing';

import { FileMap, IFile } from 'models/file';
import { IFileUpdatePayload } from 'models/payloads/fileUpdate';

export const createOrganization = (payload: IOrganizationCreationPayload) => post<IOrganizationCreationPayload, IOrganization>('/organizations', payload, true);
export const updateBasicOrganizationInfo = (orgId: string, payload: IOrganizationUpdateBasicDetailsPayload) => put<IOrganizationUpdateBasicDetailsPayload, IOrganization>(`/organizations/${ orgId }`, payload, true);
export const updateBillingOrganizationInfo = (orgId: string, payload: IOrganizationUpdateBillingDetailsPayload) => put<IOrganizationUpdateBillingDetailsPayload, IOrganization>(`/organizations/${ orgId }/billing/address`, payload, true);

export const getOrganization = (orgId: string) => get<IOrganization>(`/organizations/${ orgId }`, true);

export const getOrganizationFilesAsVFS = (orgId: string) => get<FileMap>(`/organizations/${ orgId }/files?as=vfs`, true);
export const uploadOrganizationFile = (orgId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/files`, formData, true);
export const createNewOrganizationFileFolder = (orgId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const updateOrganizationFile = (orgId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/files/${ fileId }`, payload, true);
export const deleteOrganizationFile = (orgId: string, fileId: string) => del(`/organizations/${ orgId }/files/${ fileId }`, true);
export const getOrganizationFile = (orgId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/files/${ fileId }`, true);

export const createPaymentMethod = (orgId: string, payload: IOrganizationPaymentMethodAddPayload) => post<IOrganizationPaymentMethodAddPayload, any>(`/organizations/${ orgId }/billing/paymentMethods`, payload, true);
export const setPaymentMethodAsDefault = (orgId: string, payload: ISetDefaultPaymentMethodPayload) => post<ISetDefaultPaymentMethodPayload, any>(`/organizations/${ orgId }/billing/paymentMethods/set-default`, payload, true);
export const getPaymentIntentSecret = (orgId: string) => get<{ secret: string }>(`/organizations/${ orgId }/billing/paymentMethods/add`, true);
export const getPaymentIntentActionSecret = (orgId: string, invoiceId: string) => get<{ secret: string }>(`/organizations/${ orgId }/billing/invoices/${ invoiceId }/action`, true);
export const deletePaymentMethod = (orgId: string, paymentMethodId: string) => del(`/organizations/${ orgId }/billing/paymentMethods/${ paymentMethodId }`, true);

export const createSubscription = (orgId: string, payload: IOrganizationSubscribePayload) => post<IOrganizationSubscribePayload, IOrganization>(`/organizations/${ orgId }/billing/subscriptions`, payload, true);
export const updateSubscription = (orgId: string, payload: IOrganizationSubscriptionUpdatePayload) => put<IOrganizationSubscriptionUpdatePayload, void>(`/organizations/${ orgId }/billing/subscriptions`, payload, true);

//stripe connect
export const createStripeConnectLink = (orgId: string) => get<IOrganizationStripeConnectStart>(`/organizations/${ orgId }/stripe/start-link`, true);
export const syncStripeConnectAccount = (orgId: string) => get<IOrganizationStripeConnect>(`/organizations/${ orgId }/stripe`, true);

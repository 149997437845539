export interface ISSEvent<TData> {
    type: SSEventType;
    action: SSEventAction;
    data: TData;
}

export enum SSEventType {
    Notification = 'notification',
    Inventory = 'inventory',
    Client = 'client',
    Loan = 'loan',
    Organization = 'organization',
    SMS = 'sms',
}

export enum SSEventAction {
    Created = 'created',
    Updated = 'updated',
    Removed = 'removed',
}

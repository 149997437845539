import { get, post, postForm, put, del } from './index';

import type { ILoanSchedule } from 'models/loanSchedule';
import type { ILoan, ILoanTransaction, LoanStatus, ILoanPayOff, ILoanLateFeeConfig } from 'models/loan';
import type { IUpdateLoanDatesPayload, ILoanTransactionReversal, ILoanCommunicationPreferenceChangePayload, ILoanTransactionStatusChangePayload, IUpdateOnlinePaymentConfigPayload, ILoanTransactionReviseDate } from 'models/payloads/loans';
import type { IFile, FileMap } from 'models/file';
import type { INote } from 'models/notes';
import type { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import type { IRestRequest } from 'models/common/restRequest';
import type { IRestResult } from 'models/common/restResult';

export const getLoans = (orgId: string, req?: IRestRequest) => get<IRestResult<ILoan>>(`/organizations/${ orgId }/loans`, true, req);
export const createLoan = (orgId: string, loan: Partial<ILoan>) => post<Partial<ILoan>, ILoan>(`/organizations/${ orgId }/loans`, loan, true);
export const getLoanByID = (orgId: string, loanId: string) => get<ILoan>(`/organizations/${ orgId }/loans/${ loanId }`, true);
export const deleteLoanByID = (orgId: string, loanId: string) => del(`/organizations/${ orgId }/loans/${ loanId }`, true);
export const createLoanTransaction = (orgId: string, loanId: string, trans: Partial<ILoanTransaction>) => post<Partial<ILoanTransaction>, ILoan>(`/organizations/${ orgId }/loans/${ loanId }/transactions`, trans, true);
export const getLoanTransactions = (orgId: string, loanId: string, asCSV?: '' | 'simple' | 'detailed') => get<ILoanTransaction[]>(`/organizations/${ orgId }/loans/${ loanId }/transactions${ asCSV ? '?csv='+asCSV : ''}`, true);
export const getLoanAmortizationSchedule = (orgId: string, loanId: string, includeDocument?: boolean, fileOnly?: boolean) => get<ILoanSchedule>(`/organizations/${ orgId }/loans/${ loanId }/amortization-schedule${ includeDocument ? `?includeDocument=true${ fileOnly ? '&fileOnly=true' : ''}` : ''}`, true);
export const getPayOffInfo = (orgId: string, loanId: string, date: string, includeLetter?: boolean) => get<ILoanPayOff>(`/organizations/${ orgId }/loans/${ loanId }/payoff?date=${ date }${ includeLetter ? '&includeLetter=true' : ''}`, true);
export const updateOnlinePaymentConfig = (orgId: string, loanId: string, payload: IUpdateOnlinePaymentConfigPayload) => post<IUpdateOnlinePaymentConfigPayload, void>(`/organizations/${ orgId }/loans/${ loanId }/online-config`, payload, true);
export const updateLateFeeConfig = (orgId: string, loanId: string, payload: ILoanLateFeeConfig) => post<ILoanLateFeeConfig, void>(`/organizations/${ orgId }/loans/${ loanId }/late-fee-config`, payload, true);
export const updateNextDueDate = (orgId: string, loanId: string, date: string) => post<IUpdateLoanDatesPayload, undefined>(`/organizations/${ orgId }/loans/${ loanId }/dates`, { nextDueDate: date }, true);
export const setLoanStatus = (orgId: string, loanId: string, status: LoanStatus, date?: string, tractAcres?: number, tractLabel?: string) => put<{ status: LoanStatus, date?: string, tractAcres?: number, tractLabel?: string }, ILoan>(`/organizations/${ orgId }/loans/${ loanId }/status`, { status, date, tractAcres, tractLabel }, true);
export const reverseLoanTransaction = (orgId: string, loanId: string, transactionId: string, payload: ILoanTransactionReversal) => post<ILoanTransactionReversal, undefined>(`/organizations/${ orgId }/loans/${ loanId }/transactions/${ transactionId }`, payload, true);
export const reviseLoanTransactionDate = (orgId: string, loanId: string, transactionId: string, payload: ILoanTransactionReviseDate) => post<ILoanTransactionReviseDate, undefined>(`/organizations/${ orgId }/loans/${ loanId }/transactions/${ transactionId }/date`, payload, true);
export const regenerateLoanTransactionLetter = (orgId: string, loanId: string, transactionId: string) => post(`/organizations/${ orgId }/loans/${ loanId }/transactions/${ transactionId }/letter`, undefined, true);
export const setLoanTransactionStatus = (orgId: string, loanId: string, transactionId: string, payload: Partial<ILoanTransactionStatusChangePayload>) => post<Partial<ILoanTransactionStatusChangePayload>, ILoan>(`/organizations/${ orgId }/loans/${ loanId }/transactions/${ transactionId }/status`, payload, true);
export const setLoanLabel = (orgId: string, loanId: string, label: string) => put<{ label: string }, void>(`/organizations/${ orgId }/loans/${ loanId }/label`, { label }, true);
export const updateCommunicationPreference = (orgId: string, loanId: string, payload: ILoanCommunicationPreferenceChangePayload) => post<ILoanCommunicationPreferenceChangePayload, undefined>(`/organizations/${ orgId }/loans/${ loanId }/communication-prefs`, payload, true);

export const getLoanNotes = (orgId: string, loanId: string) => get<INote[]>(`/organizations/${ orgId }/loans/${ loanId }/notes`, true);
export const addLoanNote = (orgId: string, loanId: string, note: Partial<INote>) => post<Partial<INote>, INote>(`/organizations/${ orgId }/loans/${ loanId }/notes`, note, true);
export const updateLoanNote = (orgId: string, loanId: string, note: Partial<INote>) => put<Partial<INote>, INote>(`/organizations/${ orgId }/loans/${ loanId }/notes/${ note.id }`, note, true);
export const deleteLoanNote = (orgId: string, loanId: string, noteId: string) => del(`/organizations/${ orgId }/loans/${ loanId }/notes/${ noteId }`, true);

export const getLoanFilesAsVFS = (orgId: string, loanId: string) => get<FileMap>(`/organizations/${ orgId }/loans/${ loanId }/files?as=vfs`, true);
export const getLoanFile = (orgId: string, loanId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/loans/${ loanId }/files/${ fileId }`, true);
export const uploadLoanFile = (orgId: string, loanId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/loans/${ loanId }/files`, formData, true);
export const updateLoanFile = (orgId: string, loanId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/loans/${ loanId }/files/${ fileId }`, payload, true);
export const createNewLoanFileFolder = (orgId: string, loanId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/loans/${ loanId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const deleteLoanFile = (orgId: string, loanId: string, fileId: string) => del(`/organizations/${ orgId }/loans/${ loanId }/files/${ fileId }`, true);

import React from 'react';
import shortid from 'shortid';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Col, Form, FormInstance, Input, Button, notification } from 'antd';
import { DingdingOutlined, SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';

import { IUserForgotPasswordPayload } from 'models/payloads/userLogin';
import { GlobalState } from 'store';
import { getAuthState } from 'store/selectors/auth';
import { authResetPassword } from 'store/actions/auth';

import { requestPasswordReset } from 'api/auth';

interface IFormValues {
    email: string;
    token: string;
    password: string;
    confirmPassword: string;
}

const mapStateToProps = (state: GlobalState) => ({
    auth: getAuthState(state),
});

interface IForgotPasswordPathParams {
    token?: string;
    email?: string;
}

interface IForgotPasswordProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IForgotPasswordPathParams> { }

interface IForgotPasswordState {
    autoCompleteId: string;
    isProcessing: boolean;
    requestSent: boolean;
    invalidToken: boolean;
}

class ForgotPasswordBase extends React.PureComponent<IForgotPasswordProps, IForgotPasswordState> {
    state: Readonly<IForgotPasswordState> = {
        autoCompleteId: shortid.generate(),
        isProcessing: false,
        requestSent: false,
        invalidToken: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidMount() {
        if (this.props.match.params.token) {
            this.setState({ requestSent: true });
        }
    }

    handleResetSubmit = (values: IFormValues) => {
        this.setState({ isProcessing: true }, async () => {
            if (this.state.requestSent) {
                this.resetPassword();
                return;
            }

            const payload: IUserForgotPasswordPayload = {
                email: values.email,
            };

            try {
                await requestPasswordReset(payload);
            } catch {}

            setTimeout(() => {
                this.setState({ isProcessing: false, requestSent: true });
                notification.success({ message: 'Password request sent. If an account was found with the provided address, you will receive an email.' });
            }, 500);
        });
    }

    resetPassword = async () => {
        const email = this.formRef.current!.getFieldValue('email');
        const token = this.formRef.current!.getFieldValue('token');
        const password = this.formRef.current!.getFieldValue('password');

        const result = await this.props.dispatch(authResetPassword({ email, token, password }) as any);
        if (result.error) {
            this.setState({ isProcessing: false, invalidToken: true });

            if (result.error.code === 73) {
                notification.error({ message: 'Invalid token. It may have expired.' });
                return;
            } else if (result.error.code === 88) {
                notification.error({ message: 'Invalid password? huh!?' });
                return;
            } else if (result.error.code === 1) {
                notification.error({ message: 'Unknown email provided, no account found.' });
                return;
            }

            notification.error({ message: 'Internal error while logging in.' });
            return;
        }

        const { user } = this.props.auth;

        if (!user) {
            notification.error({ message: 'Failed to recover from an internal error.' });
            return;
        }

        this.props.history.push('/');
    }

    get emailInput() {
        return (
            <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to request a password reset.' }]}>
                <Input
                    size="large"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    inputMode="email"
                    prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled={this.state.isProcessing || this.state.requestSent}
                />
            </Form.Item>
        );
    }

    get tokenInput() {
        if (!this.state.requestSent) {
            return null;
        }

        return (
            <Form.Item name="token" rules={[{ required: true, message: 'A valid token is required to reset your password.' }]}>
                <Input
                    size="large"
                    placeholder="Password Reset Token"
                    autoComplete={this.state.autoCompleteId}
                    prefix={<SafetyCertificateOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled={this.state.isProcessing}
                />
            </Form.Item>
        );
    }

    get passwordInput() {
        if (!this.state.requestSent) {
            return null;
        }

        return (
            <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required.' }]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get confirmPasswordInput() {
        if (!this.state.requestSent) {
            return null;
        }

        const rules: Rule[] = [
            { required: true, message: 'A non-empty password is required.' },
            {
                message: 'Passwords must match.',
                validator: async (rule, value) => {
                    if (value === this.formRef.current!.getFieldValue('password')) {
                        return;
                    }

                    throw new Error('Passwords must match.');
                },
            },
        ];

        return (
            <Form.Item name="confirmPassword" rules={rules}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    invalidTokenButtonClick = () => {
        this.props.history.push('/auth/forgot-password');

        this.setState({
            autoCompleteId: shortid.generate(),
            isProcessing: false,
            requestSent: false,
            invalidToken: false,
        }, () => this.formRef.current!.resetFields());
    }

    get invalidTokenArea() {
        if (!this.state.invalidToken) {
            return null;
        }

        return (
            <Form.Item>
                <div className="invalid-token">
                    <Button size="large" type="primary" danger onClick={this.invalidTokenButtonClick} disabled={this.state.isProcessing}>Invalid token? Request reset again</Button>
                </div>
            </Form.Item>
        );
    }

    loginClick = () => {
        this.props.history.push('/auth/login');
    }

    get loginOrRegister() {
        return (
            <Form.Item>
                <div className="action-buttons">
                    <Button size="large" type="primary" htmlType="submit" className="submit" disabled={this.state.isProcessing} loading={this.state.isProcessing}>{this.state.requestSent ? 'Reset Password' : 'Request Reset'}</Button>
                    <Button size="large" className="sign-up" onClick={this.loginClick} disabled={this.state.isProcessing}>Log In</Button>
                </div>
            </Form.Item>
        );
    }

    render() {
        return (
            <Layout className="login-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={6}>
                        <Layout.Content className="login">
                            <Layout className="branding">
                                <DingdingOutlined /> &nbsp; <span className="name">Lendiom</span>
                            </Layout>

                            <h3>Forgot Password</h3>
                            <Form<IFormValues>
                                ref={this.formRef}
                                onFinish={this.handleResetSubmit}
                                initialValues={{
                                    token: decodeURIComponent(this.props.match.params.token || ''),
                                    email: decodeURIComponent(this.props.match.params.email || ''),
                                }}
                            >
                                {this.emailInput}
                                {this.tokenInput}
                                {this.passwordInput}
                                {this.confirmPasswordInput}
                                {this.invalidTokenArea}
                                {this.loginOrRegister}
                            </Form>
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>
            </Layout>
        );
    }
}

export const UserForgotPassword = connect(mapStateToProps)(withRouter(ForgotPasswordBase));

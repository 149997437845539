import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ApiOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { hasAccessTo } from 'store/selectors/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { createStripeConnectLink } from 'api/organizations';
import { displayErrorNotification } from 'utils/errors';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
    userCanView: hasAccessTo(PermissionFeature.OrganizationBilling, PermissionAction.Read)(state),
});

interface IStripeConnectButtonProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    size?: SizeType;
}

interface IStripeConnectButtonState {
    isLoadingConnect: boolean;
}

class StripeConnectButtonBase extends React.PureComponent<IStripeConnectButtonProps, IStripeConnectButtonState> {
    state: IStripeConnectButtonState = {
        isLoadingConnect: false,
    };

    //#region when they are connected already
    get stripeConnectDashboardPopover() {
        return (
            <div>View your account information<br />in the Stripe Connect Dashboard.</div>
        );
    }
    //#endregion when they are connected already

    //#region when they have yet to connect stripe
    get stripeConnectButtonPopover() {
        if (!this.props.selectedOrg) {
            return null;
        }

        return (
            <div>We use <a href="https://stripe.com/connect" target="_blank" rel="noopener noreferrer">Stripe Connect</a> for accepting<br />online payments on behalf of <strong>{ this.props.selectedOrg.name }</strong>.</div>
        );
    }

    onStripeConnect = () => {
        if (!this.props.selectedOrg) {
            return;
        }

        this.setState({ isLoadingConnect: true }, async () => {
            try {
                const result = await createStripeConnectLink(this.props.selectedOrg!.id);
                window.location.href = result.url;
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ isLoadingConnect: false });
            }
        });
    }
    //#endregion when they have yet to connect stripe

    render() {
        if (!this.props.selectedOrg || !this.props.selectedOrg.stripeConnect || !this.props.userCanView) {
            return null;
        }

        if (this.props.selectedOrg.stripeConnect.accountId && this.props.selectedOrg.stripeConnect.detailsSubmitted) {
            const url = `https://dashboard.stripe.com/${ process.env.NODE_ENV === 'production' ? '' : 'test/' }dashboard`;

            return (
                <Popover placement="bottom" title="Stripe Connect Dashboard" content={this.stripeConnectDashboardPopover}>
                    <Button type="default" icon={<LinkOutlined />} href={url} target="_blank" size={this.props.size || 'middle'}>Stripe Dashboard</Button>
                </Popover>
            );
        }

        return (
            <Popover placement="bottom" content={this.stripeConnectButtonPopover} title="Stripe Connect">
                <Button
                    type="default"
                    onClick={this.onStripeConnect}
                    icon={<ApiOutlined />}
                    loading={this.state.isLoadingConnect}
                    disabled={this.state.isLoadingConnect || !this.props.canCreate}
                    size={this.props.size || 'middle'}
                >Connect Stripe</Button>
            </Popover>
        );
    }
}

export const StripeConnectButton = connect(mapStateToProps)(StripeConnectButtonBase);

import React from 'react';
import shortid from 'shortid';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Col, Form, FormInstance, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, DingdingOutlined, MailOutlined, SafetyCertificateOutlined } from '@ant-design/icons';

import { GlobalState } from 'store';
import { getAuthState, getUser } from 'store/selectors/auth';
import { isFetchingOrg } from 'store/selectors/org';
import { authRefreshUser } from 'store/actions/auth';

import { IUserVerifyEmailPayload } from 'models/payloads/userLogin';
import { verifyEmail, resendEmailVerification } from 'api/auth';

import { displayErrorNotification } from 'utils/errors';

import './verify-email.css';

interface IFormValues {
    email: string;
    token: string;
}

const mapStateToProps = (state: GlobalState) => ({
    auth: getAuthState(state),
    isFetchingOrg: isFetchingOrg(state),
    loggedInUser: getUser(state),
});

interface IVerifyEmailRouteProps {
    token?: string;
}

interface IVerifyEmailProps extends ReturnType<typeof mapStateToProps>, RouteComponentProps<IVerifyEmailRouteProps>, DispatchProp { }

interface IVerifyEmailState {
    autoCompleteId: string;
    isVerifying: boolean;
}

class VerifyEmailBase extends React.PureComponent<IVerifyEmailProps, IVerifyEmailState> {
    state: Readonly<IVerifyEmailState> = {
        autoCompleteId: shortid.generate(),
        isVerifying: false,
    };

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidMount() {
        if (!this.props.loggedInUser) {
            console.log('no user found, redirecting to login page');
            window.localStorage.clear();
            this.props.history.push('/auth/login');
            return;
        }

        if (this.props.loggedInUser.emailVerified) {
            console.log('user found and email verified, taking them to the home page');
            this.props.history.push('/');
            return;
        }

        this.setState({ autoCompleteId: shortid.generate() }, () => {
            if (this.props.match.params.token) {
                this.submitForVerification();
            }
        });
    }

    handleSubmit = async () => {
        try {
            await this.formRef.current!.validateFields();

            this.submitForVerification();
        } catch (e) {
            console.warn('error while validating form fields', e);
        }
    }

    submitForVerification = () => {
        this.setState({ isVerifying: true }, async () => {
            const payload: IUserVerifyEmailPayload = {
                email: this.props.loggedInUser!.email,
                token: this.formRef.current!.getFieldValue('token') || this.props.match.params.token || '',
            };

            try {
                await verifyEmail(payload);

                await this.props.dispatch(authRefreshUser() as any);
            } catch (e: any) {
                this.setState({ isVerifying: false });

                if (e && typeof e.code === 'number' && e.code === 73) {
                    notification.error({ message: 'Invalid email verification token.' });
                    return;
                }

                displayErrorNotification(e);
                return;
            }

            if (this.props.auth.hasError) {
                displayErrorNotification(this.props.auth.error);
                this.setState({ isVerifying: false });
                return;
            }

            notification.success({ message: 'Email successfully verified!' });

            setTimeout(() => this.props.history.push('/'), 500);
        });
    }

    onDidntGetIt = async () => {
        try {
            await this.formRef.current!.validateFields(['email']);

            this.setState({ isVerifying: true }, async () => {
                try {
                    await resendEmailVerification();
                } catch {}

                this.setState({ isVerifying: false });
                notification.success({ message: 'Verification email resent attempted. If an account was found with the provided address, you will receive an email.' });
            });
        } catch (e) {
            console.warn('error validating the email input:', e);
        }
    }

    get infoBoxDescription() {
        const text = "We sent you an email with a token to help us verify your email address. Didn't get the email? ";
        return (
            <React.Fragment>
                {text}
                <div className="resend-email" onClick={this.onDidntGetIt}>Click here and we will resend it.</div>
            </React.Fragment>
        );
    }

    get infoBox() {
        return (
            <Form.Item>
                <Alert
                    message="Email Verification Notes"
                    description={this.infoBoxDescription}
                    type="info"
                    showIcon
                />
            </Form.Item>
        );
    }

    get emailInput() {
        return (
            <Form.Item name="email">
                <Input
                    size="large"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    inputMode="email"
                    prefix={<MailOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled
                />
            </Form.Item>
        );
    }

    get tokenInput() {
        return (
            <Form.Item name="token" rules={[{ required: true, message: 'A valid token is required to verify your email.' }]}>
                <Input
                    size="large"
                    placeholder="Verification Token"
                    autoComplete={this.state.autoCompleteId}
                    prefix={<SafetyCertificateOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled={this.state.isVerifying || this.props.isFetchingOrg}
                />
            </Form.Item>
        );
    }

    get verifyEmailButton() {
        return (
            <Form.Item>
                <div className="action-buttons">
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="full-button"
                        disabled={this.state.isVerifying || this.props.isFetchingOrg}
                        loading={this.state.isVerifying || this.props.isFetchingOrg}
                        icon={<CheckOutlined />}
                    >Verify Email</Button>
                </div>
            </Form.Item>
        );
    }

    render() {
        return (
            <Layout className="login-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={6}>
                        <Layout.Content className="login">
                            <Layout className="branding">
                                <DingdingOutlined /> &nbsp; <span className="name">Lendiom</span>
                            </Layout>

                            <h3>Email Verification</h3>
                            <Form
                                ref={this.formRef}
                                onFinish={this.handleSubmit}
                                initialValues={{
                                    email: this.props.loggedInUser ? this.props.loggedInUser.email : '',
                                    token: this.props.match.params.token,
                                }}
                            >
                                {this.infoBox}
                                {this.emailInput}
                                {this.tokenInput}
                                {this.verifyEmailButton}
                            </Form>
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>
            </Layout>
        );
    }
}

export const UserVerifyEmail = connect(mapStateToProps)(withRouter(VerifyEmailBase));

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { BulbOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Card, Table, Button, Empty, Typography } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { isMobileOnly } from 'react-device-detect';

import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { wrapUnableToCreateTooltip } from 'utils/tooltipWrapper';
import { ClientStatusTag } from 'components/clients/clientStatusTag';
import { ClientsList } from 'components/clients/clientsList';
import { ActionsMenu, ActionMenuItemMap } from 'components/actions';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { IClient, ClientType, ClientStatus } from 'models/client';
import { ILookup } from 'models/common/lookup';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { getClientsForSelectedOrg, getClientsPagination, hasLoadingError, isLoadingClients } from 'store/selectors/clients';
import { fetchClients } from 'store/actions/clients';

import { setClientStatus } from 'api/clients';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { TableSearchInput } from 'components/misc/table-search-input';

import { AccessControlledWrapper, AccessControlledButton } from 'components/permissions';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
    isLoading: isLoadingClients(state),
    hasLoadingError: hasLoadingError(state),
    clients: getClientsForSelectedOrg(state),
    pagination: getClientsPagination(state),
});

interface IClientsProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IOrgIdPathParams> {}

interface IClientsState {
    page: number;
    pageSize: number;
    sortBy: string;
    displayNameSearch: string;
    accountNumberSearch: string;
    statusSearch?: ClientStatus[];
    typeSearch?: ClientType[];
}

class ClientsBase extends React.PureComponent<IClientsProps, IClientsState> {
    state: Readonly<IClientsState> = {
        page: 1,
        pageSize: 10,
        sortBy: 'displayName',
        displayNameSearch: '',
        accountNumberSearch: '',
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps: IClientsProps) {
        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            return;
        }

        this.refreshData();
    }

    refreshData = async () => {
        await this.props.dispatch(fetchClients(this.state.page, this.state.pageSize, this.state.sortBy, this.state.displayNameSearch, this.state.typeSearch, this.state.statusSearch, this.state.accountNumberSearch) as any);
    }

    columns: ColumnProps<IClient>[] = [
        {
            title: 'Name', dataIndex: 'displayName', key: 'name', defaultSortOrder: 'ascend', sorter: true,
            filterDropdown: (props) => <TableSearchInput {...props} placeholder="Search by display name" />,
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        },
        {
            title: 'Status', dataIndex: 'status', key: 'status', sorter: true,
            render: (value: ClientStatus) => <ClientStatusTag status={value} />,
            filters: [
                { text: 'Active', value: ClientStatus.Active },
                { text: 'Inactive', value: ClientStatus.Inactive },
                { text: 'Prospect', value: ClientStatus.Prospect },
                { text: 'Do Not Contact', value: ClientStatus.DoNotContact },
                { text: 'Unknown', value: ClientStatus.Unknown },
            ],
        },
        {
            title: 'Type', dataIndex: 'type', key: 'type', className: 'title-caps', sorter: true,
            filters: [
                { text: 'Individual', value: ClientType.Individual },
                { text: 'Family', value: ClientType.Family },
                { text: 'Company', value: ClientType.Company },
                { text: 'Unidentified', value: ClientType.Unidentified },
            ],
        },
        {
            title: 'Account Number', dataIndex: 'accountNumber', key: 'accountNumber',
            filterDropdown: (props) => <TableSearchInput {...props} placeholder="Search by account number" />,
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            render: (value: string) => <Typography.Paragraph style={{ marginBottom: 'unset' }} copyable={{ format: 'text/plain', text: value }}>{ value }</Typography.Paragraph>
        },
        {
            title: 'Entities', dataIndex: 'entities', key: 'entities',
            render: (value: ILookup[]) => value.map((l) => l.label).join(', '),
        },
        {
            title: '', key: 'action',
            render: (value: any, record: IClient) => <ActionsMenu<IClient> record={record} actions={this.actions} onClick={this.onActionClick} />,
        },
    ];

    actions: ActionMenuItemMap<IClient> = {
        edit: { icon: <EditOutlined />, text: 'View' },
        status: {
            icon: <BulbOutlined />, text: 'Status', isSubMenu: true,
            items: {
                active: { text: 'Active' },
                inactive: { text: 'Inactive' },
                prospect: { text: 'Prospect' },
                'do-not-contact': { text: 'Do Not Contact'},
            },
        },
    }

    onActionClick = async (client: IClient, what: string) => {
        const { orgId } = this.props.match.params;
        console.log(`Clicked on '${ what }' for the client: ${ client.displayName }`, client);

        switch (what) {
            case 'edit':
                this.props.history.push(`/${ this.props.match.params.orgId }/clients/${ client.id }`);
                break;
            case ClientStatus.Active:
                await setClientStatus(orgId, client.id, ClientStatus.Active);
                return;
            case ClientStatus.Inactive:
                await setClientStatus(orgId, client.id, ClientStatus.Inactive);
                return;
            case ClientStatus.Prospect:
                await setClientStatus(orgId, client.id, ClientStatus.Prospect);
                return;
            case ClientStatus.DoNotContact:
                await setClientStatus(orgId, client.id, ClientStatus.DoNotContact);
                return;
        }
    }

    onRow = (record: IClient) => {
        return {
            onClick: () => this.props.history.push(`/${ this.props.match.params.orgId }/clients/${ record.id }`),
        };
    }

    onClientsListPageChange = (page: number, pageSize: number) => {
        if (this.state.page === page && this.state.pageSize === pageSize) {
            return;
        }

        this.setState({ page, pageSize }, this.refreshData);
    }

    onTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<IClient> | SorterResult<IClient>[]) => {
        const toSet: Pick<IClientsState, keyof IClientsState> = {
            page: pagination.current || 1,
            pageSize: pagination.pageSize || 25,
            displayNameSearch: this.state.displayNameSearch,
            accountNumberSearch: this.state.accountNumberSearch,
            sortBy: this.state.sortBy,
        };

        if (filters && typeof filters.name !== 'undefined') {
            toSet.displayNameSearch = Array.isArray(filters.name) ? filters.name[0] as string : '';
        }

        if (filters && Array.isArray(filters.status)) {
            toSet.statusSearch = filters.status as ClientStatus[];
        }

        if (filters && Array.isArray(filters.type)) {
            toSet.typeSearch = filters.type as ClientType[];
        }

        if (filters && typeof filters.accountNumber !== 'undefined') {
            toSet.accountNumberSearch = Array.isArray(filters.accountNumber) ? filters.accountNumber[0] as string : '';
        }

        if (!Array.isArray(sorter) && sorter.column && sorter.field && !Array.isArray(sorter.field)) {
            toSet.sortBy = sorter.order === 'ascend' ? sorter.field as string : `-${ sorter.field }`;
        }

        this.setState(toSet, this.refreshData);
    }

    get clientsSorted() {
        if (this.state.sortBy.startsWith('-')) {
            const key = this.state.sortBy.replace('-', '');
            return this.props.clients.sort((a, b) => (b as any)[key].localeCompare((a as any)[key]));
        }

        return this.props.clients.sort((a, b) => (a as any)[this.state.sortBy].localeCompare((b as any)[this.state.sortBy]));
    }

    get clientsListOrTable() {
        if (isMobileOnly) {
            return (
                <ClientsList
                    isLoading={this.props.isLoading}
                    clients={this.clientsSorted}
                    pagination={{
                        defaultPageSize: 10,
                        onChange: this.onClientsListPageChange,
                        total: this.props.pagination.count,
                    }}
                />
            );
        }

        return (
            <Table<IClient>
                loading={this.props.isLoading}
                columns={this.columns}
                dataSource={this.clientsSorted}
                rowKey="id"
                rowClassName={() => 'pointer'}
                onRow={this.onRow}
                scroll={{ x: 'max-content' }}
                locale={{
                    emptyText: this.props.hasLoadingError
                    ? <Empty description={`There was an error while loading the clients for ${ this.props.selectedOrg ? this.props.selectedOrg.name : 'this organization' }`}><Button type="primary" onClick={this.refreshData}>Refresh</Button> </Empty>
                    : <Empty description={`No clients have been created yet inside ${ this.props.selectedOrg ? this.props.selectedOrg.name : 'this organization' }.`} />
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                    total: this.props.pagination.count,
                }}
                onChange={this.onTableChange}
            ></Table>
        );
    }

    get headerExtra() {
        if (!this.props.selectedOrg) {
            return null;
        }

        let button: React.ReactNode = (
            <AccessControlledButton
                type="primary"
                onClick={() => this.props.history.push(`/${ this.props.match.params.orgId }/client/new`)}
                icon={<PlusOutlined />}
                disabled={!this.props.canCreate}
                feature={PermissionFeature.Client}
                action={PermissionAction.Create}
                prevent={this.props.canCreate ? 'tooltip' : 'disable'}
            >
                New client
            </AccessControlledButton>
        );

        if (!this.props.canCreate) {
            button = wrapUnableToCreateTooltip(button, this.props.selectedOrg.billing.status);
        }

        return (
            <Button.Group>
                {button}
            </Button.Group>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.match.params.orgId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ this.props.match.params.orgId }/clients`,
                    breadcrumbName: `${ this.props.selectedOrg.name }'s Clients`
                }
            ],
        };
    }

    render() {
        const headerProps: PageContainerProps = {
            title: 'Clients',
            extra: this.headerExtra,
            breadcrumb: this.breadcrumbProps
        };

        return (
            <PageContainer {...headerProps}>
                <Row style={{ marginTop: 24 }}>
                    <Col span={24}>
                        <Card bordered={false}>
                            {this.clientsListOrTable}
                        </Card>
                    </Col>
                </Row>
            </PageContainer>
        );
    }
}

const ClientsPage = connect(mapStateToProps)(withRouter(ClientsBase));

export const Clients: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Client}
        action={PermissionAction.Read}
        children={<ClientsPage />}
    />
);


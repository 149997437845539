import { Currency } from './currency';
import { IPostalAddress } from './common/postalAddress';
import { IRelatedTo } from './common/relatedTo';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';

export interface IMail extends IObjectDates, IOrganizational {
    id: string,
    description: string,
    fileId: string,
    relatedTo: IRelatedTo,

    to: IPostalAddress,
    from: IPostalAddress,
    color: boolean,
    doubleSided: boolean,
    addressPlacement: MailAddressPlacement,
    class: MailClass,
    extraService: MailExtraService,

    cost: Currency,
    pageCount: number,
    externalInfo: IMailExternalInfo,
}

export interface IMailExternalInfo {
    letterId: string,
    status: MailStatus,
    sentDate: string,
    expectedDeliveryDate: string,
    lastUpdatedAt: string,

    trackingNumber: string,
    trackingEvents: IMailTrackingEvent[],
}

export interface IMailTrackingEvent {
    eventId: string,
    status: MailStatus,
    location: string,
    when: string,

    certifiedActionRequired: boolean,
    certifiedEvent: string,
    certifiedDescription: string,
    certifiedNotes: string,
}

export enum MailStatus {
    Created = 'created',
    Deleted = 'deleted',
    Mailed = 'mailed',
    InTransit = 'in_transit',
    InLocalArea = 'in_local_area',
    ProcessedForDelivery = 'processed_for_delivery',
    Rerouted = 're-routed',
    ReturnToSender = 'return_to_sender',
    PickupAvailable = 'pickup_available',
    Delivered = 'delivered',
    Issue = 'issue',
    Unknown = 'unknown',
}

export enum MailAddressPlacement {
    TopFirstPage = 'top_first_page',
    InsertBlankPage = 'insert_blank_page',
}

export enum MailClass {
    Standard = 'standard',
    FirstClass = 'first_class',
}

export enum MailExtraService {
    Certified = 'certified',
    CertifiedReturnReceipt = 'certified_return_receipt',
    Registered = 'registered',
}

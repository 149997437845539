import React, { lazy, Suspense } from 'react';
import { Row, Col, Card, Spin, Skeleton } from 'antd';

import type { IRelatedTo } from 'models/common/relatedTo';
import type { FileMap, IFileData } from 'models/file';

import { loadFilesFor } from 'api/files';

import { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { AccessControlled } from 'components/permissions';
import { relatedToFeature } from 'utils/permissions';
import { displayErrorNotification } from 'utils/errors';

import './filesCard.css';

const VFSBrowser = lazy(() => import('./VFSBrowser'));

interface IFilesCardProps {
    orgId: string;
    flat?: boolean;
    relatedTo: IRelatedTo;
    relatedLabel?: string;
};

interface IFilesCardState {
    isLoading: boolean;
    files: FileMap;
}

class FilesCardBase extends React.PureComponent<IFilesCardProps, IFilesCardState> {
    state: Readonly<IFilesCardState> = {
        isLoading: true,
        files: {},
    }

    componentDidMount() {
        this.loadFiles();
    }

    loadFiles = async () => {
        try {
            this.setState({ isLoading: true });

            const files = await loadFilesFor(this.props.orgId, this.props.relatedTo);

            this.setState({ files });
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    setLoading = (loading: boolean) => {
        this.setState({ isLoading: loading });
    }

    // handleRenameFileOrFolder = (oldKey: string, newKey: string) => {
    //     const file = this.state.filesMap[oldKey];

    //     if (!file) {
    //         return;
    //     }

    //     if (file.isFolder && file.visiblePath.endsWith('Featured Photos/')) {
    //         message.warning('Renaming the Featured Photos folder is not allowed.');
    //         return;
    //     }

    //     if (file.isFolder && file.visiblePath.endsWith('Files/')) {
    //         message.warning('Renaming the "Files/" folder is not allowed.');
    //         return;
    //     }

    //     if (this.props.relatedTo.type === RelatedToType.ORGANIZATION && !oldKey.startsWith('Files/')) {
    //         message.warning(`Can not create a folder inside of "${oldKey}" in this view.`);
    //         return;
    //     }

    //     this.setState({ isSpinning: true }, async () => {
    //         try {
    //             switch (this.props.relatedTo.type) {
    //                 case RelatedToType.ORGANIZATION:
    //                     await updateOrganizationFile(this.props.orgId, file.id, { visiblePath: newKey });
    //                     break;
    //                 case RelatedToType.PROJECT:
    //                     await updateInventoryFile(this.props.orgId, this.props.relatedTo.id, file.id, { visiblePath: newKey });
    //                     break;
    //                 case RelatedToType.TRACK:
    //                     // const related = this.props.relatedTo as ITract;
    //                     await updateTractFile(this.props.orgId, this.props.relatedTo.parentId!, this.props.relatedTo.id, file.id, { visiblePath: newKey });
    //                     break;
    //                 case RelatedToType.CLIENT:
    //                     await updateClientFile(this.props.orgId, this.props.relatedTo.id, file.id, { visiblePath: newKey });
    //                     break;
    //                 case RelatedToType.LOAN:
    //                     await updateLoanFile(this.props.orgId, this.props.relatedTo.id, file.id, { visiblePath: newKey });
    //                     break;
    //             }

    //             await this.loadFiles();
    //         } catch (e) {
    //             displayErrorNotification(e);
    //         } finally {
    //             this.setState({ isSpinning: false });
    //         }
    //     });
    // }

    get parentFolder(): IFileData {
        return {
            id: this.props.relatedTo.id,
            parentId: '',
            name: this.props.relatedLabel ? `${ this.props.relatedLabel } Files` : 'Loading...',
            isDir: true,
            draggable: false,
            droppable: false,
            preventActions: true,
        };
    }

    get newFileArea() {
        return (
            <Spin spinning={this.state.isLoading}>
                <Suspense fallback={<Skeleton.Input block active style={{ height: '325px' }} />}>
                    <VFSBrowser
                        height={325}
                        loading={this.state.isLoading}
                        files={this.state.files}
                        parentFolder={this.parentFolder}
                        refresh={this.loadFiles}
                        setLoading={this.setLoading}
                    />
                </Suspense>
            </Spin>
        );
    }

    get card() {
        return (
            <Card title="Files" bordered={false}>
                {this.newFileArea}
            </Card>
        );
    }

    render() {
        return (
            <Row>
                <Col span={24}>
                    {this.props.flat ? this.newFileArea : this.card}
                </Col>
            </Row>
        );
    }
}

export const FilesCard: React.FC<IFilesCardProps> = (props) => {
    let feature: PermissionFeature | undefined = undefined;

    switch (relatedToFeature(props.relatedTo.type)) {
        case PermissionFeature.Client:
            feature = PermissionFeature.ClientFiles;
            break;
        case PermissionFeature.Inventory:
            feature = PermissionFeature.InventoryFiles;
            break;
        case PermissionFeature.Tract:
            feature = PermissionFeature.TractFiles;
            break;
        case PermissionFeature.Loan:
            feature = PermissionFeature.LoanFiles;
            break;
        default:
            return null;
    }

    return (
        <AccessControlled feature={feature} action={PermissionAction.Read}>
            <FilesCardBase
                {...props}
            />
        </AccessControlled>
    );
}

import React from 'react';
import type { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

export const breadCrumbItemRender = (route: BreadcrumbItemType, params: any, routes: BreadcrumbItemType[], paths: string[]) => {
    const last = routes.indexOf(route) === routes.length - 1;

    if (last) {
        return (
            <span>{route.breadcrumbName}</span>
        );
    }

    return (
        <Link to={route.path!}>{route.breadcrumbName}</Link>
    );
};

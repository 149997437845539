import React, { useMemo } from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { isMobileOnly } from 'react-device-detect';
import { Descriptions, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import type { ILoan } from 'models/loan';
import { LoanStatus, LoanTermsInterestAccruesDailyFirstPaymentBasis, LoanTermsInterestSchedule, LoanType } from 'models/loan';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { ILoanSchedule } from 'models/loanSchedule';

interface ILoanTabDetailsProps {
    loan: ILoan;
    schedule?: ILoanSchedule;
}

export const LoanTabDetails: FC<ILoanTabDetailsProps> = React.memo(({ loan, schedule }) => {
    const totalDueTooltip = useMemo(() => <Tooltip overlay="Total due is a sum of the late fees and other fees due plus the balance due">Total Due <InfoCircleOutlined /></Tooltip>, []);
    const paymentAmountTooltip = useMemo(() => {
        if (!loan.escrow || !loan.escrow.paymentAmount) {
            return (
                <React.Fragment>Payment Amount</React.Fragment>
            );
        }

        return (
            <Tooltip overlay="Includes the escrow payment.">Payment Amount <InfoCircleOutlined /></Tooltip>
        );
    }, [loan]);

    const onlinePaymentItems = useMemo(() => {
        if (!loan || !loan.onlinePaymentConfig) {
            return null;
        }

        if (!loan.onlinePaymentConfig.enabled) {
            return (
                <Descriptions.Item label="Online Payments">Disabled</Descriptions.Item>
            );
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Online Payments">
                    <Tooltip overlay={`Statement Descriptor: ${ loan.onlinePaymentConfig.statementDescriptor }`}>
                        Enabled
                    </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Platform Fee Payee" className="title-caps">
                    { loan.onlinePaymentConfig.platformFeePayee.replaceAll('-', ' ') }
                </Descriptions.Item>
            </React.Fragment>
        )
    }, [loan]);

    const autoDraftItems = useMemo(() => {
        if (!loan || !loan.onlinePaymentConfig || !loan.onlinePaymentConfig.autoDraft) {
            return null;
        }

        if (!loan.onlinePaymentConfig.allowAutoDraft) {
            return (
                <Descriptions.Item label="Auto Draft">Not Allowed</Descriptions.Item>
            );
        }

        const autoDraft = loan.onlinePaymentConfig.autoDraft;

        if (!autoDraft.enabled) {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Auto Draft">
                    <Tooltip overlay={`Payment amount: $${ autoDraft.paymentAmount }`}>
                        Enabled
                    </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Next Auto Draft Date"><SimpleDate date={autoDraft.nextDate} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const interestAccruesDaily = useMemo(() => {
        if (loan.terms.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Accrued Interest"><LongCurrency value={loan.balance.accruedInterest} /></Descriptions.Item>
                <Descriptions.Item label="Unpaid Interest"><LongCurrency value={loan.balance.interest} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const escrow = useMemo(() => {
        if (loan.type !== LoanType.Residential || typeof loan.escrow === 'undefined') {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Escrow Balance"><LongCurrency value={loan.escrow.balance} /></Descriptions.Item>
                <Descriptions.Item label="Escrow Payment Amount"><LongCurrency value={loan.escrow.paymentAmount} /></Descriptions.Item>
                <Descriptions.Item label="Escrow Step" className='title-caps'>{ loan.escrow.applicationStep.replace('-' , ' ') }</Descriptions.Item>
                <Descriptions.Item label="Last Escrow Entry"><SimpleDate date={loan.escrow.lastEntryDate} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    return (
        <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
            <Descriptions.Item label="Sales Price"><LongCurrency value={loan.terms.salesPrice} /></Descriptions.Item>
            <Descriptions.Item label="Down Payment"><LongCurrency value={loan.terms.downPayment} /></Descriptions.Item>
            { loan.type === LoanType.Residential ? <Descriptions.Item label="Total Adjustments"><LongCurrency value={loan.terms.adjustments} /></Descriptions.Item> : null}
            <Descriptions.Item label="Loan Amount"><LongCurrency value={loan.terms.amount!} /></Descriptions.Item>
            <Descriptions.Item label="Payment Frequency">Monthly</Descriptions.Item>
            <Descriptions.Item label="Payment Terms">{ currency(loan.terms.length, { precision: 0 }).format() } { loan.terms.lengthUnit }</Descriptions.Item>
            <Descriptions.Item label="Interest Rate">{ currency(loan.terms.rate).format() }%</Descriptions.Item>
            <Descriptions.Item label="Interest Schedule" className="title-caps">{ loan.terms.interestSchedule.replaceAll('-', ' ') }</Descriptions.Item>
            { loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="Interest Formula" className="title-caps">{ loan.terms.interestFormula ? loan.terms.interestFormula.replaceAll('/', ' / ') : '-' }</Descriptions.Item> : null }
            { loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="First Payment Basis" className="title-caps">{ loan.terms.firstPaymentBasis ? loan.terms.firstPaymentBasis.replaceAll('-', ' ') : '-' }</Descriptions.Item> : null }
            { loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate ? <Descriptions.Item label="Down Payment Date"><SimpleDate date={loan.downPaymentDate} /></Descriptions.Item> : null }
            <Descriptions.Item label="Total of Payments"><LongCurrency value={schedule?.totalPayments} tooltip /></Descriptions.Item>
            <Descriptions.Item label="Total Interest"><LongCurrency value={schedule?.totalInterest} tooltip /></Descriptions.Item>
            <Descriptions.Item label={ paymentAmountTooltip }><LongCurrency value={loan.terms.payment!} /></Descriptions.Item>
            <Descriptions.Item label="Next Due Date"><SimpleDate date={loan.nextDueDate} /></Descriptions.Item>
            <Descriptions.Item label="Last Payment Date"><SimpleDate date={loan.lastPaymentReceivedDate} /></Descriptions.Item>
            <Descriptions.Item label="Previous Due Date"><SimpleDate date={loan.previousDueDate} /></Descriptions.Item>
            <Descriptions.Item label="Remaining Principal"><LongCurrency value={loan.balance.principal} tooltip /></Descriptions.Item>
            <Descriptions.Item label="Balance Due"><LongCurrency value={loan.balance.due} /></Descriptions.Item>
            <Descriptions.Item label="Late Fees Due"><LongCurrency value={loan.balance.lateFees} /></Descriptions.Item>
            <Descriptions.Item label="Other Fees Due"><LongCurrency value={loan.balance.otherFees} /></Descriptions.Item>
            { interestAccruesDaily }
            <Descriptions.Item label={totalDueTooltip}><LongCurrency value={loan.balance.totalDue || '0'} /></Descriptions.Item>
            { loan.balance.propertyTax && loan.balance.propertyTax !== '0' ? <Descriptions.Item label="Property Tax Due">{currency(loan.balance.propertyTax).format(true)}</Descriptions.Item> : null }
            <Descriptions.Item label="Closing Date"><SimpleDate date={loan.closingDate} /></Descriptions.Item>
            <Descriptions.Item label="Communication" className="title-caps">{loan.communication.preference} ({loan.communication.automated ? 'automatic' : 'manual'})</Descriptions.Item>
            <Descriptions.Item label="Extra Application" className="title-caps">{ loan.terms.extraApplication ? loan.terms.extraApplication.replaceAll('-', ' ') : '-' }</Descriptions.Item>
            { loan.terms.wasExisting ? <Descriptions.Item label="Pre-existing Loan">Yes</Descriptions.Item> : null }
            { onlinePaymentItems }
            { autoDraftItems }
            { loan.status === LoanStatus.Repossessed ? <Descriptions.Item label="Repossessed Date"><SimpleDate date={loan.repossessedDate} /></Descriptions.Item> : null}
            { escrow }
        </Descriptions>
    )
});

import React, { Suspense, lazy } from 'react';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Typography, Tooltip, message } from 'antd';
import type { ColumnProps } from 'antd/lib/table';

import { ITemplate } from 'models/template';
import { Language, LanguageDisplay } from 'models/common/language';
import { getTemplates, delTemplate, createTemplate, updateTemplate } from 'api/templates';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { displayErrorNotification } from 'utils/errors';
import { SimpleDate } from 'utils/formatting';

const EditTemplateModal = lazy(() => import('components/templates/editModal'));

interface ITemplatesProps {
    orgId: string;
    canCreate: boolean;
}

interface ITemplatesState {
    isLoading: boolean;
    templates: ITemplate[];
    editModalVisible: boolean;
    editingTemplate?: ITemplate;
}

export default class TemplatesView extends React.PureComponent<ITemplatesProps, ITemplatesState> {
    state: Readonly<ITemplatesState> = {
        isLoading: true,
        templates: [],
        editModalVisible: false,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: ITemplatesProps) {
        if (this.props.orgId === prevProps.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = async () => {
        try {
            const templates = await getTemplates(this.props.orgId);
            this.setState({ templates, isLoading: false });
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    columns: ColumnProps<ITemplate>[] = [
        {
            title: 'Slug', key: 'slug', dataIndex: 'slug',
            render: (slug: string, tmpl: ITemplate) => (
                <React.Fragment>
                    { slug } <Tooltip overlay={ tmpl.description ? `${ tmpl.description }` : 'Unknown template.' }><QuestionCircleOutlined /></Tooltip>
                </React.Fragment>
            )
        },
        {
            title: 'Language', key: 'language', dataIndex: 'language',
            render: (language: Language) => language ? LanguageDisplay[language] : '-',
        },
        {
            title: 'Customized', key: 'system', dataIndex: 'system',
            render: (system: boolean) => system ? 'No' : 'Yes',
        },
        {
            title: 'Last Updated', key: 'lastUpdated', dataIndex: 'modifiedAt',
            render: (modifiedAt: string, record: ITemplate) => record.system ? '-' : <SimpleDate date={modifiedAt} />,
        },
        {
            title: '', key: 'actions',
            render: (nothing: any, record: ITemplate) => <ActionsMenu<ITemplate> record={record} actions={this.actions} onClick={this.onActionClick} />
        },
    ]

    actions: ActionMenuItemMap<ITemplate> = {
        edit: { icon: <EditOutlined />, text: 'Edit' },
        delete: { icon: <DeleteOutlined />, text: 'Delete', hidden: (tmpl) => tmpl.system },
    }

    onActionClick = async (template: ITemplate, what: string) => {
        console.log(`Clicked on '${ what }' for the template: ${ template.slug }`, template);

        switch (what) {
            case 'edit':
                this.setState({ editModalVisible: true, editingTemplate: template });
                return;
            case 'delete':
                if (template.system) {
                    message.error('Can not remove a system template.');
                    return;
                }

                this.setState({ isLoading: true }, async () => {
                    try {
                        await delTemplate(this.props.orgId, template.id);
                    } catch (e) {
                        displayErrorNotification(e);
                    }

                    this.loadData();
                });
                break;
        }
    }

    onEditTemplateModalClose = () => {
        this.setState({ editingTemplate: undefined, editModalVisible: false });
    }

    onEditTemplateSave = async (template: Partial<ITemplate>) => {
        console.log('result of the save is:', template);

        try {
            if (template.system) {
                await createTemplate(this.props.orgId, template);
                this.onEditTemplateModalClose();
            } else {
                await updateTemplate(this.props.orgId, template.id!, template);
                this.onEditTemplateModalClose();
            }
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            await this.loadData();
        }
    }

    tableHeader = () => {
        return (
            <div className="table-header-with-button">
                <Typography.Title level={3}>Templates</Typography.Title>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Table<ITemplate>
                    dataSource={this.state.templates}
                    loading={this.state.isLoading}
                    columns={this.columns}
                    rowKey="id"
                    pagination={{ hideOnSinglePage: true }}
                    bordered={false}
                    title={this.tableHeader}
                    scroll={{ x: 'max-content' }}
                />

                <Suspense fallback={null}>
                    <EditTemplateModal
                        visible={this.state.editModalVisible}
                        template={this.state.editingTemplate}
                        close={this.onEditTemplateModalClose}
                        save={this.onEditTemplateSave}
                    />
                </Suspense>
            </React.Fragment>
        );
    }
}

import React, { Suspense, lazy } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';

import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { wrapUnableToCreateTooltip } from 'utils/tooltipWrapper';
import { Loading } from 'components/misc/loading';


import { getUser } from 'store/selectors/auth';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { AccessControlledWrapper } from 'components/permissions/acWrapperComponent';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';
import { AccessControlledButton } from 'components/permissions';

const InventoryTable = lazy(() => import('components/inventories/inventoryTable'));

const InventoriesBase: FC = () => {
    const history = useHistory();
    const user = useSelector(getUser);
    const selectedOrg = useSelector(getSelectedOrg);
    const canCreate = useSelector(isSelectedOrgAllowedToCreate);

    if (!user || !selectedOrg) {
        return (
            <Loading />
        );
    }

    const onCreateClick = () => history.push(`/${selectedOrg.shortId}/inventory/new`);

    let button: React.ReactNode = (
        <AccessControlledButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={onCreateClick}
            disabled={!canCreate}
            feature={PermissionFeature.Inventory}
            action={PermissionAction.Create}
            prevent={canCreate ? 'tooltip' : 'disable'}
        >
            Add New
        </AccessControlledButton>
    );

    if (!canCreate) {
        button = wrapUnableToCreateTooltip(button, selectedOrg.billing.status);
    }

    const headerProps: PageContainerProps = {
        title: 'Inventory',
        extra: button,
        breadcrumb: {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ selectedOrg.shortId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ selectedOrg.shortId }/inventory`,
                    breadcrumbName: `${ selectedOrg.name }'s Inventory`,
                },
            ],
        },
    };

    return (
        <PageContainer {...headerProps}>
            <Suspense fallback={<Loading />}>
                <InventoryTable createButton={button} />
            </Suspense>
        </PageContainer>
    );
}

export const Inventories: FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Inventory}
        action={PermissionAction.Read}
        children={<InventoriesBase />}
    />
);

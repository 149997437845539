import { message } from 'antd';
import { IRelatedTo, RelatedToType } from 'models/common/relatedTo';
import { IFile } from 'models/file';
import { IFileUpdatePayload } from 'models/payloads/fileUpdate';

import { createNewOrganizationFileFolder, deleteOrganizationFile, getOrganizationFile, getOrganizationFilesAsVFS, updateOrganizationFile, uploadOrganizationFile } from './organizations';
import { createNewInventoryFileFolder, deleteInventoryFile, getInventoryFile, getInventoryFilesAsVFS, updateInventoryFile, uploadInventoryFile } from './inventories';
import { createNewTractFileFolder, deleteTractFile, getTractFile, getTractFilesAsVFS, updateTractFile, uploadTractFile } from './tracts';
import { createNewClientFileFolder, deleteClientFile, getClientFile, getClientFilesAsVFS, updateClientFile, uploadClientFile } from './clients';
import { createNewLoanFileFolder, deleteLoanFile, getLoanFile, getLoanFilesAsVFS, updateLoanFile, uploadLoanFile } from './loans';

export const loadFilesFor = async (orgId: string, related: IRelatedTo) => {
    switch (related.type) {
        case RelatedToType.ORGANIZATION:
            return getOrganizationFilesAsVFS(orgId);
        case RelatedToType.INVENTORY:
            return getInventoryFilesAsVFS(orgId, related.id);
        case RelatedToType.TRACT:
            return getTractFilesAsVFS(orgId, related.parentId!, related.id);
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            return getClientFilesAsVFS(orgId, related.id);
        case RelatedToType.MAIL:
            return getClientFilesAsVFS(orgId, related.parentId!);
        case RelatedToType.LOAN:
            return getLoanFilesAsVFS(orgId, related.id);
        default:
            message.warning(`no idea how to fetch the files for ${ related.type } 😔`);
    }

    return {};
}

export const createFolderFor = async (orgId: string, folderName: string, related: IRelatedTo, parentId?: string) => {
    switch (related.type) {
        case RelatedToType.ORGANIZATION:
            await createNewOrganizationFileFolder(orgId, folderName, parentId);
            break;
        case RelatedToType.INVENTORY:
            await createNewInventoryFileFolder(orgId, related.id, folderName, parentId);
            break;
        case RelatedToType.TRACT:
            await createNewTractFileFolder(orgId, related.parentId!, related.id, folderName, parentId);
            break;
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            await createNewClientFileFolder(orgId, related.id, folderName, parentId);
            break;
        case RelatedToType.LOAN:
            await createNewLoanFileFolder(orgId, related.id, folderName, parentId);
            break;
        case RelatedToType.MAIL:
            message.warning('Creating folders under Letters (mail) is not supported.');
            break;
        default:
            message.warning(`no idea how to create a folder for ${ related.type } 😔`);
            console.warn('no idea how to create a folder for:', related);
    }
};

export const deleteFileFor = async (orgId: string, related: IRelatedTo, fileId: string) => {
    switch (related.type) {
        case RelatedToType.ORGANIZATION:
            await deleteOrganizationFile(orgId, fileId);
            break;
        case RelatedToType.INVENTORY:
            await deleteInventoryFile(orgId, related.id, fileId);
            break;
        case RelatedToType.TRACT:
            await deleteTractFile(orgId, related.parentId!, related.id, fileId);
            break;
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            await deleteClientFile(orgId, related.id, fileId);
            break;
        case RelatedToType.MAIL:
            await deleteClientFile(orgId, related.parentId!, fileId);
            break;
        case RelatedToType.LOAN:
            await deleteLoanFile(orgId, related.id, fileId);
            break;
        default:
            message.warning(`no idea how to delete a file or folder for ${ related.type } 😔`);
            console.warn('no idea how to delete a folder/file for:', related);
    }
}

export const uploadFileFor = async (orgId: string, related: IRelatedTo, parentId: string, file: File) => {
    const data = new FormData();
    data.append('name', file.name);
    data.append('file', file);
    data.append('parentId', parentId);

    switch (related.type) {
        case RelatedToType.ORGANIZATION:
            return await uploadOrganizationFile(orgId, data);
        case RelatedToType.INVENTORY:
            return await uploadInventoryFile(orgId, related.id, data);
        case RelatedToType.TRACT:
            return await uploadTractFile(orgId, related.parentId!, related.id, data);
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            return await uploadClientFile(orgId, related.id, data);
        case RelatedToType.LOAN:
            return await uploadLoanFile(orgId, related.id, data);
        case RelatedToType.MAIL:
            message.warning('Uploading a file to the Letters (mail) folder is not allowed.');
            return;
        default:
            message.warning(`no idea how to upload a file for ${ related.type } 😔`);
            throw new Error('invalid related object type');
    }
}

export const downloadFileFor = async (orgId: string, related: IRelatedTo, fileId: string) => {
    let promise: Promise<IFile>;

    switch (related.type) {
        case RelatedToType.ORGANIZATION:
        case RelatedToType.FILLABLE_PDF:
        case RelatedToType.REPORT:
            promise = getOrganizationFile(orgId, fileId);
            break;
        case RelatedToType.INVENTORY:
            promise = getInventoryFile(orgId, related.id, fileId);
            break;
        case RelatedToType.TRACT:
            promise = getTractFile(orgId, related.parentId!, related.id, fileId);
            break;
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            promise = getClientFile(orgId, related.id, fileId);
            break;
        case RelatedToType.MAIL:
            promise = getClientFile(orgId, related.parentId!, fileId);
            break;
        case RelatedToType.LOAN:
            promise = getLoanFile(orgId, related.id, fileId);
            break;
        default:
            message.warning(`no idea how to download a file for ${ related.type } 😔`);
            return;
    }

    const result = await promise;
    window.open(result.shareableUrl, '_blank');
}

export const updateFileFor = async (orgId: string, related: IRelatedTo, fileId: string, payload: Partial<IFileUpdatePayload>) => {
    switch (related.type) {
        case RelatedToType.ORGANIZATION:
            await updateOrganizationFile(orgId, fileId, payload);
            break;
        case RelatedToType.INVENTORY:
            await updateInventoryFile(orgId, related.id, fileId, payload);
            break;
        case RelatedToType.TRACT:
            await updateTractFile(orgId, related.parentId!, related.id, fileId, payload);
            break;
        case RelatedToType.CLIENT:
        case RelatedToType.CLIENT_ENTITY:
            await updateClientFile(orgId, related.id, fileId, payload);
            break;
        case RelatedToType.MAIL:
            await updateClientFile(orgId, related.parentId!, fileId, payload);
            break;
        case RelatedToType.LOAN:
            await updateLoanFile(orgId, related.id, fileId, payload);
            break;
        default:
            message.warning(`no idea how to update a file for ${ related.type } 😔`);
            return;
    }
}

import React from 'react';
import { Modal } from 'antd';

export function displayConfirmModal(title: React.ReactNode, content: React.ReactNode, okText: React.ReactNode, cancelText: React.ReactNode): Promise<boolean> {
    return new Promise((resolve) => {
        function onOk() {
            resolve(true);
        }

        function onCancel() {
            resolve(false);
        }

        if (!okText) {
            Modal.warning({
                title,
                content,
                onOk,
                onCancel,
                okText: cancelText,
            });

            return;
        }

        Modal.confirm({
            title,
            content,
            okText,
            onOk,
            onCancel,
            cancelText,
            autoFocusButton: 'ok',
        });
    });
}

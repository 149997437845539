import { get } from './index';

import type { Currency } from 'models/currency';
import { type ILoanTerms, LoanTermsLengthUnit } from 'models/loan';
import type { ILoanSchedule } from 'models/loanSchedule';
import type { IRestRequest } from 'models/common/restRequest';

export const getAmortizationSchedule = (downPayment: Currency, salesPrice: Currency, months: number, rate: Currency, firstPaymentDate: string, escrowAmount?: string) => get<ILoanSchedule>(`/helpers/amoritzation-schedule?down_payment=${ downPayment }&sales_price=${ salesPrice }&months=${ months }&interest_rate=${ rate }&first_payment_date=${ firstPaymentDate }${ escrowAmount ? `&escrow=${ escrowAmount }` : '' }`);
export const generateAmortizationSchedule = (loanTerms: ILoanTerms, firstPaymentDate: string, escrowAmount?: string) => {
    const req: IRestRequest = {
        down_payment: loanTerms.downPayment || '0',
        sales_price: loanTerms.salesPrice,
        adjustments: loanTerms.adjustments || '0',
        interest_rate: loanTerms.rate || '0',
        first_payment_date: firstPaymentDate,
    };

    switch (loanTerms.lengthUnit) {
        case LoanTermsLengthUnit.Months:
            req.months = loanTerms.length;
            break;
        case LoanTermsLengthUnit.Years:
            req.months = loanTerms.length * 12;
            break;
        default:
            req.months = 0;
            break;
    }

    if (loanTerms.wasExisting) {
        req.existing = 'true';
        req.principal_paid = loanTerms.existing.principalPaid;
        req.interest_paid = loanTerms.existing.interestPaid;
        req.last_paid_in_full = loanTerms.existing.lastPaidInFullPayment;
        req.next_payment_date = loanTerms.existing.nextPaymentDate;
    }

    if (escrowAmount) {
        req.escrow = escrowAmount;
    }

    return get<ILoanSchedule>(`/helpers/amoritzation-schedule`, false, req);
}

export const getPaymentAmount = (salesPrice: Currency, downPayment: Currency, adjustments: Currency, months: number, rate: Currency) => get<{ paymentRounded: string }>(`/helpers/payment-amount?down_payment=${ downPayment }&sales_price=${ salesPrice }${ adjustments ? '&adjustments='+adjustments : '' }&months=${ months }&interest_rate=${ rate }`);

import React from 'react';
import { Typography } from 'antd';
import type { Currency } from 'models/currency';

export function isNumber(value: number | string): boolean {
    return typeof value === 'number';
}

//Pulled from: https://stackoverflow.com/a/15762794
export function roundTo(value: number, digits: number): number {
    if (digits === undefined) {
        digits = 0;
    }

    let multiplicator = Math.pow(10, digits);
    const n = parseFloat((value * multiplicator).toFixed(11));
    const divided = Math.round(n) / multiplicator;
    return +(divided.toFixed(digits));
}

export function sanitizeCurrency(amount: Currency, allowNegative?: boolean): Currency {
    if (!amount) {
        return '';
    }

    if (allowNegative) {
        return amount.replace(/[^-0-9.]/g, '');
    }

    return amount.replace(/[^0-9.]/g, '');
}

export function numberWithCommas(x: number, withDecimal = true) {
    let parts = Number(x).toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return withDecimal ? parts.join(".") : parts[0];
}

export const PhoneNumber: React.FC<{ value?: string, asLink?: boolean; copyable?: boolean; }> = React.memo(({ value, asLink, copyable }) => {
    if (!value) {
        return null;
    }

    const formatted = formatPhoneNumberForDisplay(value);

    if (asLink) {
        const linkFormat = formatPhoneNumberForLink(value);

        return (
            <a href={`tel:${linkFormat}`}>
                <Typography.Text copyable={copyable}>
                    {formatted}
                </Typography.Text>
            </a>
        );
    }

    return (
        <Typography.Text copyable={copyable}>{formatted}</Typography.Text>
    );
});

export function formatPhoneNumberForDisplay(phoneNumberString: string): string {
    const cleaned = phoneNumberString.replace('+1', '').replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumberString;
}

export function formatPhoneNumberForLink(phoneNumberString: string): string {
    const cleaned = phoneNumberString.replace('+1', '').replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `+1${match[1]}${match[2]}${match[3]}`;
    }

    return phoneNumberString;
}

import React, { useState } from 'react';
import { Button, Modal, Form, Input, Space, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { IClientCreationPayload } from 'models/payloads/clients';

import { ClientBillingAllowedPaymentMethods, ClientStatus, ClientType, IClient } from 'models/client';
import { createClient } from 'api/clients';
import { displayErrorNotification } from 'utils/errors';
import { Language } from 'models/common/language';

interface IQuickClientCreateModalProps {
    orgId: string;
    onCreated: (client: IClient) => void;
    disabled?: boolean;
}

interface IFormValues {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    language: Language;
}

export const QuickClientCreateModal: React.FC<IQuickClientCreateModalProps> = (props: IQuickClientCreateModalProps) => {
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm<IFormValues>();

    const onCreateClick = () => setVisible(true);
    const onCancelClick = () => setVisible(false);

    const onFormSubmit = async () => {
        let values: IFormValues;
        try {
            values = await form.validateFields();
        } catch {
            return;
        }

        try {
            setSaving(true);

            const payload: IClientCreationPayload = {
                client: {
                    displayName: `${ values.firstName } ${ values.lastName }`,
                    type: ClientType.Family,
                    status: ClientStatus.Active,
                    preferences: {
                        language: values.language,
                    },
                    billing: {
                        allowedPaymentMethods: [ClientBillingAllowedPaymentMethods.BankAccounts, ClientBillingAllowedPaymentMethods.CreditDebitCards],
                    },
                },
                entities: [{
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumbers: [{ label: 'Mobile', number: values.mobilePhone, isCellular: true }],
                }],
            };

            const client = await createClient(props.orgId, payload);

            props.onCreated(client);
            setVisible(false);
        } catch (e) {
            displayErrorNotification(e, onFormSubmit);
        } finally {
            setSaving(false);
        }
    };

    const createModal = (
        <Modal
            open={visible}
            title="Client Quick Create"
            onCancel={onCancelClick}
            onOk={onFormSubmit}
            okText="Save & Create"
            okButtonProps={{ loading: saving, disabled: saving }}
            cancelButtonProps={{ disabled: saving }}
            keyboard={false}
            maskClosable={false}
        >
            <Form<IFormValues> form={form} layout="vertical" disabled={saving}>
                <Form.Item label="Full Name" required>
                    <Space.Compact block>
                        <Form.Item noStyle name="firstName" label="first name" rules={[{ required: true }]}>
                            <Input placeholder="First Name" />
                        </Form.Item>

                        <Form.Item noStyle name="lastName" label="last name" rules={[{ required: true }]}>
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Space.Compact block>
                    <Form.Item name="mobilePhone" label="Mobile Phone" style={{ flex: 1 }} rules={[{ required: true }]}>
                        <Input type="tel" />
                    </Form.Item>

                    <Form.Item name="language" label="Language" style={{ flex: 1 }} rules={[{ required: true }]}>
                        <Select placeholder="Please select a langauge">
                            <Select.Option value={Language.English}>English <span role="img" aria-label="USA Flag">🇺🇸</span></Select.Option>
                            <Select.Option value={Language.Spanish}>Spanish <span role="img" aria-label="Mexico Flag">🇲🇽</span></Select.Option>
                        </Select>
                    </Form.Item>
                </Space.Compact>
            </Form>
        </Modal>
    );

    return (
        <React.Fragment>
            <Button icon={<PlusCircleOutlined />} onClick={onCreateClick} disabled={props.disabled} />

            { visible ? createModal : null}
        </React.Fragment>
    );
}

import React, { useState, Suspense, lazy } from 'react';

import { Modal, Form, Row, Col, Select, Divider, Descriptions, Alert, Skeleton } from 'antd';
import { UndoOutlined } from '@ant-design/icons';

import type { EditorState } from 'braft-editor';

import { ILoan, ILoanTransaction, LoanTransactionReversalReason } from 'models/loan';
import { reverseLoanTransaction } from 'api/loans';
import { trackUmami } from 'utils/umami';
import { displayErrorNotification } from 'utils/errors';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { isMobileOnly } from 'react-device-detect';

const BraftEditorInput = lazy(() => import('components/misc/braftEditorInput'));

interface IFormValues {
    reason: LoanTransactionReversalReason;
    comment: EditorState;
}

interface IReverseTransactionModalProps {
    loan: Partial<ILoan>;
    transaction?: ILoanTransaction;
    isVisible: boolean;
    close: (updated: boolean) => Promise<void>;
}

export const ReverseTransactionModal: React.FC<IReverseTransactionModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const onCancelClick = async () => {
        await props.close(false);
        form.resetFields();
        setSaving(false);
    };

    const onSaveClick = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    const { transaction, loan } = props;
                    if (!transaction || !loan) {
                        return;
                    }

                    setSaving(true);

                    transaction.reversalReason = values.reason;
                    transaction.reversalComment = values.comment.toHTML();
                    transaction.reversalCommentText = values.comment.toText();

                    await reverseLoanTransaction(loan.organization!.id, loan.id!, transaction.id, { reason: values.reason, comment: values.comment.toHTML() });
                    trackUmami('Reverse Loan Transaction');

                    await props.close(true);
                    form.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    setSaving(false);
                }
            })
            .catch((e) => {
                console.warn('failed to validate the reverse payment fields:', e);
            });
    };

    const getOnlinePaymentSection = () => {
        if (!props.loan || !props.loan.onlinePaymentConfig || !props.loan.onlinePaymentConfig.enabled) {
            return null;
        }

        if (!props.transaction || !props.transaction.paidViaStripe || !props.transaction.stripeInvoiceId) {
            return null;
        }

        return (
            <Row>
                <Col style={{ marginBottom: '15px' }}>
                    <Alert
                        type="warning"
                        showIcon
                        message="Online Payment"
                        description={
                            <div>
                                <p>This transaction is an online payment. If the payment has been processed, the platform fee of <LongCurrency value={props.transaction.totalToPlatform} /> will <strong>NOT</strong> be refunded.</p>
                                <p>Refunds use your available Stripe balance, which does not include any pending balance. If your available balance does not cover the amount of the refund, Stripe debits the remaining amount from your bank account. <a href="https://stripe.com/docs/refunds" target="_blank" rel="noopener noreferrer">For more info, click here.</a></p>
                            </div>
                        }
                    />
                </Col>
            </Row>
        );
    };

    const getTransactionDetails = () => {
        const { transaction } = props;

        if (!transaction) {
            return null;
        }

        return (
            <Row>
                <Col span={24}>
                    <Divider orientation="left">Transaction Details</Divider>
                    <Descriptions bordered column={2}>
                        <Descriptions.Item label="Date"><SimpleDate simplier date={transaction.date} /></Descriptions.Item>
                        <Descriptions.Item label="Amount"><LongCurrency value={transaction.totalAmount} /></Descriptions.Item>
                        <Descriptions.Item label="To Interest"><LongCurrency value={transaction.totalToInterest} tooltip /></Descriptions.Item>
                        <Descriptions.Item label="To Principal"><LongCurrency value={transaction.totalToPrincipal} tooltip /></Descriptions.Item>
                        <Descriptions.Item label="To Fees"><LongCurrency value={transaction.totalToFees} /></Descriptions.Item>
                        { transaction.paidViaStripe && transaction.stripeInvoiceId ? <Descriptions.Item label="To Platform"><LongCurrency value={transaction.totalToPlatform} /></Descriptions.Item> : null }
                        <Descriptions.Item label="Type" className="title-caps">{ transaction.type.replace('-', ' ') }</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        );
    }

    let width = '600px';
    if (isMobileOnly) {
        width = '100vh';
    }

    return (
        <Modal
            open={props.isVisible}
            width={width}
            title={<span><UndoOutlined /> Reverse Transaction</span>}
            okText="Undo Transaction"
            onOk={onSaveClick}
            onCancel={onCancelClick}
            closable={!isSaving}
            okButtonProps={{ disabled: isSaving, loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSaveClick}
            >
                { getOnlinePaymentSection() }

                <Row>
                    <Col span={24}>
                        <Form.Item name="reason" label="Reason" rules={[{ required: true, message: 'Please select a reason for the transaction being reversed!' }]}>
                            <Select disabled={isSaving}>
                                <Select.Option key={LoanTransactionReversalReason.BuyerRequested} value={LoanTransactionReversalReason.BuyerRequested}>Buyer Requested</Select.Option>
                                <Select.Option key={LoanTransactionReversalReason.CheckBounced} value={LoanTransactionReversalReason.CheckBounced}>Check Bounced</Select.Option>
                                <Select.Option key={LoanTransactionReversalReason.ClericalError} value={LoanTransactionReversalReason.ClericalError}>Clerical Error</Select.Option>
                                <Select.Option key={LoanTransactionReversalReason.InsufficientFunds} value={LoanTransactionReversalReason.InsufficientFunds}>Insufficient Funds</Select.Option>
                                <Select.Option key={LoanTransactionReversalReason.Other} value={LoanTransactionReversalReason.Other}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Suspense fallback={<Skeleton.Input block active />}>
                            <BraftEditorInput
                                name="comment"
                                label="Reversal Comment"
                                className="last-form-item"
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    validator: async (rule, value: EditorState) => {
                                        if (!value || value.isEmpty()) {
                                            throw new Error('Please provide more details about why the transaction is being reversed!');
                                        }
                                    },
                                }]}
                                disabled={isSaving}
                                height={110}
                            />
                        </Suspense>
                    </Col>
                </Row>

                { getTransactionDetails() }
            </Form>
        </Modal>
    );
};

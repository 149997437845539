import React, { useState } from 'react';
import { Alert, Col, DatePicker, Form, Modal, Row, Typography, theme } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';

import { ILoan, ILoanTransaction, LoanTransactionType } from 'models/loan';
import type { ILoanSchedule } from 'models/loanSchedule';

import { reviseLoanTransactionDate } from 'api/loans';
import { displayErrorNotification } from 'utils/errors';

interface IFormValues {}

interface IReviseTransactionDateModalProps {
    loan: Partial<ILoan>;
    schedule?: ILoanSchedule;
    transaction?: ILoanTransaction;
    isVisible: boolean;
    close: (updated: boolean) => Promise<void>;
}

export const ReviseTransactionDateModal: React.FC<IReviseTransactionDateModalProps> = (props) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const onCloseClick = () => {
        props.close(false);
    };

    const onSaveClick = async () => {
        if (!props.loan || !props.loan.id || !props.loan.organization || !props.transaction) {
            return;
        }

        setSaving(true);

        try {
            await reviseLoanTransactionDate(props.loan.organization.id, props.loan.id, props.transaction.id, { date: form.getFieldValue('date').toJSON() });
            await props.close(true);

            setSaving(false);
            form.resetFields();
        } catch (e) {
            displayErrorNotification(e);
            setSaving(false);
        }
    };

    function renderDate(current: Dayjs | number | null, info: CellRenderInfo<Dayjs>) {
        if (typeof current === 'undefined' || current === null) {
            return current;
        }

        if (typeof current === 'number') {
            if (!info.subType) {
                return current;
            }

            switch (info.subType) {
                case 'meridiem':
                    return current === 0 ? 'am' : 'pm';
                case 'hour':
                    return current === 0 ? '12' : current;
                default:
                    return current;
            }
        }

        if (typeof current === 'number') {
            return current;
        }

        const style: React.CSSProperties = {};

        if (props.transaction && props.schedule) {
            const forPaymentsIndexes = props.transaction.forPayments;

            if (props.transaction.type === LoanTransactionType.RegularPayment && Array.isArray(forPaymentsIndexes) && forPaymentsIndexes.length !== 0) {
                const payments = props.schedule.payments!.filter((p) => forPaymentsIndexes.includes(p.paymentIndex));

                if (payments.length !== 0) {
                    payments.forEach((p) => {
                        let dueDate = dayjs(p.dueDate);

                        if (dueDate.isSame(current, 'day')) {
                            style.border = `1px solid ${ token.green6 }`;
                        }

                        dueDate = dueDate.add(props.loan!.lateFeeConfig!.daysUntilLate, 'days');
                        if (dueDate.isSame(current, 'day')) {
                            style.border = `1px solid ${ token.red5 }`;
                        }
                    });
                }
            }

            // adds a gold border for the transaction's previously selected date
            if (dayjs(props.transaction.date).isSame(current, 'day')) {
                style.border = '1px solid #faad14';
            }
        }

        if (current.isSame(form.getFieldValue('date'), 'day')) {
            style.backgroundColor = 'rgb(24, 144, 255)';
        }

        return (
            <div className="ant-calendar-date" style={style}>
                {current.date()}
            </div>
        );
    };

    // this is for when there is a schedule
    let warningText = (
        <React.Fragment>
            <Typography.Text>You are revising the transaction's date and time only.&nbsp;</Typography.Text>
            <Typography.Text type="danger">This action does <Typography.Text type="danger" strong>not</Typography.Text> modify the amortization schedule nor the late fees due.&nbsp;</Typography.Text>
            <Typography.Text>Are you sure you want to continue? Should you wish to revise the amortization schedule, you will need to reverse the transaction.</Typography.Text>
        </React.Fragment>
    );

    if (!props.loan.hasSchedule) {
        warningText = (
            <React.Fragment>
                <Typography.Text>You are revising the transaction's date and time only.&nbsp;</Typography.Text>
                <Typography.Text type="danger">This action does <Typography.Text type="danger" strong>not</Typography.Text> modify the interest paid nor the late fees due.&nbsp;</Typography.Text>
                <Typography.Text>Are you sure you want to continue? Should you wish to revise the interest paid, you will need to reverse the transaction.</Typography.Text>
            </React.Fragment>
        );
    }

    let width = '575px';
    if (isMobileOnly) {
        width = '100vh';
    }

    return (
        <Modal
            open={props.isVisible}
            title="Revise Transaction Date"
            width={width}
            okText="Revise Date"
            closable={!isSaving}
            okButtonProps={{ disabled: isSaving || (props.transaction && props.transaction.paidViaStripe), loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
            onCancel={onCloseClick}
            onOk={onSaveClick}
        >
            <Form
                form={form}
                layout="inline"
                initialValues={{
                    date: props.transaction ? dayjs(props.transaction.date) : undefined,
                }}
            >
                <Row>
                    <Col span={24}>
                        <Alert type="warning" description={warningText} />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col span={24}>
                        <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please set the date of this transaction!' }]}>
                            <DatePicker
                                format="MM/DD/YYYY h:mm:ss a"
                                disabled={isSaving}
                                cellRender={renderDate}
                                showTime
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

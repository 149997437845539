import React, { Suspense, lazy } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StripeProvider, Elements, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Col, Form, FormInstance, Steps, Input, Select, Checkbox, Button, notification, Alert, Space, Skeleton } from 'antd';
import { DingdingOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';

import { Loading } from 'components/misc/loading';

import type { IPostalAddress } from 'models/common/postalAddress';
import type { IOrganizationCreationPayload } from 'models/payloads/organization';
import type { IRestError } from 'models/common/restResult';
import { createPaymentMethod } from 'api/organizations';
import { displayErrorNotification } from 'utils/errors';
import { stripeApiKey } from 'utils/auth';

import { GlobalState } from 'store';
import { isCreatingOrg, createdOrg, getOrgError } from 'store/selectors/org';
import { orgCreate, orgRefresh, orgClearCreated, orgCreateFailure, orgCreatePaymentMethodPending } from 'store/actions/org';
import { authLogout } from 'store/actions/auth';
import { getUser } from 'store/selectors/auth';

import { OrgCreationSecondStep } from './secondStep';
import { OrgCreationThirdStep } from './thirdStep';

import './newOrg.css';

const AddressAutoCompleteInput = lazy(() => import('components/misc/addressAutoCompleteInput'));

const mapStateToProps = (state: GlobalState) => ({
    user: getUser(state),
    isCreatingOrg: isCreatingOrg(state),
    createdOrg: createdOrg(state),
    creationErrors: getOrgError(state),
});

interface IOrgCreationProps extends ReturnType<typeof mapStateToProps>, DispatchProp, ReactStripeElements.InjectedStripeProps, RouteComponentProps {
    form: FormInstance;
}

interface IOrgCreationState {
    currentStep: number;
}

class OrgCreationBase extends React.PureComponent<IOrgCreationProps, IOrgCreationState> {
    private stepOneFields = ['name', 'phoneNumber', 'email', 'phoneNumberType', 'mailingLine1', 'mailingCity', 'mailingState', 'mailingZip', 'tosAgreed'];
    private stepTwoFields = ['billingLine1', 'billingCity', 'billingState', 'billingZip', 'creditCardName']

    state: Readonly<IOrgCreationState> = {
        currentStep: 0,
    };

    constructor(props: IOrgCreationProps) {
        super(props);

        if (props.createdOrg) {
            console.log('created organization', props.createdOrg);
            if (!props.createdOrg.billing.paymentMethods || props.createdOrg.billing.paymentMethods.length === 0) {
                this.state = { currentStep: 1 };
            } else {
                this.state = { currentStep: 2 };
            }
        }
    }

    componentDidMount() {
        if (!this.props.stripe) {
            throw new Error('failure to load Stripe');
        }
    }

    //#region next, previous, and sign up handlers
    onNextClick = async () => {
        try {
            if (this.state.currentStep === 0) {
                await this.props.form.validateFields(this.stepOneFields);
                this.setState({ currentStep: this.state.currentStep + 1 });
            } else if (this.state.currentStep === 1) {
                await this.props.form.validateFields(this.stepTwoFields);
                this.handleCreatingOrganization();
            }
        } catch (e) {
            console.warn('error validating the fields for step', this.state.currentStep, 'and the error is:', e);
        }
    }

    handleCreatingOrganization = async () => {
        if (this.props.createdOrg) {
            // the organization has already been created
            this.handleStripePaymentMethod();
            return;
        }

        const { getFieldValue } = this.props.form;

        const mailingAddress: IPostalAddress = {
            label: 'Mailing',
            streetAddresses: [getFieldValue('mailingLine1')],
            city: getFieldValue('mailingCity'),
            state: getFieldValue('mailingState'),
            zipCode: getFieldValue('mailingZip'),
        };

        if (getFieldValue('mailingLine2')) {
            mailingAddress.streetAddresses.push(getFieldValue('mailingLine2'));
        }

        let billingAddress;
        if (getFieldValue('sameAsMailing')) {
            billingAddress = mailingAddress;
        } else {
            billingAddress = {
                label: 'Billing',
                streetAddresses: [getFieldValue('billingLine1')],
                city: getFieldValue('billingCity'),
                state: getFieldValue('billingState'),
                zipCode: getFieldValue('billingZip'),
            };

            if (getFieldValue('billingLine2')) {
                billingAddress.streetAddresses.push(getFieldValue('billingLine2'));
            }
        }

        const orgCreatePayload: IOrganizationCreationPayload = {
            name: getFieldValue('name'),
            tosAgreed: getFieldValue('tosAgreed'),
            email: getFieldValue('email'),
            number: {
                label: getFieldValue('phoneNumberType'),
                number: `${getFieldValue('phoneNumber')}`,
                isCellular: getFieldValue('phoneNumberType') === 'Mobile',
            },
            addresses: {
                mailing: mailingAddress,
                billing: billingAddress,
                areSame: getFieldValue('sameAsMailing'),
            },
        };

        await this.props.dispatch(orgCreate(orgCreatePayload) as any);

        if (this.props.creationErrors.hasError) {
            displayErrorNotification(this.props.creationErrors.error);
            return;
        }

        if (!this.props.createdOrg) {
            displayErrorNotification('No error or organization was returned. Not sure what happened.');
            return;
        }

        await this.handleStripePaymentMethod();
    }

    handleStripePaymentMethod = async () => {
        this.props.dispatch(orgCreatePaymentMethodPending());

        const org = this.props.createdOrg!;

        let paymentMethodId: string = '';
        try {
            const { error, paymentMethod } = await this.props.stripe!.createPaymentMethod('card', {
                billing_details: {
                    name: this.props.form.getFieldValue('creditCardName'),
                    email: this.props.user!.email,
                    address: {
                        line1: org.addresses.billing.streetAddresses[0],
                        line2: org.addresses.billing.streetAddresses.length === 2 ? org.addresses.billing.streetAddresses[1] : undefined,
                        city: org.addresses.billing.city,
                        state: org.addresses.billing.state,
                        postal_code: org.addresses.billing.zipCode,
                    },
                },
            });

            if (error) {
                console.log('error from stripe:', error);
                this.props.dispatch(orgCreateFailure({ error: error.message }));
                throw new Error(error.message);
            }

            if (!paymentMethod) {
                this.props.dispatch(orgCreateFailure({ error: 'Invalid response from our Payment Gateway.' }));
                throw new Error('Invalid response from our Payment Gateway.');
            }

            paymentMethodId = paymentMethod.id;
        } catch (e) {
            displayErrorNotification(e);
            return;
        }

        try {
            await createPaymentMethod(org.id, { paymentProviderId: paymentMethodId });
            await this.props.dispatch(orgRefresh(org.id) as any);
        } catch (e) {
            displayErrorNotification(e);
            this.props.dispatch(orgCreateFailure(e as IRestError));
            return;
        }

        // after all is well, show them the plan selection
        this.setState({ currentStep: this.state.currentStep + 1 });
    }

    goBackClick = () => {
        const previousStep = this.state.currentStep - 1;

        if (previousStep < 0) {
            return;
        }

        this.setState({ currentStep: previousStep });
    }

    onSuccess = async () => {
        const created = this.props.createdOrg;
        if (!created) {
            notification.error({ message: 'Failed to create the organization?' });
            return;
        }

        await this.props.dispatch(orgClearCreated() as any);
        this.props.history.push(`/${ created.shortId }/inventories`);
    }
    //#endregion

    get stepsDisplay() {
        return (
            <Row className="creation-steps">
                <Col xs={0} sm={0} md={0} lg={24}>
                    <Steps
                        current={this.state.currentStep}
                        size="small"
                        items={[
                            { title: 'Company Info', description: 'Basic info.' },
                            { title: 'Billing Details', description: 'Payment info.' },
                            { title: 'Plan Selection', description: 'Monthly or yearly.' },
                        ]}
                    />
                </Col>
            </Row>
        );
    }

    //#region inputs
    //#region step one inputs
    get orgNameInput() {
        return (
            <Form.Item name="name" rules={[{ required: true, message: 'The organization name is required' }]} preserve>
                <Input
                    size="large"
                    placeholder="Organization name"
                    autoComplete="organization"
                    disabled={this.props.isCreatingOrg}
                />
            </Form.Item>
        );
    }

    get phoneNumberInput() {
        const rules: Rule[] = [
            { required: true, message: 'Phone number is required for great support.' },
            { pattern: /^\d{10}$/, message: 'Invalid number format, include numbers only.' },
        ];

        return (
            <Row className="org-phone-number">
                <Col span={24}>
                    <Form.Item>
                        <Space.Compact size="large" block>
                            <Select size="large" value="+1" disabled style={{ flex: 1 }}>
                                <Select.Option value="+1">+1</Select.Option>
                            </Select>
                            <Form.Item name="phoneNumber" rules={rules} noStyle preserve>
                                <Input
                                    className="phone-number"
                                    min={0}
                                    minLength={10}
                                    maxLength={10}
                                    size="large"
                                    style={{ flex: 4 }}
                                    placeholder="Phone Number"
                                    autoComplete="tel-national"
                                    inputMode="tel"
                                    disabled={this.props.isCreatingOrg}
                                />
                            </Form.Item>
                            <Form.Item name="phoneNumberType" rules={[{ required: true, message: 'Required.' }]} noStyle preserve>
                                <Select size="large" placeholder="Type" className="phone-number-type" style={{ flex: 1 }}>
                                    <Select.Option value="Mobile">Mobile</Select.Option>
                                    <Select.Option value="Office">Office</Select.Option>
                                </Select>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get emailInput() {
        const rules: Rule[] = [
            { required: true, message: 'The organization\'s contact email must be provided.' },
            { type: 'email', message: 'The email address must be valid.' },
        ];

        return (
            <Form.Item name="email" rules={rules} preserve>
                <Input
                    size="large"
                    placeholder="contact@properties.com"
                    autoComplete="email"
                    type="email"
                    inputMode="email"
                    disabled={this.props.isCreatingOrg}
                />
            </Form.Item>
        );
    }

    getAddressFromAutoComplete = (address?: IPostalAddress) => {
        if (!address) {
            return '';
        }

        let streetLine1 = '';
        if (Array.isArray(address.streetAddresses) && address.streetAddresses.length !== 0) {
            streetLine1 = address.streetAddresses[0];
        }

        if (address.city) {
            this.props.form.setFieldValue('mailingCity', address.city);
        }

        if (address.state) {
            this.props.form.setFieldValue('mailingState', address.state);
        }

        if (address.zipCode) {
            this.props.form.setFieldValue('mailingZip', address.zipCode);
        }

        return streetLine1;
    };

    get orgStreetOneInput() {
        return (
            <React.Fragment>
                <Suspense fallback={<Skeleton.Input block active style={{ height: '40px' }} />}>
                    <AddressAutoCompleteInput
                        name="mailingLine1"
                        rules={[{ required: true, message: 'The mailing address street is required.' }]}
                        disabled={this.props.isCreatingOrg}
                        getValueFromEvent={this.getAddressFromAutoComplete}
                        placeholder="Mailing street one"
                        size="large"
                        preserve
                    />
                </Suspense>
            </React.Fragment>
        );
    }

    get orgStreetTwoInput() {
        return (
            <Form.Item name="mailingLine2" preserve>
                <Input
                    size="large"
                    placeholder="Mailing street two"
                    autoComplete="mailing address-line2"
                    disabled={this.props.isCreatingOrg}
                />
            </Form.Item>
        );
    }

    get orgCityInput() {
        return (
            <Form.Item name="mailingCity" rules={[{ required: true, message: 'The mailing address city is required.' }]} preserve>
                <Input
                    size="large"
                    placeholder="Mailing city"
                    autoComplete="mailing address-level2"
                    disabled={this.props.isCreatingOrg}
                />
            </Form.Item>
        );
    }

    get orgStateAndZipInput() {
        const zipRules: Rule[] = [
            { required: true, message: 'The mailing zip code is required.' },
            { pattern: /^\d{5}$/, message: 'Invalid number format, include numbers only.' },
        ];

        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="mailingState" rules={[{ required: true, message: 'The mailing address state is requried.' }]} preserve>
                        <Input
                            size="large"
                            placeholder="Mailing state"
                            autoComplete="mailing address-level1"
                            disabled={this.props.isCreatingOrg}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="mailingZip" rules={zipRules} preserve>
                        <Input
                            minLength={5}
                            maxLength={5}
                            size="large"
                            style={{ width: '100%' }}
                            placeholder="Mailing zip"
                            autoComplete="mailing zip"
                            disabled={this.props.isCreatingOrg}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get agreementCheckbox() {
        const rules: Rule[] = [
            {
                required: true,
                message: 'You must agree to the Terms of Service.',
                validator: (rule, value: boolean) => {
                    if (typeof value === 'boolean' && value) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error('You must agree to the Terms of Service.'));
                },
            },
        ];

        return (
            <Form.Item name="tosAgreed" rules={rules} valuePropName="checked" preserve>
                <Checkbox>I have read and agree to the <a href="https://lendiom.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a></Checkbox>
            </Form.Item>
        );
    }
    //#endregion
    //#endregion

    onCancelClick = () => {
        this.props.history.goBack();
    }

    onLogOutClick = () => {
        this.props.dispatch(authLogout() as any);
        window.localStorage.clear();
        this.props.history.push('/auth/login');
    };

    get firstStepItems() {
        if (!this.props.user) {
            return null;
        }

        const hasOtherOrgs = this.props.user.organizations.length !== 0;

        return (
            <Layout>
                {this.orgNameInput}
                {this.phoneNumberInput}
                {this.emailInput}
                {this.orgStreetOneInput}
                {this.orgStreetTwoInput}
                {this.orgCityInput}
                {this.orgStateAndZipInput}
                {this.agreementCheckbox}

                <Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={5}>
                            <Button key="cancelBtn" type="primary" danger size="large" disabled={this.props.isCreatingOrg} onClick={hasOtherOrgs ? this.onCancelClick : this.onLogOutClick}>{ hasOtherOrgs ? 'Cancel' : 'Log out' }</Button>
                        </Col>
                        <Col span={4}>
                            <Button key="nextBtn" type="primary" size="large" disabled={this.state.currentStep === 2 || this.props.isCreatingOrg} onClick={this.onNextClick}>Next</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Layout>
        );
    }

    get secondStepItems() {
        return (
            <OrgCreationSecondStep
                form={this.props.form}
                isSaving={this.props.isCreatingOrg}
                user={this.props.user!}
                onNext={this.onNextClick}
                onPrevious={this.goBackClick}
                organization={this.props.createdOrg}
            />
        );
    }

    get thirdStepItems() {
        return (
            <OrgCreationThirdStep organization={this.props.createdOrg!} onSuccess={this.onSuccess} dispatch={this.props.dispatch} />
        );
    }

    get trialInfoRow() {
        return (
            <Row style={{ marginBottom: '16px' }}>
                <Col>
                    <Alert
                        message="31 Day Trial"
                        description="We are pleased to offer a 31 day trial! We are confident you will enjoy using Lendiom but if you are not 100% satisfied during the first 31 days of using our software, you can cancel and you will not be charged."
                        type="info"
                        showIcon
                    />
                </Col>
            </Row>
        );
    }

    get formOnlyWhenUser() {
        const { createdOrg, user } = this.props;

        if (!user) {
            return <Loading />;
        }

        const { currentStep } = this.state;

        return (
            <Layout>
                {this.trialInfoRow}

                <h3>Organization Creation</h3>
                {this.stepsDisplay}

                <Form
                    form={this.props.form}
                    onSubmitCapture={(e) => e.preventDefault()}
                    initialValues={{
                        name: createdOrg ? createdOrg.name : '',
                        email: createdOrg ? createdOrg.email : '',
                        mailingLine1: createdOrg && createdOrg.addresses.mailing.streetAddresses.length >= 1 ? createdOrg.addresses.mailing.streetAddresses[0] : '',
                        mailingLine2: createdOrg && createdOrg.addresses.mailing.streetAddresses.length >= 2 ? createdOrg.addresses.mailing.streetAddresses[1] : '',
                        mailingCity: createdOrg ? createdOrg.addresses.mailing.city : '',
                        mailingState: createdOrg ? createdOrg.addresses.mailing.state : '',
                        mailingZip: createdOrg ? createdOrg.addresses.mailing.zipCode : '',
                        sameAsMailing: createdOrg ? createdOrg.addresses.areSame : false,
                        billingLine1: createdOrg && createdOrg.addresses.billing.streetAddresses.length >= 1 ? createdOrg.addresses.billing.streetAddresses[0] : '',
                        billingLine2: createdOrg && createdOrg.addresses.billing.streetAddresses.length >= 2 ? createdOrg.addresses.billing.streetAddresses[1] : '',
                        billingCity: createdOrg ? createdOrg.addresses.billing.city : '',
                        billingState: createdOrg ? createdOrg.addresses.billing.state : '',
                        billingZip: createdOrg ? createdOrg.addresses.billing.zipCode : '',
                        creditCardName: user.firstName + ' ' + user.lastName,
                    }}
                >
                    {currentStep === 0 ? this.firstStepItems : null}
                    {currentStep === 1 ? this.secondStepItems : null}
                    {currentStep === 2 ? this.thirdStepItems : null}
                </Form>
            </Layout>
        );
    }

    render() {
        return (
            <Layout className="org-creation-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={8}>
                        <Layout.Content className="create">
                            <Layout className="branding">
                                <DingdingOutlined /> &nbsp; <span className="name">Lendiom</span>
                            </Layout>

                            {this.formOnlyWhenUser}
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>
            </Layout>
        );
    }
}

const OrgCreation = connect(mapStateToProps)(injectStripe(withRouter(OrgCreationBase)));

export const WrappedOrganizationCreation: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <StripeProvider apiKey={stripeApiKey}>
            <Elements>
                <OrgCreation form={form} />
            </Elements>
        </StripeProvider>
    );
}

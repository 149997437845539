import React from 'react';
import shortid from 'shortid';
import { Modal, Row, Col, Form, FormInstance, Alert, Radio, Input, Select } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';

import { IOrganization } from 'models/organization';
import { ILoan, LoanOnlinePaymentPlatformFeePayee } from 'models/loan';
import { IUpdateOnlinePaymentConfigPayload } from 'models/payloads/loans';

import { trackUmami } from 'utils/umami';
import { displayErrorNotification } from 'utils/errors';

import { updateOnlinePaymentConfig } from 'api/loans';
import { displayConfirmModal } from 'utils/modals';

interface IFormValues {
    onlinePaymentsEnabled: boolean;
    onlineStatementDescriptor: string;
    onlinePaymentPlatformFeePayee: LoanOnlinePaymentPlatformFeePayee;
    allowPrincipalOnly: boolean;
    allowAutoDraft: boolean;
}

interface IOnlinePaymentsModalProps {
    org?: IOrganization;
    loan?: ILoan;
    isVisible: boolean;
    close: (updated?: boolean) => Promise<void>;
}

interface IOnlinePaymentsModalState {
    valueChangeEventId: string;
    isSaving: boolean;
}

export class OnlinePaymentsModal extends React.PureComponent<IOnlinePaymentsModalProps, IOnlinePaymentsModalState> {
    state: Readonly<IOnlinePaymentsModalState> = {
        valueChangeEventId: '',
        isSaving: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidUpdate(prevProps: IOnlinePaymentsModalProps) {
        if (!prevProps.isVisible && this.props.isVisible) {
            trackUmami('Open Online Payments Config Modal');
        }
    }

    valuesChanged = () => {
        this.setState({ valueChangeEventId: shortid.generate() });
    }

    onClose = () => {
        this.formRef.current?.resetFields();
        this.props.close(false);
    }

    save = async () => {
        if (!this.formRef.current) {
            return;
        }

        try {
            const values = await this.formRef.current.validateFields();

            this.setState({ isSaving: true }, async () => {
                try {
                    const { loan } = this.props;
                    const payload: IUpdateOnlinePaymentConfigPayload = {
                        enabled: typeof values.onlinePaymentsEnabled === 'boolean' ? values.onlinePaymentsEnabled : false,
                        statementDescriptor: typeof values.onlineStatementDescriptor === 'string' ? values.onlineStatementDescriptor : '',
                        platformFeePayee: typeof values.onlinePaymentPlatformFeePayee === 'string' ? values.onlinePaymentPlatformFeePayee : LoanOnlinePaymentPlatformFeePayee.Buyer,
                        allowPrincipalOnly: typeof values.allowPrincipalOnly === 'boolean' ? values.allowPrincipalOnly : false,
                        allowAutoDraft: typeof values.allowAutoDraft === 'boolean' ? values.allowAutoDraft : false,
                    };

                    if (loan?.onlinePaymentConfig?.autoDraft?.enabled && (!payload.enabled || !payload.allowAutoDraft)) {
                        if (!await displayConfirmModal('Cancel auto draft?', 'Are you sure you wish to cancel the auto draft payments?', 'Yes', 'No, keep them!')) {
                            throw new Error('Saving aborted and the auto draft is still enabled');
                        }
                    }

                    await updateOnlinePaymentConfig(loan!.organization!.id, loan!.id!, payload);
                    trackUmami('Update Online Payment Config');

                    await this.props.close(true);
                    this.formRef.current?.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        } catch (e) {
            console.warn('validation failed for loan online payments modal:', e);
        }
    }

    get autoDraftAlert() {
        if (!this.props.org || !this.props.loan || !this.props.loan.onlinePaymentConfig || !this.props.loan.onlinePaymentConfig.autoDraft) {
            return null;
        }

        if (!this.props.loan.onlinePaymentConfig.autoDraft.enabled) {
            return null;
        }

        return (
            <Row style={{ marginBottom: '25px ' }}>
                <Col>
                    <Alert
                        message="Automatic Payments Enabled"
                        description="Automatic payments are enabled for this loan. Disabling online payments or no longer allowing auto draft will cancel the client's automatic payments."
                        type="warning"
                        showIcon
                    />
                </Col>
            </Row>
        );
    }

    get acceptOnlinePayments() {
        if (!this.props.org) {
            return null;
        }

        const { stripeConnect } = this.props.org;

        // not stripe account connected
        if (!stripeConnect.accountId) {
            return (
                <Row style={{ marginBottom: '25px ' }}>
                    <Col>
                        <Alert
                            message="Online Payments"
                            description="If you would like to accept online payments, please connect Stripe."
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            );
        }

        // they have a stripe account id but their details have yet to be submitted
        if (stripeConnect.accountId && !stripeConnect.detailsSubmitted) {
            return (
                <Row style={{ marginBottom: '25px ' }}>
                    <Col>
                        <Alert
                            message="Online Payments"
                            description="To accept online payments, please finish connecting Stripe."
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            );
        }

        return (
            <Form.Item
                name="onlinePaymentsEnabled"
                label="Online Payments"
                extra="Should we setup this loan to accept online payments? It can be enabled in the future."
                rules={[{ required: true, message: 'Please state whether or not we should enabled online payments for the loan.' }]}
            >
                <Radio.Group buttonStyle="solid" disabled={this.state.isSaving}>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    get statementDescriptorExtra() {
        return (
            <React.Fragment>
                Extra information about a product which will appear on your customer's credit card statement. <a href="https://stripe.com/docs/statement-descriptors" target="_blank" rel="noopener noreferrer">Click for more information.</a>
            </React.Fragment>
        );
    }

    get statementDescriptor() {
        if (!this.props.org) {
            return null;
        }

        const rules: Rule[] = [
            { required: true, message: 'Please provide a brief summary for the statement descriptor of the transaction.' },
            {
                type: 'string', whitespace: true, message: 'Invalid statement descriptor.',
                transform: (value: string) => value.trim().toUpperCase(),
            },
            { min: 5, max: 22, message: 'Statement descriptor must be between 5 and 22 characters long.' },
            { pattern: /[A-Z]+/gi, message: 'Statement descriptor must contain at least one letter.' },
            {
                message: `Statement descriptor must not contain: < > \\ ' " *`,
                validator: async (rule, value: string) => {
                    if (value.includes('>')) {
                        throw new Error('Can not include >');
                    } else if (value.includes('<')) {
                        throw new Error('Can not include <');
                    } else if (value.includes('\\')) {
                        throw new Error('Can not include \\');
                    } else if (value.includes('\'')) {
                        throw new Error('Can not include \'');
                    } else if (value.includes('"')) {
                        throw new Error('Can not include "');
                    } else if (value.includes('*')) {
                        throw new Error('Can not include *');
                    }
                },
            },
        ];

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.onlinePaymentsEnabled !== curr.onlinePaymentsEnabled}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('onlinePaymentsEnabled')) {
                        return null;
                    }

                    return (
                        <Form.Item name="onlineStatementDescriptor" label="Statement Descriptor" extra={this.statementDescriptorExtra} rules={rules}>
                            <Input style={{ textTransform: 'uppercase' }} disabled={this.state.isSaving} minLength={5} maxLength={22} />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get platformFeesPayee() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.onlinePaymentsEnabled !== curr.onlinePaymentsEnabled}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('onlinePaymentsEnabled')) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="onlinePaymentPlatformFeePayee"
                            label="Platform Fee Payee"
                            extra="Who pays the online platform fees? The buyer, seller or split 50% between them both?"
                            rules={[{ required: true, message: 'Please indicate who should pay the platform fees.' }]}
                        >
                            <Select<LoanOnlinePaymentPlatformFeePayee> disabled={this.state.isSaving}>
                                <Select.Option key={LoanOnlinePaymentPlatformFeePayee.Buyer} value={LoanOnlinePaymentPlatformFeePayee.Buyer}>Buyer</Select.Option>
                                <Select.Option key={LoanOnlinePaymentPlatformFeePayee.Both} value={LoanOnlinePaymentPlatformFeePayee.Both}>Both 50/50 Split</Select.Option>
                                <Select.Option key={LoanOnlinePaymentPlatformFeePayee.Seller} value={LoanOnlinePaymentPlatformFeePayee.Seller}>Seller</Select.Option>
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get allowPrincipalOnlyPayments() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.onlinePaymentsEnabled !== curr.onlinePaymentsEnabled}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('onlinePaymentsEnabled')) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="allowPrincipalOnly"
                            label="Allow Principal Only"
                            extra="Do you wish to allow the buyer to pay extra principal only payments online? These are separate transactions from payments which include extra on the regular payment."
                            rules={[{ required: true, message: 'Please state whether or not we should allow principal only payments online.' }]}
                        >
                            <Radio.Group buttonStyle="solid" disabled={this.state.isSaving}>
                                <Radio.Button value={true}>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get allowAutoDraft() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.onlinePaymentsEnabled !== curr.onlinePaymentsEnabled}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('onlinePaymentsEnabled')) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="allowAutoDraft"
                            label="Allow Auto Draft"
                            extra="Do you wish to allow the buyer to set up auto drafting their payments?"
                            rules={[{ required: true, message: 'Please state whether or not we should allow auto drafting payments.' }]}
                        >
                            <Radio.Group buttonStyle="solid" disabled={this.state.isSaving}>
                                <Radio.Button value={true}>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get saveDisabled() {
        if (this.formRef.current?.getFieldValue('onlinePaymentsEnabled') === 'undefined') {
            return true;
        }

        return this.state.isSaving;
    }

    render() {
        let initialValue = this.props.org ? this.props.org.name.replace(/[^\w\s]/gi, '') : 'Lendiom';
        if (initialValue.length > 22) {
            initialValue = initialValue.substring(0, 22).trim();
        }

        return (
            <Modal
                open={this.props.isVisible}
                title={<span><ApiOutlined /> Online Payments</span>}
                okText="Save"
                onOk={this.save}
                onCancel={this.onClose}
                closable={!this.state.isSaving}
                okButtonProps={{ disabled: this.saveDisabled, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
                destroyOnClose
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    onValuesChange={this.valuesChanged}
                    onFinish={this.save}
                    initialValues={{
                        onlinePaymentsEnabled: this.props.loan ? this.props.loan.onlinePaymentConfig.enabled : false,
                        onlineStatementDescriptor: this.props.loan && !!this.props.loan.onlinePaymentConfig.statementDescriptor ? this.props.loan.onlinePaymentConfig.statementDescriptor : initialValue,
                        onlinePaymentPlatformFeePayee: this.props.loan ? this.props.loan.onlinePaymentConfig.platformFeePayee : LoanOnlinePaymentPlatformFeePayee.Buyer,
                        allowPrincipalOnly: this.props.loan ? this.props.loan.onlinePaymentConfig.allowPrincipalOnly : false,
                        allowAutoDraft: this.props.loan ? this.props.loan.onlinePaymentConfig.allowAutoDraft : true,
                    }}
                >
                    {this.autoDraftAlert}
                    {this.acceptOnlinePayments}
                    {this.statementDescriptor}
                    {this.platformFeesPayee}
                    {this.allowPrincipalOnlyPayments}
                    {this.allowAutoDraft}
                </Form>
            </Modal>
        );
    }
}

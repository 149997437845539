import React, { useState } from 'react';
import { Form, InputNumber, message, Modal, Radio } from 'antd';

import { ILoan, ILoanLateFeeConfig } from 'models/loan';

import { displayErrorNotification } from 'utils/errors';
import { updateLateFeeConfig } from 'api/loans';

interface IFormValues {
    daysUntilLate: number;
    lateFeeDisabled: boolean;
}

interface IChangeLateFeeConfigModalProps {
    visible: boolean;
    loan: ILoan;
    close: (saved?: boolean) => void;
}

export const ChangeLateFeeConfigModal: React.FC<IChangeLateFeeConfigModalProps> = (props) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm<IFormValues>();

    const onSaveClick = async () => {
        setSaving(true);
        const values = await form.validateFields();

        try {
            const lateFeeConfig: ILoanLateFeeConfig = {
                ...props.loan.lateFeeConfig,
                daysUntilLate: values.daysUntilLate,
                disabled: values.lateFeeDisabled,
            };

            await updateLateFeeConfig(props.loan.organization.id, props.loan.id, lateFeeConfig);

            message.success('Late Fee config successfully updated.');

            setSaving(false);
            props.close(true);
            form.resetFields();
        } catch (e) {
            displayErrorNotification(e);
            setSaving(false);
        }
    };

    const onCancelClick = () => {
        setSaving(false);
        props.close();
        form.resetFields();
    }

    return (
        <Modal
            open={props.visible}
            title="Change Late Fee Config"
            maskClosable={false}
            closable={!saving}
            okText="Save"
            okButtonProps={{ disabled: saving, loading: saving }}
            onOk={onSaveClick}
            onCancel={onCancelClick}
            cancelButtonProps={{ disabled: saving }}
        >
            <Form<IFormValues>
                form={form}
                initialValues={{
                    daysUntilLate: props.loan.lateFeeConfig.daysUntilLate,
                    lateFeeDisabled: props.loan.lateFeeConfig.disabled,
                }}
            >
                <Form.Item
                    name="daysUntilLate"
                    label="Days Until Late"
                    extra="How long the payee has after the due date until the payment is considered late."
                    rules={[{ required: true, message: 'Please input the amount of days before the payment is marked as late.' }]}
                >
                    <InputNumber
                        min={0}
                        step={1}
                        style={{ width: '100%' }}
                        disabled={saving}
                    />
                </Form.Item>

                <Form.Item
                    name="lateFeeDisabled"
                    label="Late Fees Applied"
                    extra="Do you want Lendiom to automatically apply late fees?"
                    rules={[{ required: true, message: 'Please indicate whether or not to automatically apply late fees.' }]}
                >
                    <Radio.Group buttonStyle="solid" disabled={saving}>
                        <Radio.Button value={false}>Automatically</Radio.Button>
                        <Radio.Button value={true}>Manually</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}

import React from 'react';
import currency from 'currency.js';
import { DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Spin, Row, Col, Result, Typography, Descriptions } from 'antd';

import { StripeConnectButton } from 'components/stripe/stripeConnectButton';

import { IOrganization } from 'models/organization';

import { createStripeConnectLink } from 'api/organizations';
import { displayErrorNotification } from 'utils/errors';
import { isMobileOnly } from 'react-device-detect';

interface IStripeProps extends DispatchProp, RouteComponentProps {
    org: IOrganization;
}

interface IStripeState {
    isLoading: boolean;
}

class StripeViewBase extends React.PureComponent<IStripeProps, IStripeState> {
    state: Readonly<IStripeState> = {
        isLoading: true,
    };

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search.replace('?', ''));
        if (!params.get('action')) {
            this.setState({ isLoading: false });
            return;
        }

        switch (params.get('action')) {
            case 'refresh':
                try {
                    const result = await createStripeConnectLink(this.props.org.id);
                    window.location.href = result.url;
                } catch (e) {
                    displayErrorNotification(e);
                }
                break;
            default:
                break;
        }

        this.setState({ isLoading: false });
    }

    get stripeDetails() {
        const { stripeConnect } = this.props.org;

        return (
            <Descriptions bordered size="small" column={isMobileOnly ? 1 : 2}>
                <Descriptions.Item label="Stripe ID" span={2}>{stripeConnect.accountId}</Descriptions.Item>
                <Descriptions.Item label="Available Balance" span={2}>{currency(stripeConnect.availableBalance, { precision: 2 }).format(true)}</Descriptions.Item>
                <Descriptions.Item label="Pending Balance" span={2}>{currency(stripeConnect.pendingBalance, { precision: 2 }).format(true)}</Descriptions.Item>
                <Descriptions.Item label="Details Submitted" span={2}>{stripeConnect.detailsSubmitted ? 'Yes' : <StripeConnectButton size="small" />}</Descriptions.Item>
                <Descriptions.Item label="Charges">{stripeConnect.chargesEnabled ? 'Enabled' : 'Disabled'}</Descriptions.Item>
                <Descriptions.Item label="Payouts">{stripeConnect.payoutsEnabled ? 'Enabled' : 'Disabled'}</Descriptions.Item>
            </Descriptions>
        );
    }

    get detailsForAnAccount() {
        return (
            <React.Fragment>
                <div className="table-header-with-button">
                    <Typography.Title level={3}>Stripe</Typography.Title>
                    <StripeConnectButton />
                </div>

                <Row>
                    <Col xs={24} sm={12}>{this.stripeDetails}</Col>
                </Row>
            </React.Fragment>
        );
    }

    get noAccount() {
        if (!this.props.org.stripeConnect.detailsSubmitted) {
            return (
                <Result title="Stripe Account not yet finished setting up." extra={<StripeConnectButton size="large" />} />
            );
        }

        return (
            <Result title="No Stripe Account connected yet." extra={<StripeConnectButton size="large" />} />
        );
    }

    render() {
        const { stripeConnect } = this.props.org;

        return (
            <Spin spinning={this.state.isLoading} size="large">
                { stripeConnect.accountId === '' || !stripeConnect.detailsSubmitted ? this.noAccount : this.detailsForAnAccount }
            </Spin>
        );
    }
}

export const StripeView = withRouter(StripeViewBase);

import React from 'react';
import currency from 'currency.js';
import { TableOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Descriptions, Button } from 'antd';

import { IMail, MailClass } from 'models/mail';

import { SimpleDate } from 'utils/formatting';

interface IViewMailDetailsModalProps {
    mail?: IMail;
    isVisible: boolean;
    close: () => void;
}

interface IViewMailDetailsModalState { }

export class ViewMailDetailsModal extends React.PureComponent<IViewMailDetailsModalProps, IViewMailDetailsModalState> {
    state: Readonly<IViewMailDetailsModalState> = {};

    get mailDetails() {
        const { mail } = this.props;

        if (!mail) {
            return null;
        }

        return (
            <Row>
                <Col>
                    <Descriptions column={2}>
                        <Descriptions.Item label="Description">{ mail.description }</Descriptions.Item>
                        <Descriptions.Item label="Class">{ mail.class === MailClass.FirstClass ? 'First Class' : 'Standard Class' }</Descriptions.Item>
                        <Descriptions.Item label="Page Count">{ mail.pageCount }</Descriptions.Item>
                        <Descriptions.Item label="Cost">{ currency(mail.cost, { precision: 2 }).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Sent Date"><SimpleDate date={mail.externalInfo.sentDate} /></Descriptions.Item>
                        <Descriptions.Item label="Expected Delivery"><SimpleDate date={mail.externalInfo.expectedDeliveryDate} /></Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        );
    }

    render() {
        const { isVisible } = this.props;

        if (!isVisible || !this.props.mail) {
            return null;
        }

        return (
            <Modal
                open={isVisible}
                title={<React.Fragment><TableOutlined /> View Mail Details</React.Fragment>}
                footer={
                    <Button type="primary" onClick={this.props.close}>
                        Close
                    </Button>
                }
                onCancel={this.props.close}
            >
                {this.mailDetails}
            </Modal>
        );
    }
}

import React from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { useRouteMatch } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { List, Button, Row, Col, Statistic } from 'antd';
import type { PaginationConfig } from 'antd/lib/pagination';

import { SimpleDate } from 'utils/formatting';

import type { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import type { ILoan } from 'models/loan';

import { LoanStatusTag } from './loanStatusTag';
import { loanSorter } from './loansSorter';

interface ILoansListProps {
    isLoading: boolean;
    loans: ILoan[];
    recordTransactionFor: (loan: ILoan) => void;
    pagination: PaginationConfig;
}

export const LoansList: FC<ILoansListProps> = (props) => {
    const match = useRouteMatch<IOrgIdPathParams>();

    return (
        <List<ILoan>
            itemLayout="vertical"
            loading={props.isLoading}
            dataSource={props.loans.sort(loanSorter)}
            pagination={props.pagination}
            renderItem={(loan: ILoan) => {
                return (
                    <List.Item
                        key={loan.id}
                        actions={[
                            <Button key={loan.id+'_view'} icon={<InfoCircleOutlined />} href={`/${ match.params.orgId }/loans/${ loan.id }`}>View Details</Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={loan.label}
                            description={<LoanStatusTag status={loan.status} />}
                        />

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Statistic title="Payment" prefix="$" precision={2} value={currency(loan.terms.payment!, { precision: 2 }).value} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="Interest Rate" suffix="%" precision={2} value={loan.terms.rate} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
                            <Col span={12}>
                                <Statistic title="Last Payment Date" value={loan.lastPaymentReceivedDate} formatter={(value: any) => <SimpleDate date={value} />} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="Next Due Date" value={loan.nextDueDate} formatter={(value: any) => <SimpleDate date={value} />} />
                            </Col>
                        </Row>
                    </List.Item>
                );
            }}
        />
    );
}

import React, { Suspense, lazy } from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { Comment } from '@ant-design/compatible';
import { List, Tooltip, Card, notification } from 'antd';
import TimeAgo from 'react-timeago';

import { AccessControlledButton } from 'components/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { IClient } from 'models/client';
import { ILoan } from 'models/loan';
import { INote } from 'models/notes';
import { RelatedToType } from 'models/common/relatedTo';

import { addInventoryNote, updateInventoryNote, deleteInventoryNote } from 'api/inventories';
import { addTractNote, updateTractNote, deleteTractNote } from 'api/tracts';
import { addClientNote, updateClientNote, deleteClientNote } from 'api/clients';
import { addLoanNote, updateLoanNote, deleteLoanNote } from 'api/loans';

import { trackUmami } from 'utils/umami';

const NewOrEditNoteModal = lazy(() => import('./newNoteModal'));

interface INotesCardProps {
    orgId: string;
    inventory?: IInventory;
    tract?: ITract;
    client?: IClient;
    loan?: ILoan;
    notes?: INote[];
    simpleList?: boolean;
    isLoading: boolean;
    refresh: () => Promise<void>;
    displayCreateModal?: boolean;
    modalStatusUpdate?: (open: boolean) => void;
}

interface INotesCardState {
    isModalVisible: boolean;
    editingNote?: INote;
}

export class NotesCard extends React.PureComponent<INotesCardProps, INotesCardState> {
    state: Readonly<INotesCardState> = {
        isModalVisible: false,
    }

    get permissionFeature() {
        if (this.props.client) {
            return PermissionFeature.ClientNotes;
        } else if (this.props.tract) {
            return PermissionFeature.TractNotes;
        } else if (this.props.loan) {
            return PermissionFeature.LoanNotes;
        } else if (this.props.inventory) {
            return PermissionFeature.InventoryNotes;
        }

        return PermissionFeature.All;
    }

    generateCommentTimeNode(date: string) {
        const d = dayjs(date);

        //Apr 14th, 2023 @ 7:32AM
        return (
            <Tooltip title={d.format('MMM D, YYYY @ h:mma')}>
                <TimeAgo date={d.toDate()} />
            </Tooltip>
        );
    }

    addNoteClick = () => {
        this.setState({ isModalVisible: true });

        if (this.props.modalStatusUpdate) {
            this.props.modalStatusUpdate(true);
        }
    }

    closeNote = () => {
        this.setState({ isModalVisible: false, editingNote: undefined });

        if (this.props.modalStatusUpdate) {
            this.props.modalStatusUpdate(false);
        }
    }

    saveNote = async (note: Partial<INote>) => {
        if (this.props.inventory) {
            if (this.state.editingNote) {
                await updateInventoryNote(this.props.orgId, this.props.inventory.id, note);
            } else {
                note.relatedTo = {
                    id: this.props.inventory.id,
                    label: this.props.inventory.name,
                    type: RelatedToType.INVENTORY,
                };

                await addInventoryNote(this.props.orgId, this.props.inventory.id, note);
            }
        }

        if (this.props.tract) {
            const { tract } = this.props;
            if (!tract.inventoryId) {
                notification.error({ message: 'Invalid tract was provided, could not find the associated inventory.' });
                return;
            }

            if (this.state.editingNote) {
                await updateTractNote(this.props.orgId, tract.inventoryId, tract.id, note);
            } else {
                note.relatedTo = {
                    id: tract.id,
                    label: tract.label,
                    type: RelatedToType.TRACT,
                };

                await addTractNote(this.props.orgId, tract.inventoryId, tract.id, note);
            }
        }

        if (this.props.client) {
            const { client } = this.props;

            if (this.state.editingNote) {
                await updateClientNote(this.props.orgId, client.id, note);
            } else {
                note.relatedTo = {
                    id: client.id,
                    label: client.displayName,
                    type: RelatedToType.CLIENT,
                };

                await addClientNote(this.props.orgId, client.id, note);
            }
        }

        if (this.props.loan) {
            const { loan } = this.props;

            if (this.state.editingNote) {
                await updateLoanNote(this.props.orgId, loan.id, note);
            } else {
                note.relatedTo = {
                    id: loan.id,
                    label: loan.label,
                    type: RelatedToType.LOAN,
                };

                await addLoanNote(this.props.orgId, loan.id, note);
            }
        }

        await this.props.refresh();
        this.closeNote();
    }

    getContent = (note: INote) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: note.content }} />
        );
    }

    get newNoteButton() {
        return (
            <AccessControlledButton
                type="dashed"
                size="small"
                onClick={this.addNoteClick}
                icon={<PlusOutlined />}
                feature={this.permissionFeature}
                action={PermissionAction.Create}
                prevent="tooltip"
            >Add Note</AccessControlledButton>
        );
    }

    editNote = (note: INote) => {
        this.setState({ editingNote: note, isModalVisible: true });
    }

    deleteNote = async (note: INote) => {
        if (this.props.inventory) {
            await deleteInventoryNote(this.props.orgId, this.props.inventory.id, note.id);
        }

        if (this.props.tract) {
            const { tract } = this.props;

            await deleteTractNote(this.props.orgId, tract.inventoryId!, tract.id, note.id);
        }

        if (this.props.client) {
            await deleteClientNote(this.props.orgId, this.props.client.id, note.id);
        }

        if (this.props.loan) {
            await deleteLoanNote(this.props.orgId, this.props.loan.id, note.id);
        }

        trackUmami('Delete Note');
        await this.props.refresh();
    }

    getNoteActions = (note: INote) => {
        const editClick = () => {
            this.editNote(note);
        };

        const deleteClick = () => {
            this.deleteNote(note);
        };

        return [
            <AccessControlledButton
                key="edit"
                type="link"
                onClick={editClick}
                disabled={note.system}
                feature={this.permissionFeature}
                action={PermissionAction.Update}
                prevent="hide"
            >Edit</AccessControlledButton>,
            <AccessControlledButton
                key="delete"
                type="link"
                onClick={deleteClick}
                feature={this.permissionFeature}
                action={PermissionAction.Delete}
                prevent="hide"
            >Delete</AccessControlledButton>
        ];
    }

    get notesList() {
        return (
            <List<INote>
                className="notes-list"
                itemLayout="horizontal"
                pagination={{
                    pageSize: 3,
                    hideOnSinglePage: true,
                }}
                dataSource={this.props.notes}
                loading={this.props.isLoading}
                renderItem={(item) => (
                    <List.Item actions={[this.getNoteActions(item).filter((i) => !!i)]}>
                        <Comment
                            key={item.id}
                            author={item.author}
                            content={this.getContent(item)}
                            datetime={this.generateCommentTimeNode(item.createdAt)}
                        />
                    </List.Item>
                )}
            />
        );
    }

    get notesCard() {
        return (
            <Card title="Notes" bordered={false} extra={this.newNoteButton} loading={this.props.isLoading}>
                {this.notesList}
            </Card>
        );
    }

    isCreateModalVisible(): boolean {
        if (this.state.isModalVisible) {
            return true;
        }

        if (this.props.displayCreateModal) {
            return true;
        }

        return false;
    }

    render() {
        const displayTimeline = typeof this.props.tract !== 'undefined' || typeof this.props.client !== 'undefined';

        return (
            <React.Fragment>
                {this.props.simpleList ? this.notesList : this.notesCard}

                <Suspense fallback={null}>
                    <NewOrEditNoteModal
                        isVisible={this.isCreateModalVisible()}
                        displayTimelineOption={displayTimeline}
                        note={this.state.editingNote}
                        close={this.closeNote}
                        save={this.saveNote}
                    />
                </Suspense>
            </React.Fragment>
        );
    }
}

import React from 'react';
import { FormInstance, Descriptions } from 'antd';

import { InventoryStatusTag } from 'components/inventories/inventoryStatusTag';

import { INewInventoryFormValues } from './newInventoryFormValues';
import { InventoryCategory } from 'models/inventory';

import { LongCurrency } from 'utils/formatting';

interface INewInventoryStepThreeProps {
    form: FormInstance<INewInventoryFormValues>;
}

export const NewInventoryFormStepThree: React.FC<INewInventoryStepThreeProps> = (props) => {
    const { getFieldValue } = props.form;
    const category = getFieldValue('category');
    const parcelNumbers: string[] = getFieldValue('parcelNumbers') || [];

    return (
        <Descriptions
            title={`New Inventory`}
            bordered
            size="small"
            column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            style={{ marginBottom: '25px' }}
        >
            <Descriptions.Item label="Name">{ getFieldValue('name') }</Descriptions.Item>
            <Descriptions.Item label="Category" className="title-caps">{ category }</Descriptions.Item>
            <Descriptions.Item label="Type" className="title-caps">{ getFieldValue('type') }</Descriptions.Item>
            <Descriptions.Item label="Status"><InventoryStatusTag status={getFieldValue('status')} /></Descriptions.Item>
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Street Address">{ getFieldValue('address1') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential ? <Descriptions.Item label="City">{ getFieldValue('city') }</Descriptions.Item> : null}
            <Descriptions.Item label="County">{ getFieldValue('county') ? getFieldValue('county') : '-' }</Descriptions.Item>
            <Descriptions.Item label="State">{ getFieldValue('state') }</Descriptions.Item>
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Zip Code">{ getFieldValue('zip') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential || category === InventoryCategory.Land ? <Descriptions.Item label="Parcel Numbers">{ parcelNumbers.length === 0 ? '-' : parcelNumbers.join(', ') }</Descriptions.Item> : null }
            { category === InventoryCategory.Land ? <Descriptions.Item label="Total Acres">{ getFieldValue('totalAcres') }</Descriptions.Item> : null }
            { category === InventoryCategory.Land ? <Descriptions.Item label="Total Dev Cost"><LongCurrency value={ getFieldValue('totalCostOfDevelopment') } /></Descriptions.Item> : null }
            { category === InventoryCategory.Land ? <Descriptions.Item label="Per Acre Cost"><LongCurrency value={ getFieldValue('perAcreCostOfDevelopment') } /></Descriptions.Item> : null }
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Bedrooms">{ getFieldValue('bedrooms') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Bathrooms">{ getFieldValue('bathrooms') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Square Footage">{ getFieldValue('livingSquareFootage') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Lot Area" className="title-caps">{ getFieldValue('lotArea') } { getFieldValue('lotAreaUnit') }</Descriptions.Item> : null}
            { category === InventoryCategory.Residential ? <Descriptions.Item label="Price"><LongCurrency value={getFieldValue('residentialPrice')} /></Descriptions.Item> : null }
        </Descriptions>
    );
}

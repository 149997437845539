import React from 'react';
import { Tag } from 'antd';

import { InventoryStatus } from 'models/inventory';

interface IInventoryStatusTagProps {
    status: InventoryStatus
};

export const InventoryStatusTag: React.FC<IInventoryStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case InventoryStatus.Idea:
            color = 'gold';
            break;
        case InventoryStatus.Ready:
            color = 'purple';
            break;
        case InventoryStatus.InProgress:
            color = 'green';
            break;
        case InventoryStatus.Completed:
            color = 'cyan';
            break;
        default:
            color = 'purple';
            break;
    }

    let label = 'Unknown';
    switch(props.status) {
        case InventoryStatus.Idea:
            label = 'Idea';
            break;
        case InventoryStatus.Ready:
            label = 'Ready';
            break;
        case InventoryStatus.InProgress:
            label = 'In Progress';
            break;
        case InventoryStatus.Completed:
            label = 'Completed';
            break;
        default:
            label = 'Unknown';
            break;
    }

    return (
        <Tag color={color}>{label}</Tag>
    );
});

import { Currency } from './currency';

import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { ILookup } from './common/lookup';
import { IEscrow } from './escrow';

export interface ILoan extends IObjectDates, IOrganizational {
    id: string;
    label: string;

    type: LoanType;
    residential?: ILoanResidential;
    tracts?: LoanTract[];
    client: ILookup;
    status: LoanStatus;
    communication: LoanCommunication;
    onlinePaymentConfig: ILoanOnlinePaymentConfiguration;

    terms: ILoanTerms;
    balance: ILoanBalance;
    lateFeeConfig: ILoanLateFeeConfig;
    hasSchedule: boolean;

    closingDate: string;
    downPaymentDate?: string; // only populated when interest accrues daily and first payment basis is down payment
    firstPaymentDate: string;
    previousDueDate: string;
    lastPaymentReceivedDate: string;
    lastTransactionDate: string;
    lastTransactionModifiedDate: string;
    nextDueDate: string;
    repossessedDate: string;

    escrow?: IEscrow;
}

export enum LoanType {
    Tract = 'tract',
    Residential = 'residential',
    Cash = 'cash',
}

export enum LoanStatus {
    Active = 'active',
    Inactive = 'inactive',
    Late = 'late',
    InDefault = 'in-default',
    Draft = 'draft',
    PaidOff = 'paid-off',
    Repossessed = 'repo',
}

export interface ILoanResidential {
    inventoryId?: string;
    squareFootage?: number;
}

export interface LoanTract {
    inventoryId: string;
    tractId: string;
    number?: number;
}

export interface LoanCommunication {
    automated: boolean;
    preference: LoanCommunicationPreference;
}

export interface ILoanOnlinePaymentConfiguration {
    enabled: boolean;
    statementDescriptor: string;
    platformFeePayee: LoanOnlinePaymentPlatformFeePayee;
    allowPrincipalOnly: boolean;
    allowAutoDraft: boolean;

    autoDraft?: ILoanOnlineAutoDraftConfiguration;
}

export interface ILoanOnlineAutoDraftConfiguration {
    enabled: boolean;
    extraApplication: LoanTransactionExtraApplication;
    paymentAmount: Currency;
    paymentMethodId: string;
    nextDate: string;
    previousDate: string;
}

export enum LoanOnlinePaymentPlatformFeePayee {
    Buyer = 'buyer',
    Both = 'both',
    Seller = 'seller',
}

export enum LoanCommunicationPreference {
    Email = 'email',
    SMS = 'sms',
    Mail = 'mail',
}

//#region loan terms
export interface ILoanTerms {
    downPayment: Currency;
    salesPrice: Currency;
    adjustments?: Currency;
    amount?: Currency;
    length: number;
    lengthUnit: LoanTermsLengthUnit;
    rate: Currency;
    interestSchedule: LoanTermsInterestSchedule;
    interestFormula?: LoanTermsInterestFormula;
    firstPaymentBasis?: LoanTermsInterestAccruesDailyFirstPaymentBasis;
    payment?: Currency;
    overwritePayment?: boolean;
    paymentFrequency: LoanPaymentFrequency;
    extraApplication: LoanTransactionExtraApplication;
    wasExisting: boolean;
    existing: ILoanTermsExisting;
}

export interface ILoanTermsExisting {
    principalPaid: Currency;
    interestPaid: Currency;
    interestPaidYtd: Currency;
    unpaidInterest?: Currency;
    lastPaidInFullPayment?: number;
    lastPaymentDate: string;
    nextPaymentDate: string;
}

export enum LoanTermsInterestSchedule {
    FollowsPayments = 'follows-payments',
    AccruesDaily = 'accrues-daily',
}

export enum LoanTermsInterestFormula {
    ThirtyDaysBy360 = '30/360',
    ActualBy360 = 'actual/360',
    ActualBy365 = 'actual/365',
}

export enum LoanTermsInterestAccruesDailyFirstPaymentBasis {
    ClosingDate = 'closing-date',
    DownPaymentDate = 'down-payment-date',
    FullMonth = 'full-month',
}

export enum LoanTermsLengthUnit {
    Months = 'months',
    Years = 'years',
}

export enum LoanPaymentFrequency {
    SemiMonthly = 'semimonthly',
    Monthly = 'monthly',
    Bimonthly = 'bimonthly',
    Quarterly = 'quarterly',
    SemiAnnually = 'semiannually',
    Annually = 'annually',
}
//#endregion loan terms

export interface ILoanBalance {
    principal: Currency;
    lateFees: Currency;
    due: Currency;
    propertyTax: Currency;
    otherFees: Currency;
    interest?: Currency; //interest is unpaid interest balance from previous payments
    accruedInterest?: Currency; //accruedInterest is the amount accrued since the last payment
    totalDue?: Currency; //totalDue is everything due, excluding accrued interest. //TODO: figure out how to calculate with that in mind
}

//#region late fee config
export interface ILoanLateFeeConfig {
    disabled: boolean;
    application: LoanLateFeeApplication;
    chargeType: LoanLateFeeChargeType;
    daysUntilLate: number;

    fixed?: Currency;
    percentage?: Currency;

    minimumAmount: Currency;
    maximumAmount: Currency;
}

export enum LoanLateFeeApplication {
    First = 'first',
    Principal = 'principal',
    Balance = 'balance',
}

export enum LoanLateFeeChargeType {
    Fixed = 'fixed',
    Percent = 'percent',
}
//#endregion late fee config

//#region loan transaction
export interface ILoanTransaction {
    id: string;
    date: string;
    status: LoanTransactionStatus;
    method: LoanTransactionMethod;
    type: LoanTransactionType;
    extraApplication: LoanTransactionExtraApplication;
    forPayments?: number[];
    toPayments?: ILoanTransactionTo[];

    stripeInvoiceId?: string;
    paidViaStripe: boolean;

    amount: Currency;
    totalAmount: Currency;
    totalToPlatform: Currency;
    totalToFees: Currency;
    totalToInterest: Currency;
    totalToUnpaidInterest: Currency;
    totalToPrincipal: Currency;
    totalToEscrow: Currency;
    loanBalance: Currency;

    lateFeeWaived: boolean;

    comment: string;
    commentText: string;

    statusChangedAt: string;
    reversedAt: string;
    reversalReason: LoanTransactionReversalReason;
    reversalComment: string;
    reversalCommentText: string;
    stripeRefundId: string;

    files: ILoanTransactionFiles;

    createdBy: ILookup;
    createdAt: string;
}

export enum LoanTransactionStatus {
    Pending = 'pending',
    Success = 'success',
    Failure = 'failure',
    Reversed = 'reversed',
}

export enum LoanTransactionMethod {
    Cash = 'cash',
    Check = 'check',
    Card = 'card',
    ACH = 'ach',
    Wire = 'wire',
    MoneyOrder = 'money-order',
    CashApp = 'cash-app',
    Venmo = 'venmo',
}

export enum LoanTransactionType {
    DownPayment = 'down-payment',
    RegularPayment = 'regular-payment',
    PrincipalPayment = 'principal-payment',
    LateFee = 'late-fee',
    OtherFee = 'other-fee',
    PropertyTax = 'property-tax',
    DocumentationFee = 'documentation-fee',
    ClosingFee = 'closing-fee',
    EarnestMoney = 'earnest-money',
}

export enum LoanTransactionExtraApplication {
    Principal = 'principal',
    NextPayment = 'next-payment',
}

export interface ILoanTransactionTo {
    paymentNumber: number;
    interest: Currency;
    principal: Currency;
    escrow: Currency;
}

export enum LoanTransactionReversalReason {
    InsufficientFunds = 'insufficient-funds',
    CheckBounced = 'check-bounced',
    ClericalError = 'clerical-error',
    BuyerRequested = 'buyer-requested',
    Other = 'other',
}

export interface ILoanTransactionFiles {
    lateNoticeId: string;
    paymentSuccessId: string;
}
//#endregion loan transaction

export interface ILoanPayOff {
    total: Currency;
    toInterest: Currency;
    toPrincipal: Currency;
    toFees: Currency;
    toOtherFees: Currency;
    letterUrl?: string;
}

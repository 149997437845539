import { LoanStatus } from 'models/loan';

/**
 * Checks whether the actions for the loan should be disabled. The conditions for it to be true are any of the following:
 *  - no status
 *  - status is Inactive
 *  - status is Paid Off
 *  - status is Repossessed
 *
 * @param status the loan's status
 * @returns whether actions should be disabled for the loan
 */
export function loanActionsAreDisabled(status?: LoanStatus): boolean {
    if (!status) {
        return true;
    }

    return status === LoanStatus.Inactive || status === LoanStatus.PaidOff || status === LoanStatus.Repossessed;
}

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Form, FormInstance, Col, Input, Button, notification, Checkbox } from 'antd';
import { DingdingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';

import { GlobalState } from 'store';
import { getAuthState } from 'store/selectors/auth';
import { authLogin } from 'store/actions/auth';
import { IUserLoginPayload } from 'models/payloads/userLogin';
import { orgSelect } from 'store/actions/org';
import { getSelectedOrgShortId, isFetchingOrg } from 'store/selectors/org';

import './login.css';

interface IFormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

const mapStateToProps = (state: GlobalState) => ({
    auth: getAuthState(state),
    selectedOrgId: getSelectedOrgShortId(state),
    isFetchingOrg: isFetchingOrg(state),
});

interface IUserLoginPathParams {
    email?: string,
}

interface IUserLoginProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IUserLoginPathParams> { }

interface IUserLoginState {
    displayTroubleLink: boolean;
}

class UserLoginBase extends React.PureComponent<IUserLoginProps, IUserLoginState> {
    state: Readonly<IUserLoginState> = {
        displayTroubleLink: false,
    };

    formRef = React.createRef<FormInstance<IFormValues>>();
    setTimeoutId: number = -1;

    componentDidMount = () => {
        this.setTimeoutId = window.setTimeout(() => this.setState({ displayTroubleLink: true }), 10 * 1000);
    }

    componentWillUnmount() {
        window.clearTimeout(this.setTimeoutId);
    }

    handleLoginSubmit = async () => {
        try {
            const values = await this.formRef.current!.validateFields();

            const payload: IUserLoginPayload = {
                email: values.email,
                password: values.password,
            };

            const result = await this.props.dispatch(authLogin(payload) as any);
            if (result.error) {
                if (result.error.code === 88) {
                    notification.error({ message: 'Invalid password.' });
                    return;
                } else if (result.error.code === 1) {
                    notification.error({ message: 'Unknown email provided, no account found.' });
                    return;
                }

                notification.error({ message: 'Internal error while logging in.' });
                return;
            }

            const { user } = this.props.auth;

            if (!user) {
                notification.error({ message: 'Failed to recover from an internal error.' });
                return;
            }

            if (!user.emailVerified) {
                this.props.history.push('/auth/verify-email');
                return;
            }

            const query = new URLSearchParams(this.props.location.search);
            if (typeof query.get('previous') === 'string') {
                this.props.history.push(query.get('previous')!);
                return;
            }

            if (user.organizations.length >= 1) {
                await this.props.dispatch(orgSelect(user.organizations[0].shortId!) as any);
                this.props.history.push(`/${this.props.selectedOrgId}/inventories`);
                return;
            }

            this.props.history.push('/org/new');
        } catch (e) {
            console.warn('error while validating form fields', e);
        }
    }

    //#region inputs
    get emailInput() {
        return (
            <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to login.' }]}>
                <Input
                    size="large"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    inputMode="email"
                    prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled={this.props.auth.isLoggingIn || this.props.isFetchingOrg}
                />
            </Form.Item>
        );
    }

    get passwordInput() {
        return (
            <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required to login.' }]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled={this.props.auth.isLoggingIn || this.props.isFetchingOrg}
                />
            </Form.Item>
        );
    }

    get rememberMeAndForgotPassword() {
        const linkProps = {
            to: '/auth/forgot-password',
            className: 'register',
            disabled: this.props.auth.isLoggingIn || this.props.isFetchingOrg,
        };

        return (
            <Form.Item>
                <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link {...linkProps}>Forgot your password?</Link>
            </Form.Item>
        );
    }
    //#endregion inputs

    signUpClick = () => {
        this.props.history.push('/auth/register');
    }

    get loginOrRegister() {
        return (
            <Form.Item>
                <div className="action-buttons">
                    <Button size="large" type="primary" htmlType="submit" className="submit" disabled={this.props.auth.isLoggingIn || this.props.isFetchingOrg} loading={this.props.auth.isLoggingIn || this.props.isFetchingOrg}>Login</Button>
                    <Button size="large" className="sign-up" onClick={this.signUpClick} disabled={this.props.auth.isLoggingIn || this.props.isFetchingOrg}>Sign Up</Button>
                </div>
            </Form.Item>
        );
    }

    onTroubleClick = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();

        window.location.reload();
    }

    get havingTroubleArea() {
        if (!this.state.displayTroubleLink) {
            return null;
        }

        return (
            <Row justify="center">
                <Col>
                    <Button type="link" onClick={this.onTroubleClick}>Trouble logging in?</Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Layout className="login-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={6}>
                        <Layout.Content className="login">
                            <Layout className="branding">
                                <DingdingOutlined /> &nbsp; <span className="name">Lendiom</span>
                            </Layout>

                            <h3>Login</h3>
                            <Form<IFormValues>
                                ref={this.formRef}
                                onFinish={this.handleLoginSubmit}
                                initialValues={{
                                    email: this.props.match.params.email,
                                    rememberMe: true,
                                }}
                            >
                                {this.emailInput}
                                {this.passwordInput}
                                {this.rememberMeAndForgotPassword}
                                {this.loginOrRegister}
                                {this.havingTroubleArea}
                            </Form>
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>
            </Layout>
        );
    }
}

export const UserLogin = connect(mapStateToProps)(withRouter(UserLoginBase));

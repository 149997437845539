import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Modal, notification, Tooltip } from 'antd';
import { LoadingOutlined, LoginOutlined, SendOutlined } from '@ant-design/icons';
import type { MenuInfo } from 'rc-menu/lib/interface';

import { hasAccessTo } from 'store/selectors/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { IClient } from 'models/client';
import { IEntity } from 'models/entity';

import { getClientImpersonationUrl } from 'api/clients';
import { displayErrorNotification } from 'utils/errors';
import { sendLendiomPaySMS } from 'api/sms';

interface IClientLendiomPayButtonsProps {
    client?: IClient;
    entities: IEntity[];
}

export const ClientLendiomPayButtons: React.FC<IClientLendiomPayButtonsProps> = (props) => {
    const [loadingForLogin, setLoadingForLogin] = useState(false);
    const [sending, setSending] = useState(false);
    const canCreate = useSelector(hasAccessTo(PermissionFeature.Client, PermissionAction.Create));

    const clientPayRestrictions = useMemo<{ disabled: boolean, overlay: string }>(() => {
        let overlay = '';

        const primaryEntity = props.entities.find((e) => e.id === props.client?.primaryEntity.id);
        if (!primaryEntity) {
            return {
                overlay: 'No primary entity on the client',
                disabled: true,
            };
        }

        if (!Array.isArray(primaryEntity.phoneNumbers) || primaryEntity.phoneNumbers.length <= 0) {
            overlay = 'Primary entity has no phone numbers';
        } else if (typeof primaryEntity.phoneNumbers.find((p) => typeof p.isCellular === 'boolean' ? p.isCellular : false) === 'undefined') {
            overlay = 'Primary entity has no cell phone number';
        } else if (!primaryEntity.email) {
            overlay = 'Primary entity has no email address';
        } else if (!primaryEntity.addresses || !Array.isArray(primaryEntity.addresses) || primaryEntity.addresses.length === 0) {
            overlay = 'Primary entity has no address';
        }

        return {
            disabled: overlay !== '',
            overlay,
        };
    }, [props.client, props.entities]);

    async function openLendiomPayAsClient() {
        Modal.confirm({
            title: 'Impersonating Warning',
            content: 'Please note, when impersonating a client you are taking action on their behalf. Lendiom assumes no responsibility and you guarantee the client has authorized you to take these actions.',
            okText: 'Yes, continue',
            okType: 'danger',
            cancelText: 'Never mind',
            onOk: async () => {
                if (!props.client) {
                    return;
                }

                try {
                    const urlRes = await getClientImpersonationUrl(props.client.organization.id, props.client.id);

                    window.open(urlRes.url, '_blank');
                } catch (e) {
                    console.warn('failed to load the impersonation url:', e);
                    displayErrorNotification(e);
                } finally {
                    setLoadingForLogin(false);
                }
            },
        });
    }

    async function sendLendiomPayInfo() {
        if (!props.client) {
            return;
        }

        try {
            await sendLendiomPaySMS(props.client.organization.id, props.client.id);

            setSending(false);
            notification.success({ message: `Successfully sent ${ props.client.displayName } their Lendiom Pay info.` });
        } catch (e) {
            console.warn('failed to send the client the lendiom pay information:', e);
            displayErrorNotification(e);
        }
    }

    function handleMenuClick(info: MenuInfo) {
        switch (info.key) {
            case 'login-as':
                setLoadingForLogin(true);
                openLendiomPayAsClient();
                break;
            case 'send-info':
                setSending(true);
                sendLendiomPayInfo();
                break;
        }
    }

    const menu = (
        <Menu
            onClick={handleMenuClick}
            disabled={!canCreate || clientPayRestrictions.disabled}
            items={[
                {
                    key: 'send-info',
                    icon: sending ? <LoadingOutlined /> : <SendOutlined />,
                    label: 'Send Info',
                    disabled: sending,
                },
                {
                    key: 'login-as',
                    icon: loadingForLogin ? <LoadingOutlined /> : <LoginOutlined />,
                    label: 'Login As',
                    disabled: loadingForLogin,
                },
            ]}
        />
    );

    if (clientPayRestrictions.disabled) {
        return (
            <Tooltip overlay={clientPayRestrictions.overlay} placement="bottomRight">
                { menu }
            </Tooltip>
        );
    }

    return menu;
}

import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import { DispatchProp, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Input, Select } from 'antd';

import { displayErrorNotification } from 'utils/errors';

import { IOrganization } from 'models/organization';
import { IOrganizationUpdateBasicDetailsPayload } from 'models/payloads/organization';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { updateBasicOrganizationInfo } from 'api/organizations';
import { orgRefresh } from 'store/actions/org';
import { authRefreshUser } from 'store/actions/auth';
import { hasAccessTo } from 'store/selectors/permissions';

interface IFormValues {
    name: string;
    number: string;
    email: string;
    mailingLine1: string;
    mailingLine2: string;
    mailingCity: string;
    mailingState: string;
    mailingZip: string;
}

interface IBasicSettingsProps extends DispatchProp {
    org: IOrganization;
}

export const BasicSettingsView: FC<IBasicSettingsProps> = (props) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm<IFormValues>();
    const canUpdate = useSelector(hasAccessTo(PermissionFeature.Organization, PermissionAction.Update));

    const numberAddon = useMemo(() => (
        <Select value="+1" disabled>
            <Select.Option value="+1">+1</Select.Option>
        </Select>
    ), []);

    const { mailing } = props.org.addresses;

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();

            const payload: IOrganizationUpdateBasicDetailsPayload = {
                name: values.name,
                number: values.number,
                email: values.email,
                mailing: {
                    label: 'Mailing',
                    streetAddresses: [values.mailingLine1],
                    city: values.mailingCity,
                    state: values.mailingState,
                    zipCode: values.mailingZip,
                },
            };

            if (values.mailingLine2) {
                payload.mailing.streetAddresses.push(values.mailingLine2);
            }

            setSaving(true);

            await updateBasicOrganizationInfo(props.org.id, payload);
            await props.dispatch(orgRefresh(props.org.id) as any);
            await props.dispatch(authRefreshUser() as any);
        } catch (e) {
            displayErrorNotification(e);
        }

        setSaving(false);
    };

    return (
        <Row>
            <Col>
                <Form<IFormValues>
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                    disabled={saving || !canUpdate}
                    initialValues={{
                        name: props.org.name,
                        number: props.org.phoneNumber.number,
                        email: props.org.email,
                        mailingLine1: mailing.streetAddresses.length >= 1 ? mailing.streetAddresses[0] : '',
                        mailingLine2: mailing.streetAddresses.length >= 2 ? mailing.streetAddresses[1] : '',
                        mailingCity: mailing.city,
                        mailingState: mailing.state,
                        mailingZip: mailing.zipCode,
                    }}
                >
                    <Form.Item name="name" label="Business/Organization Name" rules={[{ required: true, message: 'The business name is required.' }]}>
                        <Input
                            placeholder="name"
                            autoComplete="organization-name"
                        />
                    </Form.Item>

                    <Form.Item label="Contact Number">
                        <Form.Item name="number" rules={[{ required: true, message: 'A contact number is required for the organization.' }, { pattern: /^\d{10}$/, message: 'Invalid number format, include numbers only.' }]}>
                            <Input
                                addonBefore={numberAddon}
                                placeholder="Contact Number"
                                inputMode="tel"
                            />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item name="email" label="Email Address" rules={[{ required: true, message: 'A contact email is required for the business.' }, { type: 'email', message: 'The email address must be valid.' }]}>
                        <Input
                            placeholder="example@email.com"
                            type="email"
                            inputMode="email"
                        />
                    </Form.Item>

                    <div className="address-container">
                        <Form.Item name="mailingLine1" label="Mailing Address" rules={[{ required: true, message: 'The mailing address street is required.' }]}>
                            <Input
                                placeholder="Street"
                                autoComplete="mailing address-line1"
                            />
                        </Form.Item>

                        <Form.Item name="mailingLine2">
                            <Input
                                placeholder="Street two"
                                autoComplete="mailing address-line2"
                            />
                        </Form.Item>

                        <Form.Item name="mailingCity" rules={[{ required: true, message: 'The mailing address city is required.' }]}>
                            <Input
                                placeholder="City"
                                autoComplete="mailing address-level2"
                            />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="mailingState" rules={[{ required: true, message: 'The mailing address state is requried.' }]}>
                                    <Input
                                        placeholder="State"
                                        autoComplete="mailing address-level1"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="mailingZip" rules={[{ required: true, message: 'The mailing zip code is required.' }, { pattern: /^\d{5}$/, message: 'Invalid number format, include numbers only.' }]}>
                                    <Input
                                        min={0}
                                        minLength={5}
                                        maxLength={5}
                                        style={{ width: '100%' }}
                                        placeholder="Zip"
                                        autoComplete="mailing zip"
                                        inputMode="numeric"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="submit"
                            loading={saving}
                        >Update</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

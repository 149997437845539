import React from 'react';
import { Modal, Row, Col, Form, DatePicker, Alert } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import type { ILoan } from 'models/loan';
import { updateNextDueDate } from 'api/loans';

import { trackUmami } from 'utils/umami';
import { displayErrorNotification } from 'utils/errors';

interface IChangeDueDateModalProps {
    loan: Partial<ILoan>;
    isVisible: boolean;
    close: (updated?: boolean) => Promise<void>;
}

interface IChangeDueDateModalState {
    isSaving: boolean;
    newDueDate: Dayjs;
    lastFullPaymentDate: Dayjs;
}

export class ChangeDueDateModal extends React.PureComponent<IChangeDueDateModalProps, IChangeDueDateModalState> {
    state: Readonly<IChangeDueDateModalState> = {
        isSaving: false,
        newDueDate: dayjs(),
        lastFullPaymentDate: dayjs(),
    }

    componentDidMount() {
        if (!this.props.loan || !this.props.loan.nextDueDate) {
            return;
        }

        this.setState({
            newDueDate: dayjs(this.props.loan.nextDueDate),
            lastFullPaymentDate: dayjs(this.props.loan.closingDate),
        });

        if (!this.props.loan.lastTransactionDate) {
            return;
        }

        const closeDate = dayjs(this.props.loan.closingDate);
        const lastTransDate = dayjs(this.props.loan.lastTransactionDate);

        this.setState({ lastFullPaymentDate: lastTransDate.isAfter(closeDate) ? lastTransDate : closeDate });
    }

    componentDidUpdate(prevProps: IChangeDueDateModalProps) {
        if (!prevProps.loan || !this.props.loan) {
            return;
        }

        if (prevProps.loan.nextDueDate !== this.props.loan.nextDueDate) {
            this.setState({ newDueDate: dayjs(this.props.loan.nextDueDate) });
            return;
        }
    }

    onClose = () => {
        this.props.close(false);
        this.setState({ newDueDate: dayjs() });
    }

    save = () => {
        this.setState({ isSaving: true }, async () => {
            const { loan } = this.props;

            try {
                await updateNextDueDate(loan.organization!.id, loan.id!, this.state.newDueDate.toJSON());
                trackUmami('Update Loan Due Date');
                await this.props.close(true);

                this.setState({ isSaving: false, newDueDate: dayjs() });
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ isSaving: false });
            }
        });
    }

    disabledDate = (current: Dayjs | null): boolean => {
        if (!current || !this.state.lastFullPaymentDate) {
            return false;
        }

        return current.isBefore(this.state.lastFullPaymentDate);
    }

    onDateChange = (value: Dayjs | null) => {
        if (!value) {
            return;
        }

        this.setState({ newDueDate: value });
    }

    get datePicker() {
        return (
            <Form.Item label="New Due Date">
                <DatePicker
                    format="MM/DD/YYYY"
                    value={this.state.newDueDate}
                    disabledDate={this.disabledDate}
                    onChange={this.onDateChange}
                    showToday
                    disabled={this.state.isSaving}
                />
            </Form.Item>
        );
    }

    render() {
        const { isVisible } = this.props;

        if (!isVisible) {
            return null;
        }

        return (
            <Modal
                open={isVisible}
                title={<span><CalendarOutlined /> New Due Date</span>}
                okText="Save"
                onOk={this.save}
                onCancel={this.onClose}
                closable={!this.state.isSaving}
                okButtonProps={{ disabled: this.state.isSaving, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
            >
                <Row>
                    <Col>
                        <Alert type="info" message="Please note that when changing a due date, all future payments will follow the new date structure." />
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    <Col>
                        {this.datePicker}
                    </Col>
                </Row>
            </Modal>
        );
    }
}

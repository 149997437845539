import React, { useState } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { Modal, Form, Typography, DatePicker, Alert, InputNumber, Input } from 'antd';

import { ILoan, LoanStatus, LoanType } from 'models/loan';
import { displayErrorNotification } from 'utils/errors';

import { setLoanStatus } from 'api/loans';

interface IFormValues {
    date: Dayjs;
    acres?: number;
    label?: string;
}

interface IMarkRepossessedModalProps {
    orgId: string;
    loan: ILoan;
    visible: boolean;
    close: (repossessed: boolean) => Promise<void>;
}

export const MarkRepossessedModal: React.FC<IMarkRepossessedModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [saving, setSaving] = useState(false);

    const cancel = () => {
        props.close(false);
        form.resetFields();
    };

    const onOkayClick = () => {
        setSaving(true);

        form.validateFields().then(async (values) => {
            try {
                console.log('repossessed values:', values);
                await setLoanStatus(props.orgId, props.loan.id, LoanStatus.Repossessed, values.date.toJSON(), values.acres, values.label);

                await props.close(true);
                form.resetFields();
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                setSaving(false);
            }
        }).catch((e) => {
            console.log('fail to validate the form:', e);
            setSaving(false);
        });
    };

    const disabledDate = (current: Dayjs | null): boolean => {
        const { loan } = props;

        const lastTransDate = dayjs(loan.lastTransactionDate);
        if (!current || !loan || !loan.lastTransactionDate || !lastTransDate.isValid() || lastTransDate.get('year') === 0) {
            return false;
        }

        return current.isBefore(lastTransDate) || current.isAfter(dayjs().add(1, 'day'));
    }

    const acreFormItem = (
        <Form.Item
            name="acres"
            label="Tract Acres"
            extra="When moving a loan from inactive to repossessed, the number of acres must be provided."
            rules={[
                { required: true, message: 'The number of acres on the original tract must be provided.' },
                { min: 0.01, type: 'number', message: 'The acre amount must be greater than zero.' },
            ]}
        >
            <InputNumber disabled={saving} min={0.00} step={0.01} />
        </Form.Item>
    );

    const tractLabelFormItem = (
        <Form.Item
            name="label"
            label="Tract Label"
            extra="When moving a loan from inactive to repossessed, the tract label must be provided."
            rules={[
                { required: true, message: 'The label (or name) of the tract must be provided.' },
            ]}
        >
            <Input disabled={saving} />
        </Form.Item>
    );

    const warningText = (
        <React.Fragment>
            <Typography.Text>Marking the loan as repossessed will disassociate the loan from the tract.&nbsp;</Typography.Text>
            <Typography.Text type="danger">This action is unable to be reverted.&nbsp;</Typography.Text>
            <Typography.Text>Are you sure you want to continue? No further actions will be allowed on the loan.</Typography.Text>
        </React.Fragment>
    );

    return (
        <Modal
            title="Mark as Repossessed"
            open={props.visible}
            onOk={onOkayClick}
            okText="Repossess"
            okButtonProps={{ loading: saving, disabled: saving }}
            onCancel={cancel}
            cancelText="Whoops, no!"
            cancelButtonProps={{ disabled: saving }}
            closable={!saving}
            maskClosable={false}
        >
            <Alert type="warning" description={warningText} style={{ marginBottom: '20px' }} />

            <Form<IFormValues> form={form} layout="vertical">
                <Form.Item name="date" label="Date of Repossession" extra="The date in which the loan was closed and the property repossessed." rules={[{ required: true, message: 'The date of repossession is required for reporting purposes.' }]}>
                    <DatePicker format="MM/DD/YYYY HH:mm:ss" showTime disabled={saving} disabledDate={disabledDate} />
                </Form.Item>

                { props.loan.type === LoanType.Tract && props.loan.status === LoanStatus.Inactive ?
                    acreFormItem
                : null}

                { props.loan.type === LoanType.Tract && props.loan.status === LoanStatus.Inactive ?
                    tractLabelFormItem
                : null }
            </Form>
        </Modal>
    );
}

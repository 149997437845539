import React from 'react';
import { Alert, Col, Tag, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { IPostalAddress, PostalAddressDeliverability } from 'models/common/postalAddress';

interface IAddressProps {
    value?: IPostalAddress;
    showLabel?: boolean;
    showTags?: boolean;
}

export const Address: React.FC<IAddressProps> = React.memo((props) => {
    if (!props.value) {
        return null;
    }

    let copyValue = '';

    if (props.value.streetAddresses.length >= 1) {
        copyValue += props.value.streetAddresses[0] + '\n';
    }

    if (props.value.streetAddresses.length >= 2) {
        copyValue += props.value.streetAddresses[1] + '\n';
    }

    if (props.value.city) {
        copyValue += props.value.city;
    }

    if (props.value.state) {
        if (props.value.city) {
            copyValue += ', ';
        }

        copyValue += props.value.state + ' ';
    }

    if (props.value.zipCode) {
        copyValue += props.value.zipCode;
    }

    return (
        <Typography.Text copyable={{ text: copyValue }}>
            {props.value.label && props.showLabel ? <React.Fragment>{props.value.label} <br /></React.Fragment> : null}
            {props.value.streetAddresses.length >= 1 && props.value.streetAddresses[0] ? props.value.streetAddresses[0] : '-'} <br />
            {props.value.streetAddresses.length >= 2 ? <React.Fragment>{props.value.streetAddresses[1]} <br /></React.Fragment> : null}
            {props.value.city}, {props.value.state} {props.value.zipCode}{props.value.plus4Code ? `-${props.value.plus4Code}` : null} <br />

            <AddressDeliverabilityTag value={props.value} showTags={props.showTags} />
        </Typography.Text>
    );
});

export const AddressDeliverabilityTag: React.FC<IAddressProps> = React.memo((props) => {
    if (!props.value || !props.showTags) {
        return null;
    }

    //if the address is not verified or there is not a deliverability, show the verified tag
    if (!props.value.verified || !props.value.deliverability) {
        return (
            <Tooltip overlay={props.value.verified ? 'Address is verified and snail mail can be sent to it.' : 'Address needs verified before sending snail mail.'}>
                <Tag color={props.value.verified ? 'green' : 'volcano'}>
                    {props.value.verified ? <CheckCircleOutlined /> : <WarningOutlined />} &nbsp;
                    {props.value.verified ? 'Verified' : 'Unverified'}
                </Tag>
            </Tooltip>
        );
    }

    let color = 'green';
    let icon = <CheckCircleOutlined />;

    switch (props.value.deliverability) {
        case PostalAddressDeliverability.Deliverable:
            break;
        case PostalAddressDeliverability.Undeliverable:
            color = 'red';
            icon = <CloseCircleOutlined />;
            break;
        case PostalAddressDeliverability.IncorrectUnit:
        case PostalAddressDeliverability.MissingUnit:
            color = 'volcano';
            icon = <WarningOutlined />;
            break;
    }

    return (
        <Tag color={color} className="title-caps">
            {icon} &nbsp;
            {props.value.deliverability.replace('_', ' ')}
        </Tag>
    );
});


interface IAddressDeliverabilityWarningProps {
    label: string;
    address?: IPostalAddress;
    style?: React.CSSProperties;
}

export const AddressDeliverabilityWarning: React.FC<IAddressDeliverabilityWarningProps> = React.memo((props) => {
    const { address } = props;

    // ensure an address was provided
    if (!address) {
        return null;
    }

    // we only show alerts if the address is verified and the deliverability on the address is set
    if (!address.verified || !address.deliverability) {
        return null;
    }

    let type: 'warning' | 'error' = 'warning';
    let warningMessage = `${props.label}'s address is verified and deliverable. Editing it will revoke the verification status and require it to be verified again before a letter can be sent.`;
    switch (address.deliverability) {
        case PostalAddressDeliverability.Deliverable:
            break;
        case PostalAddressDeliverability.Undeliverable:
            type = 'error';
            warningMessage = `${props.label}'s address is undeliverable. No physical mail or letters can be sent until the address is corrected and marked as deliverable.`;
            break;
        case PostalAddressDeliverability.IncorrectUnit:
            warningMessage = `${props.label}'s address is undeliverable because the unit (apartment, suite, etc) provided is incorrect. Please correct this in order to send physical mail.`;
            break;
        case PostalAddressDeliverability.MissingUnit:
            warningMessage = `${props.label}'s address is undeliverable because it is missing the unit (apartment, suite, etc). Please correct this in order to send physical mail.`;
            break;
    }

    return (
        <Col span={24} style={{ marginBottom: '16px', ...props.style }}>
            <Alert
                type={type}
                showIcon
                message={warningMessage}
            />
        </Col>
    );
});

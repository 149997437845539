import React from 'react';
import currency from 'currency.js';
import type { Dayjs } from 'dayjs';

import { connect, DispatchProp } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, FormInstance, Button, Input, InputNumber, DatePicker, Select, Radio } from 'antd';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';
import { LoanLateFeeApplication, LoanLateFeeChargeType } from 'models/loan';

import { INewLoanFormValues } from './formValues';

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    orgId: getSelectedOrgShortId(state),
});

interface INewLoanTermsStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    goNext: () => void;
    goBack: () => void;
}

interface INewLoanFormTermsStepState {}

class NewLoanFormTermsStepBase extends React.PureComponent<INewLoanTermsStepProps, INewLoanFormTermsStepState> {
    state: Readonly<INewLoanFormTermsStepState> = {}

    get closingDate() {
        return (
            <Form.Item name="closingDate" label="Closing Date" extra="The date when the papers were signed." rules={[{ required: true, message: 'Please select closing date.' }]}>
                <DatePicker
                    format="MM/DD/YYYY"
                    showToday={false}
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get firstPaymentDate() {
        return (
            <Form.Item name="firstPaymentDate" label="First Payment Date" extra="The date the first payment is due." rules={[{ required: true, message: 'Please select the day the first payment is due.' }]}>
                <DatePicker
                    format="MM/DD/YYYY"
                    showToday={false}
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get daysUntilLate() {
        return (
            <Form.Item
                name="daysUntilLate"
                label="Days Until Late"
                extra="How long the payee has after the due date until the payment is considered late."
                rules={[{ required: true, message: 'Please input the amount of days before the payment is marked as late.' }]}
            >
                <InputNumber
                    min={0}
                    step={1}
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get lateFeeDisabled() {
        return (
            <Form.Item
                name="lateFeeDisabled"
                label="Late Fees Applied"
                extra="Do you want Lendiom to automatically apply late fees?"
                rules={[{ required: true, message: 'Please indicate whether or not to automatically apply late fees.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={false}>Automatically</Radio.Button>
                    <Radio.Button value={true}>Manually</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    get lateFeeApplication() {
        return (
            <Form.Item
                name="lateFeeApplication"
                label="Late Fee Application"
                extra={`How should the late fee be applied to the loan? When the application is "Balance" then the late fee will never be automatically paid.`}
                rules={[{ required: true, message: 'Please select how the late fee should be applied.' }]}
            >
                <Select<LoanLateFeeApplication>>
                    <Select.Option key={LoanLateFeeApplication.First} value={LoanLateFeeApplication.First}>First Part of Next Payment</Select.Option>
                    <Select.Option key={LoanLateFeeApplication.Principal} value={LoanLateFeeApplication.Principal}>Added to Principal</Select.Option>
                    <Select.Option key={LoanLateFeeApplication.Balance} value={LoanLateFeeApplication.Balance}>Added to Late Fee Balance</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get lateFeeChargeType() {
        return (
            <Form.Item
                name="lateFeeChargeType"
                label="Late Fee Charge Type"
                extra="How should the late fee be calculated?"
                rules={[{ required: true, message: 'Please select how the late fee should be calculated.' }]}
            >
                <Select<LoanLateFeeChargeType>>
                    <Select.Option key={LoanLateFeeChargeType.Fixed} value={LoanLateFeeChargeType.Fixed}>Fixed Amount</Select.Option>
                    <Select.Option key={LoanLateFeeChargeType.Percent} value={LoanLateFeeChargeType.Percent}>Percentage</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get lateFeeFixedAmount() {
        const { getFieldValue } = this.props.form;

        if (getFieldValue('lateFeeChargeType') !== LoanLateFeeChargeType.Fixed) {
            return null;
        }

        return (
            <Form.Item
                name="lateFeeFixedAmount"
                label="Fixed Late Fee Amount"
                extra="How much the payee will be charged for being late."
                rules={[{ required: true, message: 'Please provide how much the fixed late fee amount is.' }]}
            >
                <Input
                    prefix="$"
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get lateFeePercentage() {
        const { getFieldValue } = this.props.form;

        if (getFieldValue('lateFeeChargeType') !== LoanLateFeeChargeType.Percent) {
            return null;
        }

        return (
            <Form.Item
                name="lateFeePercentage"
                label="Late Fee Percentage"
                extra="How much the payee will be charged for being late."
                rules={[{ required: true, message: 'Please provide how much the late fee percentage is.' }]}
            >
                <Input
                    suffix="%"
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get minimumLateFeeAmount() {
        const { getFieldValue } = this.props.form;

        if (getFieldValue('lateFeeChargeType') !== LoanLateFeeChargeType.Percent) {
            return null;
        }

        return (
            <Form.Item
                name="minimumLateFeeAmount"
                label="Min Late Fee"
                extra="What is the lowest amount that the late fee can be?"
                rules={[{ required: true, message: 'Please provide how the minimum late fee to charge the payee.' }]}
            >
                <Input
                    prefix="$"
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get maximumLateFeeAmount() {
        const { getFieldValue } = this.props.form;

        if (getFieldValue('lateFeeChargeType') !== LoanLateFeeChargeType.Percent) {
            return null;
        }

        return (
            <Form.Item
                name="maximumLateFeeAmount"
                label="Max Late Fee"
                extra="What is the highest amount that the late fee can be?"
                rules={[{ required: true, message: 'Please provide how the maximum late fee to charge the payee.' }]}
            >
                <Input
                    prefix="$"
                    style={{width: '100%'}}
                />
            </Form.Item>
        );
    }

    get bottomButtons() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue }) => {
                    const closingDate = getFieldValue('closingDate') as Dayjs;
                    const firstPaymentDate = getFieldValue('firstPaymentDate') as Dayjs;
                    const daysUntilLate = getFieldValue('daysUntilLate') as number;
                    const lateFeeDisabled = getFieldValue('lateFeeDisabled') as boolean;
                    const lateFeeApplication = getFieldValue('lateFeeApplication') as string;
                    const lateFeeChargeType = getFieldValue('lateFeeChargeType') as string;
                    const lateFeeFixedAmount = getFieldValue('lateFeeFixedAmount') as string;
                    const lateFeePercentage = getFieldValue('lateFeePercentage') as string;
                    const minimumLateFeeAmount = currency(getFieldValue('minimumLateFeeAmount'), { precision: 2 });
                    const maximumLateFeeAmount = currency(getFieldValue('maximumLateFeeAmount'), { precision: 2 });

                    const nextDisabled = !closingDate || !closingDate.isValid()
                        || !firstPaymentDate || !firstPaymentDate.isValid()
                        || firstPaymentDate.isBefore(closingDate) // the payment date can not be before the closing date
                        || (!daysUntilLate && daysUntilLate !== 0) || daysUntilLate < 0
                        || (typeof lateFeeDisabled !== 'boolean')
                        || !lateFeeApplication || (lateFeeApplication !== LoanLateFeeApplication.First && lateFeeApplication !== LoanLateFeeApplication.Principal && lateFeeApplication !== LoanLateFeeApplication.Balance)
                        || !lateFeeChargeType || (lateFeeChargeType !== LoanLateFeeChargeType.Fixed && lateFeeChargeType !== LoanLateFeeChargeType.Percent)
                        || (lateFeeChargeType === LoanLateFeeChargeType.Fixed && !lateFeeFixedAmount)
                        || (lateFeeChargeType === LoanLateFeeChargeType.Percent && !lateFeePercentage)
                        || minimumLateFeeAmount.intValue < 0 || maximumLateFeeAmount.intValue < 0 || minimumLateFeeAmount.intValue > maximumLateFeeAmount.intValue;

                    return (
                        <Button.Group>
                            <Button icon={<LeftOutlined />} onClick={this.props.goBack}>Previous</Button>
                            <Button type="primary" onClick={this.props.goNext} disabled={nextDisabled}>
                                Next <RightOutlined />
                            </Button>
                        </Button.Group>
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.closingDate}
                {this.firstPaymentDate}

                {this.daysUntilLate}
                {this.lateFeeDisabled}
                {this.lateFeeApplication}
                {this.lateFeeChargeType}

                <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.lateFeeChargeType !== curr.lateFeeChargeType}>
                    {() => (
                        <React.Fragment>
                            {this.lateFeeFixedAmount}
                            {this.lateFeePercentage}
                            {this.minimumLateFeeAmount}
                            {this.maximumLateFeeAmount}
                        </React.Fragment>
                    )}
                </Form.Item>

                {this.bottomButtons}
            </React.Fragment>
        );
    }
}

export const NewLoanTermsStep = connect(mapStateToProps)(NewLoanFormTermsStepBase);

import { notification } from 'antd';
import { displayUnauthorizedModal } from 'components/misc/authorizationModal';
import { UnauthorizedError } from 'models/common/restResult';

export function displayErrorNotification(e: any, refresh?: () => void) {
    console.error('Displayed the error notification:', e);

    if (typeof e === 'undefined') {
        notification.error({ message: 'Unknown error' });
    } else if (typeof e === 'string') {
        notification.error({ message: e });
    } else if (typeof e === 'number') {
        notification.error({ message: `Unknown error with a status code of: ${ e }`});
    } else if (e instanceof UnauthorizedError) {
        displayUnauthorizedModal(e, refresh);
    } else if (typeof e.message === 'undefined') {
        notification.error({ message: `${ e.error } (${ e.code })` });
    } else {
        notification.error({ message: e.message });
    }
}

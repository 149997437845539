import React from 'react';
import currency from 'currency.js';

import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { isMobileOnly } from 'react-device-detect';
import { FaFileCsv } from 'react-icons/fa';

import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { ContactsOutlined, CreditCardOutlined, FileOutlined, InfoCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Card, Button, Tooltip, Tag, Progress, Popover } from 'antd';
import type { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

import { AccessControlledButton, AccessControlledWrapper } from 'components/permissions';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';

import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { wrapUnableToCreateTooltip } from 'utils/tooltipWrapper';
import { SimpleDate } from 'utils/formatting';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { StripeConnectButton } from 'components/stripe/stripeConnectButton';
import { LoansList } from 'components/loans/loansList';
import { NewTransactionModal } from 'components/loans/newTransactionModal';
import { LoanStatusTag } from 'components/loans/loanStatusTag';
import { loanActionsAreDisabled } from 'components/loans/loanActionsAreDisabled';
import { statusSorter } from 'components/loans/loansSorter';
import { TableSearchInput } from 'components/misc/table-search-input';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import type { ILoanSchedule } from 'models/loanSchedule';
import { ILoan, LoanStatus, LoanType } from 'models/loan';
import type { IBillingLoanUsage } from 'models/billing/loanUsage';
import type { Currency } from 'models/currency';

import { GlobalState } from 'store';
import { getUser } from 'store/selectors/auth';
import { getSelectedOrg, isFetchingOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { getLoansForSelectedOrg, getLoansPagination } from 'store/selectors/loans';
import { fetchLoans, fetchLoanById } from 'store/actions/loans';

import { displayErrorNotification } from 'utils/errors';

import { getLoanAmortizationSchedule, getLoanTransactions } from 'api/loans';
import { getActiveLoanUsage } from 'api/stats';


const mapStateToProps = (state: GlobalState) => ({
    user: getUser(state),
    isFetchingOrg: isFetchingOrg(state),
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
    loans: getLoansForSelectedOrg(state),
    pagination: getLoansPagination(state),
});

interface ILoansProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IOrgIdPathParams> { }

interface ILoansState {
    isLoading: boolean;
    selectedLoanForPayment?: ILoan;
    selectedLoanScheduleForPayment?: ILoanSchedule;
    isNewLoanTransactionVisible: boolean;
    page: number;
    pageSize: number;
    sortBy: string;
    labelSearch: string;
    statusSearch?: LoanStatus[];
    usage?: IBillingLoanUsage;
}

class LoansBase extends React.PureComponent<ILoansProps, ILoansState> {
    state: Readonly<ILoansState> = {
        isNewLoanTransactionVisible: false,
        isLoading: true,
        page: 1,
        pageSize: 10,
        sortBy: 'status',
        labelSearch: '',
    };

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps: ILoansProps) {
        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            return;
        }

        this.refreshData();
    }

    refreshData = async () => {
        this.setState({ isLoading: true });

        await this.props.dispatch(fetchLoans(this.state.page, this.state.pageSize, this.state.sortBy, this.state.labelSearch, this.state.statusSearch) as any);

        try {
            const usage = await getActiveLoanUsage(this.props.match.params.orgId);
            this.setState({ usage });
        } catch (e) {
            displayErrorNotification(e);
        }

        this.setState({ isLoading: false });
    }

    columns: ColumnProps<ILoan>[] = [
        {
            title: 'Label', dataIndex: 'label', key: 'label', sorter: true,
            filterDropdown: (props) => <TableSearchInput {...props} placeholder="Search by label" />, filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            render: (value, record) => {
                return (
                    <span>
                        { value } <Tag className="title-caps">{ record.type.replaceAll('-', ' ') }</Tag>
                    </span>
                );
            }
        },
        {
            title: 'Status', dataIndex: 'status', key: 'status', sorter: true, defaultSortOrder: 'ascend',
            render: (value) => <LoanStatusTag status={value} />,
            filters: [
                { text: 'Active', value: LoanStatus.Active },
                { text: 'Paid Off', value: LoanStatus.PaidOff },
                { text: 'Repossessed', value: LoanStatus.Repossessed },
                { text: 'Inactive', value: LoanStatus.Inactive },
                { text: 'Late', value: LoanStatus.Late },
                { text: 'In Default', value: LoanStatus.InDefault },
                { text: 'Draft', value: LoanStatus.Draft },
            ],
        },
        {
            title: 'Payment', dataIndex: ['terms', 'payment'], key: 'payment',
            render: (value: Currency) => `${ currency(value, { precision: 2 }).format(true) }`,
        },
        {
            title: 'Rate', dataIndex: ['terms', 'rate'], key: 'rate',
            render: (value: Currency) => `${ currency(value, { precision: 2 }).format() }%`,
        },
        {
            title: <Tooltip overlay="Last date a payment transaction was recorded.">Last Payment Date</Tooltip>,
            dataIndex: 'lastPaymentReceivedDate', key: 'lastPaymentReceivedDate', sorter: true,
            render: (value: string) => <SimpleDate date={ value } />,
        },
        {
            title: <Tooltip overlay="The next date a payment is due.">Next Due Date</Tooltip>,
            dataIndex: 'nextDueDate', key: 'nextDueDate', sorter: true,
            render: (value: string) => <SimpleDate date={ value } />,
        },
        {
            title: '', dataIndex: '', key: 'action',
            render: (nothing: any, record: ILoan) => <ActionsMenu<ILoan> record={record} actions={this.actions} onClick={this.onActionMenuClick} />,
        },
    ];

    onTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ILoan> | SorterResult<ILoan>[]) => {
        const toSet = {
            page: pagination.current || 1,
            pageSize: pagination.pageSize || 25,
            labelSearch: this.state.labelSearch,
            sortBy: this.state.sortBy,
        };

        if (filters && typeof filters.label !== 'undefined') {
            toSet.labelSearch = Array.isArray(filters.label) ? filters.label[0] as string : '';
        }

        if (filters && Array.isArray(filters.status)) {
            (toSet as any).statusSearch = filters.status as LoanStatus[];
        }

        if (!Array.isArray(sorter) && sorter.column && sorter.field && !Array.isArray(sorter.field)) {
            toSet.sortBy = sorter.order === 'ascend' ? sorter.field as string : `-${ sorter.field }`;
        }

        this.setState(toSet, this.refreshData);
    }

    onLoansListPageChange = (page: number, pageSize: number) => {
        if (this.state.page === page && this.state.pageSize === pageSize) {
            return;
        }

        this.setState({ page, pageSize }, this.refreshData);
    }

    //#region menu
    actions: ActionMenuItemMap<ILoan> = {
        details: { icon: <InfoCircleOutlined />, text: 'Details' },
        'record-transaction': { icon: <CreditCardOutlined />, text: 'Record Transaction', disabled: (loan) => loanActionsAreDisabled(loan.status) },
        'download-transactions': { icon: <FaFileCsv />, text: 'Download Transactions' },
        'details-tract': { icon: <FileOutlined />, text: 'View Tract', hidden: (loan) => loan.type !== LoanType.Tract || !loan.tracts || loan.tracts.length > 1 },
        'details-res-inv': { icon: <FileOutlined />, text: 'View Inventory', hidden: (loan) => loan.type !== LoanType.Residential },
        'details-client': { icon: <ContactsOutlined />, text: 'View Client' },
    }

    onActionMenuClick = async (loan: ILoan, actionKey: string) => {
        switch (actionKey) {
            case 'details':
                this.props.history.push(`/${this.props.match.params.orgId}/loans/${loan.id}`);
                return;
            case 'details-tract':
                if (!loan.tracts || loan.tracts.length === 0) {
                    return;
                }

                this.props.history.push(`/${this.props.match.params.orgId}/inventories/${ loan.tracts[0].inventoryId }/tracts/${ loan.tracts[0].tractId }`);
                return;
            case 'details-res-inv':
                this.props.history.push(`/${this.props.match.params.orgId}/inventories/${ loan.residential?.inventoryId }`);
                return;
            case 'details-client':
                this.props.history.push(`/${this.props.match.params.orgId}/clients/${ loan.client.id }`);
                return;
            case 'record-transaction':
                this.showRecordTransactionFor(loan);
                return;
            case 'download-transactions':
                await getLoanTransactions(loan.organization.id, loan.id, 'simple');
                return;
            default:
                return;
        }
    }

    showRecordTransactionFor = async (loan: ILoan) => {
        await this.props.dispatch(fetchLoanById(loan.id) as any);

        const l = this.props.loans.find((v) => v.id === loan.id);
        const schedule = await getLoanAmortizationSchedule(this.props.match.params.orgId, loan.id);

        this.setState({ isNewLoanTransactionVisible: true, selectedLoanForPayment: l || loan, selectedLoanScheduleForPayment: schedule });
    }

    closeAddTransaction = (): Promise<void> => {
        return Promise.resolve(this.setState({ isNewLoanTransactionVisible: false, selectedLoanForPayment: undefined, selectedLoanScheduleForPayment: undefined }));
    }
    //#endregion menu

    onRow = (loan: ILoan) => {
        return {
            onClick: (event: React.MouseEvent) => {
                if (event.defaultPrevented) {
                    return;
                }

                this.props.history.push(`/${this.props.match.params.orgId}/loans/${loan.id}`);
            },
        };
    }

    //#region header
    onNewLoanClick = () => this.props.history.push(`/${this.props.match.params.orgId}/loan/new`);

    get headerActions() {
        if (!this.props.selectedOrg) {
            return null;
        }

        let newLoanButton: React.ReactNode = (
            <AccessControlledButton
                type="primary"
                icon={<PlusOutlined />}
                onClick={this.onNewLoanClick}
                disabled={!this.props.canCreate}
                feature={PermissionFeature.Inventory}
                action={PermissionAction.Create}
                prevent={this.props.canCreate ? 'tooltip' : 'disable'}
            >
                Add New Loan
            </AccessControlledButton>
        );

        if (!this.props.canCreate) {
            newLoanButton = wrapUnableToCreateTooltip(newLoanButton, this.props.selectedOrg.billing.status);
        }

        return (
            <Button.Group>
                { newLoanButton }
                <StripeConnectButton />
            </Button.Group>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.user || !this.props.selectedOrg) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.match.params.orgId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${this.props.match.params.orgId}/loans`,
                    breadcrumbName: `${this.props.selectedOrg.name}'s Loans`,
                }
            ],
        };
    }

    get progressBar() {
        if (!this.props.selectedOrg || !this.state.usage || this.state.usage.isNotCalculated) {
            return null;
        }

        return (
            <Popover
                title={`${ this.state.usage.activeLoans } Active Loans`}
                content={(
                    <React.Fragment>
                        Current tier usage for { this.props.selectedOrg.name }.<br />
                        Each tier allows for 100 active loans.<br />
                        This number updates nightly.
                    </React.Fragment>
                )}
                placement="bottomRight"
            >
                <Progress percent={this.state.usage.tierUsagePercentage} steps={5} />
            </Popover>
        );
    }
    //#endregion header

    get loansSorted() {
        const isDate = this.state.sortBy.toLowerCase().includes('date');
        const isStatus = this.state.sortBy.endsWith('status');

        if (this.state.sortBy.startsWith('-')) {
            const key = this.state.sortBy.replace('-', '');

            if (isDate) {
                return this.props.loans.sort((a, b) => new Date((b as any)[key]).getTime() - new Date((a as any)[key]).getTime());
            } else if (isStatus) {
                return this.props.loans.sort((a, b) => statusSorter(b.status, a.status));
            }

            return this.props.loans.sort((a, b) => (b as any)[key].localeCompare((a as any)[key]));
        }

        if (isDate) {
            return this.props.loans.sort((a, b) => new Date((a as any)[this.state.sortBy]).getTime() - new Date((b as any)[this.state.sortBy]).getTime());
        } else if (isStatus) {
            return this.props.loans.sort((a, b) => statusSorter(a.status, b.status));
        }

        return this.props.loans.sort((a, b) => (a as any)[this.state.sortBy].localeCompare((b as any)[this.state.sortBy]));
    }

    get listOrTable() {
        if (isMobileOnly) {
            return (
                <LoansList
                    isLoading={this.state.isLoading}
                    loans={this.loansSorted}
                    recordTransactionFor={this.showRecordTransactionFor}
                    pagination={{
                        defaultPageSize: 10,
                        total: this.props.pagination.count,
                        onChange: this.onLoansListPageChange,
                    }}
                />
            );
        }

        return (
            <Table<ILoan>
                columns={this.columns}
                dataSource={this.loansSorted}
                rowKey="id"
                rowClassName={() => 'pointer'}
                onRow={this.onRow}
                loading={this.state.isLoading}
                scroll={{ x: 'max-content' }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                    total: this.props.pagination.count,
                }}
                onChange={this.onTableChange}
            ></Table>
        );
    }

    render() {
        const headerProps: PageContainerProps = {
            title: 'Loans',
            subTitle: this.progressBar,
            extra: this.headerActions,
            breadcrumb: this.breadcrumbProps,
        };

        return (
            <PageContainer {...headerProps}>
                <Card bordered={false} style={{ marginTop: '24px' }}>
                    {this.listOrTable}
                </Card>

                <NewTransactionModal
                    loan={this.state.selectedLoanForPayment}
                    schedule={this.state.selectedLoanScheduleForPayment}
                    isVisible={this.state.isNewLoanTransactionVisible}
                    close={this.closeAddTransaction}
                />
            </PageContainer>
        );
    }
}

const LoansWithProps = connect(mapStateToProps)(withRouter(LoansBase));

export const Loans: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Loan}
        action={PermissionAction.Read}
        children={<LoansWithProps />}
    />
);

import React, { Suspense, lazy } from 'react';
import shortid from 'shortid';
import currency from 'currency.js';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { isMobileOnly } from 'react-device-detect';

import { Modal, Form, Row, Col, Select, Input, DatePicker, Switch, Alert, FormInstance, Skeleton } from 'antd';
import type { DefaultOptionType, SelectValue } from 'antd/lib/select';
import type { CellRenderInfo, PresetDate } from 'rc-picker/lib/interface';
import type { Rule } from 'antd/es/form';
import type { EditorState } from 'braft-editor';

import { ILoan, ILoanTransaction, LoanTransactionType, LoanTransactionMethod, LoanTransactionStatus, LoanTermsInterestSchedule, LoanLateFeeApplication, LoanType } from 'models/loan';
import { IPropertyTaxForTract } from 'models/propertyTax';
import type { ILoanSchedule, ILoanScheduleItem } from 'models/loanSchedule';

import { calculateAccruedInterestSinceLastPayment } from 'utils/paymentCalc';
import { displayErrorNotification } from 'utils/errors';
import { SimpleTooltip } from 'utils/tooltipWrapper';
import { trackUmami } from 'utils/umami';

import { createLoanTransaction } from 'api/loans';

import './newTransactionModal.css';

const BraftEditorInput = lazy(() => import('components/misc/braftEditorInput'));

interface IFormValues {
    type: LoanTransactionType;
    forPaymentsIndexes: number[];
    amount: string;
    method: LoanTransactionMethod;
    date: Dayjs | null;
    lateFeeWaived: boolean;
    status?: LoanTransactionStatus;
    comment: EditorState;
}

interface INewTransactionModalProps {
    loan?: Partial<ILoan>;
    schedule?: Partial<ILoanSchedule>;
    taxRecord?: IPropertyTaxForTract;
    isVisible: boolean;
    close: (saved: boolean) => Promise<void>;
}

interface INewTransactionModalState {
    isSaving: boolean;
    lastPaymentReceivedDate?: Dayjs;
}

export class NewTransactionModal extends React.PureComponent<INewTransactionModalProps, INewTransactionModalState> {
    state: Readonly<INewTransactionModalState> = {
        isSaving: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidUpdate() {
        const { loan } = this.props;
        if (!loan || !loan.lastPaymentReceivedDate) {
            return;
        }

        const date = dayjs(loan.lastPaymentReceivedDate);
        if (!this.state.lastPaymentReceivedDate) {
            this.setState({ lastPaymentReceivedDate: date });
            return;
        }

        if (this.formRef.current && !this.formRef.current.isFieldTouched('amount') && this.props.taxRecord) {
            this.formRef.current.setFieldValue('amount', currency(this.props.taxRecord.amountOwed, { precision: 2 }).toString());
            this.formRef.current.setFieldValue('type', LoanTransactionType.PropertyTax);
        }

        if (this.state.lastPaymentReceivedDate.isSame(date)) {
            return;
        }

        this.setState({ lastPaymentReceivedDate: date });
    }

    //#region closing and saving logic
    onClose = () => {
        this.props.close(false);
        this.reset();
    };

    reset = () => {
        this.setState({ isSaving: false });

        if (this.formRef.current) {
            this.formRef.current.resetFields();
            this.formRef.current.setFieldValue('interestDue', '0.00');
        }
    }

    handleSave = async () => {
        const { loan } = this.props;

        if (!loan || !this.formRef.current) {
            return;
        }

        try {
            const values = await this.formRef.current.validateFields();

            this.setState({ isSaving: true }, async () => {
                const transaction: Partial<ILoanTransaction> = {
                    id: shortid.generate(),
                    amount: values.amount ? values.amount.replace(/[^0-9.-]/g, '') : values.amount,
                    method: values.method,
                    type: values.type,
                    forPayments: values.forPaymentsIndexes,
                    date: values.date ? values.date.toJSON() : dayjs().toJSON(),
                    lateFeeWaived: values.lateFeeWaived,
                    comment: values.comment.toHTML(),
                    commentText: values.comment.toText(),
                    status: LoanTransactionStatus.Success,
                };

                if (values.status) {
                    transaction.status = values.status;
                }

                try {
                    await createLoanTransaction(loan.organization!.id, loan.id!, transaction);
                    trackUmami('Create Loan Transaction');

                    await this.props.close(true);
                    this.reset();
                } catch (e) {
                    displayErrorNotification(e);
                    this.setState({ isSaving: false });
                }
            });
        } catch (e) {
            console.warn('failed to validate the new loan transaction form:', e);
        }
    }
    //#endregion

    get transactionType() {
        return (
            <Form.Item
                name="type"
                label={<SimpleTooltip content="Type" title="The type of transaction this is, how it applies to the loan." hasQuestion />}
                rules={[{ required: true, message: 'Please state the type of transaction!' }]}
            >
                <Select disabled={this.state.isSaving || typeof this.props.taxRecord !== 'undefined'} onChange={this.onTransactionTypeChange}>
                    <Select.OptGroup label="Principal">
                        <Select.Option key={LoanTransactionType.RegularPayment} value={LoanTransactionType.RegularPayment}>Regular Payment</Select.Option>
                        <Select.Option key={LoanTransactionType.PrincipalPayment} value={LoanTransactionType.PrincipalPayment}>Principal Payment</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Fees">
                        <Select.Option key={LoanTransactionType.LateFee} value={LoanTransactionType.LateFee}>Late Fee</Select.Option>
                        <Select.Option key={LoanTransactionType.OtherFee} value={LoanTransactionType.OtherFee}>Other Fee</Select.Option>
                        { this.props.loan?.type === LoanType.Tract ? <Select.Option key={LoanTransactionType.PropertyTax} value={LoanTransactionType.PropertyTax} disabled={typeof this.props.taxRecord === 'undefined'}>Property Tax</Select.Option> : null }
                    </Select.OptGroup>
                    <Select.OptGroup label="Record Keeping">
                        <Select.Option key={LoanTransactionType.DownPayment} value={LoanTransactionType.DownPayment}>Down Payment</Select.Option>
                        <Select.Option key={LoanTransactionType.EarnestMoney} value={LoanTransactionType.EarnestMoney}>Earnest Money</Select.Option>
                        <Select.Option key={LoanTransactionType.ClosingFee} value={LoanTransactionType.ClosingFee}>Closing Fees</Select.Option>
                        <Select.Option key={LoanTransactionType.DocumentationFee} value={LoanTransactionType.DocumentationFee}>Documentation Fee</Select.Option>
                    </Select.OptGroup>
                </Select>
            </Form.Item>
        );
    }

    onTransactionTypeChange = (value: SelectValue) => {
        if (this.formRef.current?.isFieldTouched('amount')) {
            this.formRef.current.validateFields(['amount']);
        }

        if (!this.props.loan || !this.props.loan.terms || !this.props.loan.terms.downPayment || !this.formRef.current) {
            return;
        }

        if (value === LoanTransactionType.DownPayment) {
            this.formRef.current.setFieldsValue({ amount: this.props.loan.terms.downPayment, forPaymentsIndexes: [] });
        }

        if (value === LoanTransactionType.RegularPayment && this.props.loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            this.formRef.current.setFieldsValue({ amount: this.props.loan.terms.payment, forPaymentsIndexes: [] });
        }
    }

    //#region payment for
    filterOption = (inputValue: string, option?: DefaultOptionType): boolean => {
        if (!option) {
            return false;
        }

        const value = parseInt(inputValue);
        if (isNaN(value)) {
            return false;
        }

        if (!option.props.children) {
            return false;
        }

        return value === option.props.value;
    }

    forPaymentsChange = (selectedPaymentIndexes: number[]) => {
        if (!this.formRef.current) {
            return;
        }

        //TODO: Evaluate how we can use this; supposedly they fixed it
        //but it wasn't working as expected when I uncommented it
        // if (this.formRef.current.isFieldTouched('amount')) {
        //     return;
        // }

        if (!this.props.loan || !this.props.schedule || !this.props.schedule.payments) {
            return;
        }

        if (this.formRef.current.getFieldValue('type') !== LoanTransactionType.RegularPayment) {
            return;
        }

        let suggestedAmount = currency(0, { precision: 2 });
        selectedPaymentIndexes.forEach((paymentIndex) => {
            const payment = this.props.schedule!.payments!.find((p) => p.paymentIndex === paymentIndex);
            if (!payment) {
                return;
            }

            const paid = currency(payment.paymentReceived, { precision: 2 });
            const needed = currency(payment.payment, { precision: 2 });

            let amount = currency(needed);
            if (needed.intValue > paid.intValue) {
                amount = needed.subtract(paid);
            }

            suggestedAmount = suggestedAmount.add(amount);
        });

        this.formRef.current.setFieldsValue({ amount: suggestedAmount.toString() });
    }

    get forPaymentsSelector() {
        if (!this.props.loan) {
            return null;
        }

        // we don't need to show the payment selector since loans with interest accuring daily do not have a payment schedule
        // and instead are based on the interest due that is calculated from the past payment date
        if (this.props.loan.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        let payments: ILoanScheduleItem[] = [];
        if (this.props.schedule && this.props.schedule.payments) {
            payments = this.props.schedule.payments.filter((p) => !p.isFullyPaid);
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transactionType = getFieldValue('type');
                    const isEnabled = transactionType === LoanTransactionType.RegularPayment || transactionType === LoanTransactionType.LateFee;

                    return (
                        <Form.Item name="forPaymentsIndexes" label="Payment For">
                            <Select<number[]>
                                showSearch
                                mode="multiple"
                                placeholder="Select payment(s)"
                                optionFilterProp="children"
                                filterOption={this.filterOption}
                                disabled={!isEnabled || this.state.isSaving || typeof this.props.taxRecord !== 'undefined'}
                                onChange={this.forPaymentsChange}
                            >
                                {payments.map((p) =>
                                    <Select.Option key={p.paymentIndex} value={p.paymentIndex}>
                                        #{p.paymentNumber}{p.isLate ? '*' : null} (Due: {dayjs(p.dueDate).format('MM/DD/YYYY')})
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion payment for

    get interestDueInput() {
        if (!this.props.loan) {
            return null;
        }

        // we only show the interest due input when the interest schedule accrues daily
        // otherwise, the interest follows the payment schedule and we show the selector
        if (this.props.loan.terms?.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') && getFieldValue('type') !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="interestDue"
                            label={<SimpleTooltip content="Interest Due" title="Calculated based on the last payment date plus the unpaid interest. Only goes out to 14 decimal places on the UI, so the actual decimal number may differ." hasQuestion />}
                        >
                            <Input prefix="$" disabled />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get amountInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transactionType = getFieldValue('type') as LoanTransactionType;

                    const rules: Rule[] = [
                        { required: true, message: 'Please state the amount for this transaction!' },
                    ];

                    let extra: React.ReactNode | null = null;
                    switch (transactionType) {
                        case LoanTransactionType.LateFee:
                            extra = "A positive value pays the late fee balance. A negative value adds to the late fee balance.";

                            rules.push({
                                validator: async (rule, value: string) => {
                                    const lateFeesDue = currency(this.props.loan!.balance!.lateFees, { precision: 2 });
                                    const fee = currency(value, { precision: 2 });
                                    if (fee.intValue > 0 && lateFeesDue.intValue <= 0) {
                                        throw new Error('There are currently no late fees due. Did you mean to create a late fee? If so, use negative numbers.');
                                    }

                                    if (fee.intValue > lateFeesDue.intValue) {
                                        throw new Error(`The amount is greater than the late fees due. Current late fee balance is: $${ lateFeesDue.format() }`);
                                    }
                                },
                            });
                            break;
                        case LoanTransactionType.OtherFee:
                            extra = "A positive value subtracts from the other fee balance. A negative value adds to the other fee balance."
                            break;
                        default:
                            rules.push({
                                validator: async (rule, value: string) => {
                                    const fee = currency(value, { precision: 2 });
                                    if (fee.intValue < 0) {
                                        throw new Error('When creating a transaction, the value must be positive as they are paying down an amount.');
                                    }
                                },
                            });
                            break;
                    }

                    return (
                        <Form.Item name="amount" label="Amount" rules={rules} extra={extra}>
                            <Input
                                prefix="$"
                                style={{ width: '100%' }}
                                disabled={this.state.isSaving}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get paymentMethod() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.amount !== curr.amount}>
                {({ getFieldValue }) => {
                    let disabled = false;

                    let extra: React.ReactNode | null = null;
                    switch (getFieldValue('type') as LoanTransactionType) {
                        case LoanTransactionType.LateFee:
                            const lateFeeAmt = currency(getFieldValue('amount'), { precision: 2 });
                            disabled = lateFeeAmt.intValue <= 0;

                            extra = disabled ? null : 'Payment method is required when paying a late fee.';
                            break;
                        case LoanTransactionType.OtherFee:
                            const amt = currency(getFieldValue('amount'), { precision: 2 });
                            disabled = amt.intValue <= 0;

                            extra = disabled ? 'Payment method is not required when adding to the other fee balance.' : 'Payment method is required when subtracting/paying the other fee balance.';
                            break;
                    }

                    return (
                        <Form.Item name="method" label="Payment Method" extra={extra} rules={[{ required: !disabled, message: 'Please state the method of payment!' }]}>
                            <Select<LoanTransactionMethod> disabled={disabled || this.state.isSaving}>
                                <Select.OptGroup label="Banking">
                                    <Select.Option key={LoanTransactionMethod.Card}>Credit/Debit Card</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.Cash}>Cash</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.Check}>Check</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.ACH}>Bank Transfer</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.Wire}>Wire</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.MoneyOrder}>Money Order</Select.Option>
                                </Select.OptGroup>
                                <Select.OptGroup label="Apps">
                                    <Select.Option key={LoanTransactionMethod.CashApp}>Cash App</Select.Option>
                                    <Select.Option key={LoanTransactionMethod.Venmo}>Venmo</Select.Option>
                                </Select.OptGroup>
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    disabledDate = (current: Dayjs | null): boolean => {
        const { loan } = this.props;
        const { lastPaymentReceivedDate } = this.state;

        if (!current || !loan || !lastPaymentReceivedDate || !lastPaymentReceivedDate.isValid()) {
            return false;
        }

        return current.isBefore(lastPaymentReceivedDate);
    }

    renderDate = (current: Dayjs | number | null, info: CellRenderInfo<Dayjs>) => {
        if (typeof current === 'undefined' || current === null) {
            return current;
        }

        if (typeof current === 'number') {
            if (!info.subType) {
                return current;
            }

            switch (info.subType) {
                case 'meridiem':
                    return current === 0 ? 'am' : 'pm';
                case 'hour':
                    return current === 0 ? '12' : current;
                default:
                    return current;
            }
        }

        if (typeof current === 'number') {
            return current;
        }

        const { getFieldValue } = this.formRef.current!;
        const style: React.CSSProperties = {};

        const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
        const transactionType = getFieldValue('type') as LoanTransactionType;
        if (transactionType === LoanTransactionType.RegularPayment || transactionType === LoanTransactionType.LateFee) {
            if (Array.isArray(forPaymentsIndexes) && forPaymentsIndexes.length !== 0) {
                const payments = this.props.schedule!.payments!.filter((p) => forPaymentsIndexes.includes(p.paymentIndex));

                if (payments.length !== 0) {
                    payments.forEach((p) => {
                        let dueDate = dayjs(p.dueDate);

                        if (dueDate.isSame(current, 'day')) {
                            style.border = '1px solid #52c41a';
                        }

                        dueDate = dueDate.add(this.props.loan!.lateFeeConfig!.daysUntilLate, 'days');
                        if (dueDate.isSame(current, 'day')) {
                            style.border = '1px solid #ff4d4f';
                        }
                    });
                }
            } else if (this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
                let dueDate = dayjs(this.props.loan?.nextDueDate);

                if (dueDate.isSame(current, 'day')) {
                    style.border = '1px solid #52c41a';
                }

                dueDate = dueDate.add(this.props.loan!.lateFeeConfig!.daysUntilLate, 'days');
                if (dueDate.isSame(current, 'day')) {
                    style.border = '1px solid #ff4d4f';
                }
            }
        }

        return (
            <div className="ant-calendar-date ant-picker-cell-inner" style={style}>
                {current.date()}
            </div>
        );
    }

    onTransactionDateChange = (date: Dayjs | null) => {
        const { loan } = this.props;
        if (!this.formRef.current || !loan || !loan.terms || !date) {
            return;
        }

        if (loan.terms.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return;
        }

        if (this.formRef.current.getFieldValue('type') !== LoanTransactionType.RegularPayment) {
            return;
        }

        let interestDue = calculateAccruedInterestSinceLastPayment(date, dayjs(loan.lastPaymentReceivedDate), dayjs(loan.firstPaymentDate), loan.terms!, loan.balance!.principal);
        interestDue = interestDue.add(loan.balance?.interest || '0.00');

        this.formRef.current.setFieldValue('interestDue', currency(interestDue, { precision: 2 }).toString());
    }

    get transactionDate() {
        const presets: PresetDate<Dayjs>[] = [];

        if (this.props.loan) {
            presets.push({ label: 'Today', value: dayjs() });
            presets.push({ label: 'Next Due Date', value: dayjs(this.props.loan.nextDueDate) });
            presets.push({ label: 'Last Due Date', value: dayjs(this.props.loan.previousDueDate) });

            if (this.props.loan.lastPaymentReceivedDate && dayjs(this.props.loan.lastPaymentReceivedDate).get('year') !== 0) {
                presets.push({ label: 'Last Payment Date', value: dayjs(this.props.loan.lastPaymentReceivedDate) });
            }

            if (this.props.loan.downPaymentDate && dayjs(this.props.loan.downPaymentDate).get('year') !== 0) {
                presets.push({ label: 'Down Payment Date', value: dayjs(this.props.loan.downPaymentDate) });
            }

            presets.push({ label: 'Closing Date', value: dayjs(this.props.loan.closingDate) });
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const isInterestAccruesDaily = this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily;

                    let useDisabled = false;
                    switch (getFieldValue('type')) {
                        case LoanTransactionType.RegularPayment:
                        case LoanTransactionType.PrincipalPayment:
                        case LoanTransactionType.LateFee:
                            useDisabled = true;
                            break;
                    }

                    return (
                        <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please set the date of this transaction!' }]}>
                            <DatePicker
                                format={ isInterestAccruesDaily ? 'MM/DD/YYYY h:mm a' : 'MM/DD/YYYY' }
                                disabledDate={useDisabled ? this.disabledDate : undefined}
                                disabled={this.state.isSaving}
                                cellRender={this.renderDate}
                                onChange={this.onTransactionDateChange}
                                presets={presets.length > 0 ? presets : undefined}
                                showToday={false}
                                showTime={isInterestAccruesDaily}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get lateFeeWaiveSwitch() {
        if (typeof this.props.taxRecord !== 'undefined') {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={this.shouldWeUpdateLateWarning}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') === LoanTransactionType.OtherFee) {
                        return null;
                    }

                    const isLate = this.isLate();

                    return (
                        <Form.Item name="lateFeeWaived" label="Waive Late Fee" valuePropName="checked" rules={[{ required: isLate }]}>
                            <Switch
                                disabled={!isLate}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get statusSelect() {
        if (!this.props.loan) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;

                    if (transType !== LoanTransactionType.RegularPayment && transType !== LoanTransactionType.PrincipalPayment) {
                        return null;
                    }

                    return (
                        <Col span={24}>
                            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please state the status of the payment!' }]}>
                                <Select disabled={this.state.isSaving}>
                                    <Select.Option key={LoanTransactionStatus.Pending}>Pending</Select.Option>
                                    <Select.Option key={LoanTransactionStatus.Success}>Success</Select.Option>
                                    <Select.Option key={LoanTransactionStatus.Failure}>Failure</Select.Option>
                                    <Select.Option key={LoanTransactionStatus.Reversed}>Reversed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    isLate = (): boolean => {
        if (!this.props.loan || !this.props.schedule || !this.props.schedule.payments || !this.props.loan.lateFeeConfig || !this.formRef.current) {
            return false;
        }

        const { getFieldValue } = this.formRef.current;

        // we only show a warning when the payment type is regular
        if (getFieldValue('type') !== LoanTransactionType.RegularPayment) {
            return false;
        }

        // and only if they've selected the expected payment it is for
        const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
        if (!Array.isArray(forPaymentsIndexes) || forPaymentsIndexes.length === 0) {
            return false;
        }

        const transDate = getFieldValue('date') as Dayjs;
        if (!transDate) {
            return false;
        }

        const payments = this.props.schedule.payments.filter((p) => forPaymentsIndexes.includes(p.paymentIndex));
        if (payments.length === 0) {
            return false;
        }

        return payments.some((p) => {
            if (p.isLate) {
                return true;
            }

            const dueDate = dayjs(p.dueDate).add(this.props.loan!.lateFeeConfig!.daysUntilLate, 'days');

            return transDate.isAfter(dueDate);
        });
    }

    shouldWeUpdateLateWarning = (prev: IFormValues, curr: IFormValues): boolean => {
        if (prev.forPaymentsIndexes.length !== curr.forPaymentsIndexes.length) {
            return true;
        }

        if (!prev.date || !prev.date.isSame(curr.date)) {
            return true;
        }

        if (prev.type !== curr.type) {
            return true;
        }

        return false;
    }

    get lateWarning() {
        return (
            <Form.Item noStyle shouldUpdate={this.shouldWeUpdateLateWarning}>
                {({ getFieldValue }) => {
                    const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
                    if (!this.isLate() || !forPaymentsIndexes || !Array.isArray(forPaymentsIndexes)) {
                        return null;
                    }

                    let message = 'Transaction date is past the due date (including the grace period).';
                    if (forPaymentsIndexes.length > 1) {
                        message = 'Transaction date is past the due date (including the grace period) for one or more of the selected payments.';
                    }

                    switch (this.props.loan?.lateFeeConfig?.application) {
                        case LoanLateFeeApplication.First:
                            message = `${ message } The late fee will be paid first unless waived.`;
                            break;
                        case LoanLateFeeApplication.Balance:
                            message = `${ message } The late fee has been added to the balance. If the late fee is waived, the late fee will be subtracted from the balance.`;
                            break;
                        case LoanLateFeeApplication.Principal:
                            message = `${ message } The late fee has been added to the principal balance. If the late fee is waived, the late fee will be subtracted from the principal balance.`;
                            break;
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={message}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get lateFeeBalanceWarning() {
        if (!this.props.loan || !this.props.loan.balance || this.props.loan.balance.lateFees === "0") {
            return null;
        }

        const lateFeesDue = currency(this.props.loan.balance.lateFees, { precision: 2 });

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.lateFeeWaived !== curr.lateFeeWaived}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;
                    if (transType !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    if (getFieldValue('lateFeeWaived')) {
                        return (
                            <Col span={24} style={{ marginBottom: '15px' }}>
                                <Alert
                                    showIcon
                                    type="info"
                                    message={`The late fee balance of ${lateFeesDue.format(true)} will be waived.`}
                                />
                            </Col>
                        );
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={`There is currently a late fee balance of ${lateFeesDue.format(true)} which will be paid first.`}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get commentInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.method !== curr.method}>
                {({ getFieldValue }) => {
                    const rules: Rule[] = [];

                    if (getFieldValue('type') === LoanTransactionType.OtherFee) {
                        rules.push({
                            required: true,
                            validator: async (rule, value: EditorState) => {
                                if (!value || value.isEmpty()) {
                                    throw new Error('When the transaction type is Other Fee, you must provide a comment on what or why.');
                                }
                            },
                        });
                    }

                    if (getFieldValue('method') === LoanTransactionMethod.Check) {
                        rules.push({
                            required: true,
                            validator: async (rule, value: EditorState) => {
                                if (!value || value.isEmpty()) {
                                    throw new Error('When the payment method is a Check, you must provide a comment with the check number.');
                                }
                            },
                        });
                    }

                    return (
                        <Suspense fallback={<Skeleton.Input block active />}>
                            <BraftEditorInput
                                name="comment"
                                label="Comment"
                                className="last-form-item"
                                height={75}
                                validateTrigger="onBlur"
                                rules={rules}
                            />
                        </Suspense>
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        const { isVisible, loan } = this.props;

        if (!loan) {
            return null;
        }

        let defaultTransactionDate = dayjs();
        if (this.state.lastPaymentReceivedDate && this.state.lastPaymentReceivedDate.isValid() && this.state.lastPaymentReceivedDate.year() !== 0 && defaultTransactionDate.isBefore(this.state.lastPaymentReceivedDate)) {
            defaultTransactionDate = dayjs(this.state.lastPaymentReceivedDate).add(1, 'day');
        }

        let interestDue = currency('0');
        if (loan.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            interestDue = calculateAccruedInterestSinceLastPayment(defaultTransactionDate, dayjs(loan.lastPaymentReceivedDate), dayjs(loan.firstPaymentDate), loan.terms!, loan.balance!.principal);
            interestDue = interestDue.add(loan.balance?.interest || '0.00');
        }

        let width = '600px';
        if (isMobileOnly) {
            width = '100vh';
        }

        return (
            <Modal
                open={isVisible}
                title="Add a Transaction"
                okText="Save"
                onCancel={this.onClose}
                onOk={this.handleSave}
                okButtonProps={{ disabled: this.state.isSaving, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
                maskClosable={false}
                closable={!this.state.isSaving}
                width={width}
            >
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    initialValues={{
                        forPaymentsIndexes: [],
                        date: defaultTransactionDate,
                        lateFeeWaived: false,
                        status: LoanTransactionStatus.Success,
                        interestDue: currency(interestDue, { precision: 2 }).toString(),
                    }}
                >
                    <Row>
                        <Col span={11}>{this.transactionType}</Col>
                        <Col offset={1} span={12}>{this.forPaymentsSelector}{this.interestDueInput}</Col>
                    </Row>
                    <Row>
                        <Col span={11}>{this.amountInput}</Col>
                        <Col offset={1} span={12}>{this.paymentMethod}</Col>
                    </Row>
                    <Row>
                        <Col span={11}>{this.transactionDate}</Col>
                        <Col offset={1} span={12}>{this.lateFeeWaiveSwitch}</Col>
                        {this.statusSelect}
                        {this.lateWarning}
                        {this.lateFeeBalanceWarning}
                    </Row>
                    <Row>
                        <Col span={24}>{this.commentInput}</Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

import { PlanInterval } from './payment/plan';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { IPhoneNumber } from './common/phoneNumber';
import { IOrganizationAddresses } from './organizationAddresses';
import { IOrganizationLegal } from './organizationLegal';
import { IInvoice } from './billing/invoices';
import { IPaymentMethod } from './billing/paymentMethod';
import { Currency } from './currency';

export interface IOrganization extends IOrganizational, IObjectDates {
    id: string;
    shortId: string;
    name: string;
    phoneNumber: IPhoneNumber;
    email: string;
    addresses: IOrganizationAddresses;
    tosAgreedAt: Date;
    setupComplete: boolean;

    billing: IOrganizationBilling;
    legal: IOrganizationLegal;

    stripeConnect: IOrganizationStripeConnect;

    messaging?: IOrganizationMessaging;
}

export interface IOrganizationBilling {
    subscriptionId: string;
    plan: PlanInterval;
    status: OrganizationBillingStatus;
    periodStartsAt: string;
    periodEndsAt: string;

    latestInvoice: IInvoice;
    paymentMethods: IPaymentMethod[];

    addons: IOrganizationBillingAddons;

    cancelsAtEndOfPeriod: boolean;
    cancelsAt: string;
    canceledAt: string;
}

export enum OrganizationBillingStatus {
    Active = 'active',
    Cancelled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Unpaid = 'unpaid',
    Trialing = 'trialing',
}

export interface IOrganizationBillingAddons {
    customWebsite: IIOrganizationBillingAddonCustomWebsite;
}

export interface IIOrganizationBillingAddonCustomWebsite {
    enabled: boolean;
    dateEnabled: string;
}

export interface IOrganizationStripeConnect {
    accountId: string;
    availableBalance: Currency;
    pendingBalance: Currency;
    detailsSubmitted: boolean;
    chargesEnabled: boolean;
    payoutsEnabled: boolean;
}

export interface IOrganizationMessaging {
    setupComplete: boolean;

    accountId: string;

    brandId: string;
    brandStatus: string;
    campaignId: string;
    campaignStatus: string;
    numberId: string
    number: string;

    purchaseError?: boolean;
}

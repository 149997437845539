import React from 'react';
import { Button, Card, List } from 'antd';
import { FileTextTwoTone, LayoutTwoTone } from '@ant-design/icons';

import { IOrganization } from 'models/organization';

interface IBillingAddonsCardProps {
    org: IOrganization;
}

export const BillingAddonsCard: React.FC<IBillingAddonsCardProps> = (props) => {
    const websiteExtra = (
        <Button disabled>{ props.org.billing?.addons?.customWebsite.enabled ? 'Disable' : 'Enable' }</Button>
    );

    const mailExtra = (
        <Button disabled>Enable</Button>
    );

    return (
        <Card title="Addons (coming soon)" size="small" bordered style={{ marginTop: '45px' }}>
            <List>
                <List.Item
                    extra={websiteExtra}
                >
                    <List.Item.Meta
                        avatar={<LayoutTwoTone twoToneColor="#52c41a" style={{ fontSize: '2em' }} />}
                        title="Custom Website"
                        description="The custom website addon enables you to build a website via lendiom, with automatic content updates of the inventory."
                        style={{ paddingRight: '16px' }}
                    />
                </List.Item>

                <List.Item
                    extra={mailExtra}
                >
                    <List.Item.Meta
                        avatar={<FileTextTwoTone twoToneColor="#52c41a" style={{ fontSize: '2em' }} />}
                        title="Mail Editor"
                        description="A mail/letter creation inside of Lendiom which allows using and inserting data from Lendiom."
                        style={{ paddingRight: '16px' }}
                    />
                </List.Item>
            </List>
        </Card>
    );
}

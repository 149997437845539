import React from 'react';
import { Link } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { Card, Table, Empty } from 'antd';
import type { CardTabListType } from 'antd/lib/card';
import type { ColumnProps } from 'antd/lib/table';

import { AccessControlled } from 'components/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { LoanStatusTag } from 'components/loans/loanStatusTag';
import { TractStatusTag } from 'components/tracts/tractStatusTag';

import { IClient } from 'models/client';
import { IRelatedTo, RelatedToType } from 'models/common/relatedTo';

import { GlobalState } from 'store';
import { getLoansForSelectedOrg } from 'store/selectors/loans';
import { fetchRelatedLoans } from 'store/actions/loans';
import { fetchRelatedTracts } from 'store/actions/tracts';
import { getAllTracts } from 'store/selectors/tracts';

const mapStateToProps = (state: GlobalState) => ({
    loans: getLoansForSelectedOrg(state),
    tracts: getAllTracts(state),
});

enum ClientActiveItemsTabKeys {
    Tracts = 'tract',
    Loans = 'loan',
    ResidentialProperty = 'residential',
}

interface IClientTractsAndLoansProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    orgId: string;
    client: IClient;
}

interface IClientTractsAndLoansState {
    selectedTab: ClientActiveItemsTabKeys;
    isLoading: boolean;
};

class ClientTractsAndLoansBase extends React.PureComponent<IClientTractsAndLoansProps, IClientTractsAndLoansState> {
    state: Readonly<IClientTractsAndLoansState> = {
        selectedTab: ClientActiveItemsTabKeys.Loans,
        isLoading: true,
    }

    getAssociatedTracts = () => {
        return this.props.client.associated ? this.props.client.associated.filter((r) => r.type === RelatedToType.TRACT) : [];
    }

    getAssociatedLoans = () => {
        return this.props.client.associated ? this.props.client.associated.filter((r) => r.type === RelatedToType.LOAN) : [];
    }

    getAssociatedResidential = () => {
        return this.props.client.associated ? this.props.client.associated.filter((r) => r.type === RelatedToType.INVENTORY) : [];
    }

    tabList: CardTabListType[] = [
        { key: ClientActiveItemsTabKeys.Loans, tab: `Loans${ this.getAssociatedLoans().length !== 0 ? ' (' + this.getAssociatedLoans().length + ')' : ''}` },
        { key: ClientActiveItemsTabKeys.Tracts, tab: `Tracts${ this.getAssociatedTracts().length !== 0 ? ' (' + this.getAssociatedTracts().length + ')' : ''}` },
        { key: ClientActiveItemsTabKeys.ResidentialProperty, tab: `Residential${ this.getAssociatedResidential().length !== 0 ? ' (' + this.getAssociatedResidential().length + ')' : ''}` },
    ];

    columns: ColumnProps<IRelatedTo>[] = [
        {
            title: 'Label', key: 'label', dataIndex: 'label',
            render: (label: string, record: IRelatedTo) => {
                let la = label;
                let tag: React.ReactNode = null;

                if (record.type === RelatedToType.LOAN) {
                    const loan = this.props.loans.find((l) => l.id === record.id);

                    if (loan) {
                        la = loan.label;
                        tag = <LoanStatusTag status={loan.status} />
                    }
                } else if (record.type === RelatedToType.TRACT) {
                    const tract = this.props.tracts.find((t) => t.id === record.id);

                    if (tract) {
                        la = tract.label;
                        tag = <TractStatusTag status={tract.status} />
                    }
                }

                return (
                    <React.Fragment>
                        { la } { tag }
                    </React.Fragment>
                );
            }
        },
        {
            title: 'Type', key: 'type', dataIndex: 'type', className: 'title-caps',
        },
        {
            title: '', key: 'link',
            render: (nothing: any, record: IRelatedTo) => {
                const orgId = this.props.client.organization.shortId!;

                switch (record.type) {
                    case RelatedToType.TRACT:
                        return (
                            <AccessControlled feature={PermissionFeature.Tract} action={PermissionAction.Read}>
                                <Link to={`/${ orgId }/inventories/${ record.parentId }/tracts/${ record.id }`}>View</Link>
                            </AccessControlled>
                        );
                    case RelatedToType.INVENTORY:
                        return (
                            <AccessControlled feature={PermissionFeature.Inventory} action={PermissionAction.Read}>
                                <Link to={`/${ orgId }/inventories/${ record.id }`}>View</Link>
                            </AccessControlled>
                        );
                    case RelatedToType.LOAN:
                        return (
                            <AccessControlled feature={PermissionFeature.Loan} action={PermissionAction.Read}>
                                <Link to={`/${ orgId }/loans/${ record.id }`}>View</Link>
                            </AccessControlled>
                        );
                    default:
                        return null;
                }
            }
        },
    ]

    async componentDidMount() {
        if (!this.props.client.associated) {
            //TODO: ensure this property is created on the server side when a client is created
            this.setState({ isLoading: false });
            return;
        }

        const tracts = this.props.client.associated.filter((a) => a.type === RelatedToType.TRACT);
        const loans = this.props.client.associated.filter((a) => a.type === RelatedToType.LOAN);

        await this.props.dispatch(fetchRelatedTracts(tracts) as any);
        await this.props.dispatch(fetchRelatedLoans(loans) as any);

        //TODO: whenever the component updates and the `client.associated` exists, then and only then fetch the required related items instead of everything

        this.setState({ isLoading: false });
    }

    onTabSelection = (key: string) => {
        this.setState({ selectedTab: key as ClientActiveItemsTabKeys });
    }

    get tractsTab() {
        const relatedTracts = this.getAssociatedTracts();

        return (
            <Table<IRelatedTo>
                columns={this.columns}
                dataSource={relatedTracts}
                rowKey="id"
                pagination={{ hideOnSinglePage: true }}
                locale={{
                    emptyText: <Empty description={`${ this.props.client.displayName } has no tracts associated yet.`} />
                }}
            />
        );
    }

    get loansTab() {
        const relatedLoans = this.getAssociatedLoans();

        return (
            <Table<IRelatedTo>
                columns={this.columns}
                dataSource={relatedLoans}
                rowKey="id"
                pagination={{ hideOnSinglePage: true }}
                locale={{
                    emptyText: <Empty description={`${ this.props.client.displayName } has no loans yet.`} />
                }}
            />
        );
    }

    get residentialTab() {
        const relatedResidential = this.getAssociatedResidential();

        return (
            <Table<IRelatedTo>
                columns={this.columns}
                dataSource={relatedResidential}
                rowKey="id"
                pagination={{ hideOnSinglePage: true }}
                locale={{
                    emptyText: <Empty description={`${ this.props.client.displayName } has no residential properties yet.`} />
                }}
            />
        );
    }

    get selectedTab() {
        switch (this.state.selectedTab) {
            case ClientActiveItemsTabKeys.Loans:
                return this.loansTab;
            case ClientActiveItemsTabKeys.Tracts:
                return this.tractsTab;
            case ClientActiveItemsTabKeys.ResidentialProperty:
                return this.residentialTab;
            default:
                return null;
        }
    }

    render() {
        return (
            <Card
                bordered={false}
                tabList={this.tabList}
                onTabChange={this.onTabSelection}
                activeTabKey={this.state.selectedTab}
                className="active-items-tabs"
                loading={this.state.isLoading}
            >
                {this.selectedTab}
            </Card>
        );
    }
}

export const ClientTractsAndLoans = connect(mapStateToProps)(ClientTractsAndLoansBase);

import currency from 'currency.js';
import type { Dayjs } from 'dayjs';

import { Currency } from 'models/currency';
import { ILoanTerms, LoanTermsInterestFormula, LoanTermsInterestSchedule } from 'models/loan';

const currency100 = currency(100);
const currency12 = currency(12);
const precision = Object.freeze({ precision: 14, errorOnInvalid: true });

/**
 *
 * @param interestRatePerMonth the interest rate per month. .1/12 = 10% interest rate for a 12 month period
 * @param numberOfPeriods the amount of periods this covers
 * @param presentValue the value at the present time
 * @param futureValue (optional) the value in the future
 * @param whenPaymentsAreDue (optional) when the payments are due
 */
export function periodicPaymentAmount(interestRatePerMonth: string, numberOfPeriods: currency, presentValue: currency, futureValue = currency(0), whenPaymentsAreDue?: 'end' | 'start'): currency {
    // credit: https://stackoverflow.com/a/22385930
    if (currency(interestRatePerMonth).intValue === 0) {
        return presentValue.add(futureValue).multiply(-1).divide(numberOfPeriods);
    }

    const pvif = toThePowerOf(currency(interestRatePerMonth, precision).add(1), numberOfPeriods.toJSON()).toString();
    let pmt = currency(interestRatePerMonth, precision).multiply(-1).multiply(presentValue).multiply(currency(pvif, precision).add(futureValue)).divide(currency(pvif, precision).subtract(1));

    if (whenPaymentsAreDue === 'start')
        pmt = pmt.divide(currency(interestRatePerMonth).add(1));

    return pmt;
}

export function calcPaymentAmountFor(interestRate: currency, years: currency, salesPrice: currency, downPayment: currency): currency {
    return periodicPaymentAmount(
        currency(interestRate, precision).divide(currency100).divide(currency12).toString(),
        currency(years).multiply(currency12),
        currency(salesPrice).subtract(downPayment),
    ).multiply(-1);
}

export function calcPaymentAmountFromDetails(interestRate: currency, years: currency, acres: currency, pricePerAcre: currency, downPayment: currency): currency {
    return periodicPaymentAmount(
        currency(interestRate, precision).divide(currency100).divide(currency12).toString(),
        currency(years).multiply(currency12),
        currency(acres).multiply(pricePerAcre).subtract(downPayment),
    ).multiply(-1);
}

function toThePowerOf(base: currency, exponent: number): currency {
    let result = currency(base, precision);

    for (let count = 1; count < exponent; count++) {
        result = result.multiply(base);
    }

    return result;
}

export function calculateAccruedInterestSinceLastPayment(runDate: Dayjs, lastPaymentDate: Dayjs, firstPaymentDate: Dayjs, terms: ILoanTerms, principalBal: Currency): currency {
    //if you update this file, please update the server's loanAmortization.go
    const dailyInterestRate = calculateDailyInterestRateFor(terms);
    let daysSinceLastPayment = 0;

    if (lastPaymentDate.year() === 0) {
        switch (terms.interestFormula) {
            case LoanTermsInterestFormula.ThirtyDaysBy360:
                daysSinceLastPayment = 30;
                break;
            case LoanTermsInterestFormula.ActualBy360:
            case LoanTermsInterestFormula.ActualBy365:
                daysSinceLastPayment = firstPaymentDate.daysInMonth();
                break;
            default:
                throw new Error(`invalid interest formula: ${terms.interestFormula}`);
        }

        const nextPaymentDate = firstPaymentDate.add(1, 'month');
		if (nextPaymentDate.isBefore(runDate)) {
            const daysSinceNextPayment = runDate.diff(nextPaymentDate, 'days')+1;
			daysSinceLastPayment += daysSinceNextPayment;
		}
    } else {
        daysSinceLastPayment = runDate.diff(lastPaymentDate, 'days');
    }

    if (daysSinceLastPayment <= 0) {
        return currency(0, { precision: 2 });
    }

    return currency(principalBal, precision).multiply(dailyInterestRate).multiply(daysSinceLastPayment);
}

export function calculateDailyInterestRateFor(terms: ILoanTerms): currency {
    let rate = currency(terms.rate, precision).divide(currency100);

    if (terms.interestSchedule === LoanTermsInterestSchedule.FollowsPayments) {
        return rate.divide(365);
    }

    switch (terms.interestFormula) {
        case LoanTermsInterestFormula.ThirtyDaysBy360:
        case LoanTermsInterestFormula.ActualBy360:
            rate = rate.divide(360);
            break;
        case LoanTermsInterestFormula.ActualBy365:
            rate = rate.divide(365);
            break;
        default:
            throw new Error(`invalid interest formula: ${terms.interestFormula}`);
    }

    return rate
}

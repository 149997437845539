import React from 'react';
import { NavLink } from 'react-router-dom';
import { AuditOutlined, CloudDownloadOutlined, CommentOutlined, CreditCardOutlined, DashboardOutlined, FieldTimeOutlined, FileMarkdownOutlined, FilePdfOutlined, FileTextOutlined, FileZipOutlined, FormatPainterOutlined, InfoCircleOutlined, LayoutOutlined, LockOutlined, PieChartOutlined, SettingOutlined, SnippetsOutlined, TeamOutlined, WalletOutlined } from '@ant-design/icons';

import { MenuDataItem } from '@ant-design/pro-layout';

export function menuDataRender(orgId: string): () => MenuDataItem[] {
    return (): MenuDataItem[] => {
        return [
            {
                path: `/${ orgId }/dashboard`,
                name: 'Dashboard',
                icon: <DashboardOutlined />,
            },
            {
                path: `/${ orgId }/communications`,
                name: 'Communication',
                icon: <CommentOutlined />,
            },
            {
                path: `/${ orgId }/inventories`,
                name: 'Inventory',
                icon: <SnippetsOutlined />,
            },
            {
                path: `/${ orgId }/clients`,
                name: 'Clients',
                icon: <TeamOutlined />,
            },
            {
                path: `/${ orgId }/loans`,
                name: 'Loans',
                icon: <AuditOutlined />,
            },
            {
                path: `/${ orgId }/rentals`,
                name: 'Rentals',
                icon: <FieldTimeOutlined />,
            },
            {
                path: `/${ orgId }/customize`,
                name: 'Customize',
                icon: <FormatPainterOutlined />,
                children: [
                    {
                        path: `/${ orgId }/customize/website`,
                        name: 'Website',
                        icon: <LayoutOutlined />,
                    },
                    {
                        path: `/${ orgId }/customize/mail`,
                        name: 'Mail',
                        icon: <FileTextOutlined />,
                    },
                ],
            },
            {
                path: `/${ orgId }/reports`,
                name: 'Reports',
                icon: <PieChartOutlined />,
            },
            {
                path: `/${ orgId }/files`,
                name: 'Files',
                icon: <FileZipOutlined />,
            },
            {
                path: `/${ orgId }/settings`,
                name: 'Org Settings',
                icon: <SettingOutlined />,
                children: [
                    {
                        path: `/${ orgId }/settings/basic`,
                        name: 'Basic',
                        icon: <InfoCircleOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/billing`,
                        name: 'Billing',
                        icon: <WalletOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/stripe`,
                        name: 'Stripe',
                        icon: <CreditCardOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/roles`,
                        name: 'Roles',
                        icon: <LockOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/members`,
                        name: 'Members',
                        icon: <TeamOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/invoices`,
                        name: 'Invoices',
                        icon: <FilePdfOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/templates`,
                        name: 'Templates',
                        icon: <LayoutOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/filleable-pdfs`,
                        name: 'Fillable PDFs',
                        icon: <FileMarkdownOutlined />,
                    },
                    {
                        path: `/${ orgId }/settings/export`,
                        name: 'Backups/Exports',
                        icon: <CloudDownloadOutlined />,
                    },
                ],
            },
        ]
    }
}

export function menuItemRender(item: MenuDataItem, dom: JSX.Element): React.ReactNode {
    return (
        <NavLink to={item.path!}>
            { dom }
        </NavLink>
    );
}

import React from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { getSelectedOrgLongId, getSelectedOrgShortId } from 'store/selectors/org';

import { ExpectedPaymentsCard } from './overview/expectedPayments';
import { RecentTransactionsCard } from './overview/recentTransactions';
import { StorageUsedCard } from './overview/storageUsed';
import { UpcomingPaymentsCard } from './overview/upcomingPayments';

export const DashboardOverviewTab: FC = () => {
    const orgId = useSelector(getSelectedOrgLongId);
    const shortOrgId = useSelector(getSelectedOrgShortId);

    return (
        <Row gutter={[24, 24]}>
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <ExpectedPaymentsCard orgId={orgId} orgShortId={shortOrgId} />
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <UpcomingPaymentsCard orgId={orgId} orgShortId={shortOrgId} />
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <RecentTransactionsCard orgId={orgId} orgShortId={shortOrgId} />
            </Col>
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <StorageUsedCard orgId={orgId} />
            </Col>
        </Row>
    );
}

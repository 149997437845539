import React, { lazy, Suspense } from 'react';
import currency from 'currency.js';
import type { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Row, Col, FormInstance, Button, Collapse, Spin, Descriptions, Alert } from 'antd';

import { IClientAutoCompleteValue } from 'components/clients/autocomplete';
import { LongCurrency, SimpleDate } from 'utils/formatting';

import type { IInventory } from 'models/inventory';
import type { ITract } from 'models/tract';
import { ILoan, LoanLateFeeApplication, LoanLateFeeChargeType, LoanPaymentFrequency, LoanTermsInterestAccruesDailyFirstPaymentBasis, LoanTermsInterestSchedule, LoanType } from 'models/loan';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { generateAmortizationSchedule } from 'api/helpers';

import { INewLoanFormValues } from './formValues';
import { displayErrorNotification } from 'utils/errors';
import { ILoanSchedule } from 'models/loanSchedule';

const LoanScheduleTable = lazy(() => import('components/loans/scheduleTable'));

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    orgId: getSelectedOrgShortId(state),
});

interface INewLoanReviewStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    resInv?: IInventory;
    landInv?: IInventory;
    tracts?: ITract[];
    goNext: () => void;
    goBack: () => void;
    isSaving: boolean;
}

interface INewLoanFormReviewStepState {
    inventory?: IInventory;
    loan: Partial<ILoan>;
    schedule?: ILoanSchedule;
    loadingError: boolean;
}

class NewLoanFormReviewStepBase extends React.PureComponent<INewLoanReviewStepProps, INewLoanFormReviewStepState> {
    state: Readonly<INewLoanFormReviewStepState> = {
        loan: {},
        loadingError: false,
    }

    async componentDidMount() {
        const loanType = this.props.form.getFieldValue('type');

        switch (loanType) {
            case LoanType.Tract:
                this.setState({ inventory: this.props.landInv });
                break;
            case LoanType.Residential:
                this.setState({ inventory: this.props.resInv });
                break;
        }

        const loan: Partial<ILoan> = {
            type: loanType,
            status: this.props.form.getFieldValue('status'),
            terms: {
                downPayment: this.props.form.getFieldValue('downPayment'),
                salesPrice: this.props.form.getFieldValue('salesPrice'),
                length: this.props.form.getFieldValue('length'),
                lengthUnit: this.props.form.getFieldValue('lengthUnit'),
                rate: this.props.form.getFieldValue('interestRate'),
                interestSchedule: this.props.form.getFieldValue('interestSchedule'),
                interestFormula: this.props.form.getFieldValue('interestFormula'),
                paymentFrequency: LoanPaymentFrequency.Monthly,
                firstPaymentBasis: this.props.form.getFieldValue('firstPaymentBasis'),
                extraApplication: this.props.form.getFieldValue('extraApplication'),
                wasExisting: this.props.form.getFieldValue('isExistingLoan') || false,
                existing: {
                    principalPaid: this.props.form.getFieldValue('principalPaid') || '',
                    interestPaid: this.props.form.getFieldValue('interestPaid') || '',
                    interestPaidYtd: this.props.form.getFieldValue('interestPaidYtd') || '',
                    unpaidInterest: this.props.form.getFieldValue('interestBalanceUnpaid') || '',
                    lastPaidInFullPayment: this.props.form.getFieldValue('lastPaidInFullPayment') || 0,
                    lastPaymentDate: this.props.form.getFieldValue('lastPaymentDate') ? this.props.form.getFieldValue('lastPaymentDate').toJSON() : '',
                    nextPaymentDate: this.props.form.getFieldValue('nextPaymentDate') ? this.props.form.getFieldValue('nextPaymentDate').toJSON() : '',
                },
            },
            firstPaymentDate: this.props.form.getFieldValue('firstPaymentDate').toJSON(),
            closingDate: this.props.form.getFieldValue('closingDate').toJSON(),
            lateFeeConfig: {
                disabled: this.props.form.getFieldValue('lateFeeDisabled') || false,
                application: this.props.form.getFieldValue('lateFeeApplication'),
                chargeType: this.props.form.getFieldValue('lateFeeChargeType'),
                daysUntilLate: this.props.form.getFieldValue('daysUntilLate'),
                fixed: this.props.form.getFieldValue('lateFeeFixedAmount') || '0',
                percentage: this.props.form.getFieldValue('lateFeePercentage') || '0',
                minimumAmount: this.props.form.getFieldValue('minimumLateFeeAmount') || '10',
                maximumAmount: this.props.form.getFieldValue('maximumLateFeeAmount') || '50',
            },
            communication: {
                automated: this.props.form.getFieldValue('automatedCommunication'),
                preference: this.props.form.getFieldValue('communicationPreference'),
            },
            onlinePaymentConfig: {
                enabled: this.props.form.getFieldValue('onlinePaymentsEnabled'),
                statementDescriptor: this.props.form.getFieldValue('onlineStatementDescriptor'),
                platformFeePayee: this.props.form.getFieldValue('onlinePaymentPlatformFeePayee'),
                allowPrincipalOnly: this.props.form.getFieldValue('allowPrincipalOnly'),
                allowAutoDraft: this.props.form.getFieldValue('allowAutoDraft'),
            },
        };

        if (loanType === LoanType.Residential) {
            loan.terms!.adjustments = this.props.form.getFieldValue('totalAdjustments') || '0';

            loan.escrow = {
                balance: this.props.form.getFieldValue('escrowStartingBalance') || '',
                paymentAmount: this.props.form.getFieldValue('escrowPaymentAmount') || '',
                applicationStep: this.props.form.getFieldValue('escrowApplicationStep'),
            };
        }

        if (loan.terms!.interestSchedule === LoanTermsInterestSchedule.FollowsPayments) {
            try {
                const schedule = await generateAmortizationSchedule(loan.terms!, loan.firstPaymentDate!, loan.escrow?.paymentAmount || undefined);

                this.setState({ schedule });
            } catch (e) {
                console.warn('failed to get the amortization schedule:', e);
                displayErrorNotification(e);
                this.setState({ loadingError: true });
            }
        }

        this.setState({ loan });
    }

    get tractDescriptionItem() {
        if (!this.state.inventory || !this.props.tracts || this.props.tracts.length === 0) {
            return null;
        }

        const tractLinks = this.props.tracts.map((t, i, tracts) => (
            <span key={t.id}>
                <Link to={`/${this.props.orgId}/inventories/${t.inventoryId}/tracts/${t.id}`} target="_blank" title={`${ t.label }. Opens in a new tab`}>{t.number}</Link>
                { i+1 === tracts.length ? '' : ',' }
                &nbsp;
            </span>
        ));

        return (
            <Descriptions.Item label={this.props.tracts.length === 1 ? 'Tract' : 'Tracts'}>Tracts&nbsp;{tractLinks}</Descriptions.Item>
        );
    }

    get details() {
        const { loan, schedule } = this.state;
        if (!loan || !loan.terms || !loan.lateFeeConfig || !loan.communication) {
            return (
                <Spin spinning />
            );
        }

        const isAccruesDaily = loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily;
        const salesPrice = currency(loan.terms.salesPrice, { precision: 2 });
        const downPayment = currency(loan.terms.downPayment, { precision: 2 });
        const adjustments = currency(loan.terms.adjustments || 0, { precision: 2 });
        const interestRate = currency(loan.terms.rate, { precision: 2 });
        const length = currency(loan.terms.length, { precision: 0 });
        const lengthUnit = loan.terms.lengthUnit;

        let paymentAmount = this.props.form.getFieldValue('paymentAmount');
        if (schedule && schedule.payments && schedule.payments.length > 0 && !isAccruesDaily) {
            paymentAmount = schedule.payments[0].payment;
        }

        let downPaymentDate: Dayjs | undefined = undefined;
        if (isAccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate) {
            downPaymentDate = this.props.form.getFieldValue('downPaymentDate') as Dayjs;
        }

        const closingDate = this.props.form.getFieldValue('closingDate') as Dayjs;
        const firstPaymentDate = this.props.form.getFieldValue('firstPaymentDate') as Dayjs;
        const lastPaymentDate = this.props.form.getFieldValue('lastPaymentDate') as Dayjs;
        const nextPaymentDate = this.props.form.getFieldValue('nextPaymentDate') as Dayjs;
        const client = this.props.form.getFieldValue('client') as IClientAutoCompleteValue;
        const label = this.props.form.getFieldValue('label') as string;

        const minLateFee = currency(loan.lateFeeConfig.minimumAmount, { precision: 2 });
        const maxLateFee = currency(loan.lateFeeConfig.maximumAmount, { precision: 2 });

        let lateFeeApplication = 'First of Next';
        switch (loan.lateFeeConfig.application) {
            case LoanLateFeeApplication.Principal:
                lateFeeApplication = 'Principal';
                break;
            case LoanLateFeeApplication.Balance:
                lateFeeApplication = 'Late Fee Balance';
                break;
        }

        return (
            <Descriptions>
                <Descriptions.Item label="Label" span={2}>{label}</Descriptions.Item>
                <Descriptions.Item label="Type" className="title-caps" span={1}>{loan.type ? loan.type.replaceAll('-', ' ') : '-'}</Descriptions.Item>
                <Descriptions.Item label="Status" className="title-caps">{loan.status ? loan.status : '-'}</Descriptions.Item>
                {this.state.inventory ? <Descriptions.Item label="Inventory"><Link to={`/${this.props.orgId}/inventories/${this.state.inventory.id}`} target="_blank" title="Opens in a new tab">{this.state.inventory.name}</Link></Descriptions.Item> : null}
                {this.state.inventory && Array.isArray(this.props.tracts) ? this.tractDescriptionItem : null}
                <Descriptions.Item label="Client"><Link to={`/${this.props.orgId}/clients/${client.id}`} target="_blank" title="Opens in a new tab">{client.displayName}</Link></Descriptions.Item>
                <Descriptions.Item label="Communication" className="title-caps">{loan.communication.preference} ({loan.communication.automated ? 'automatic' : 'manual'})</Descriptions.Item>
                <Descriptions.Item label="Sales Price">{salesPrice.format(true)}</Descriptions.Item>
                <Descriptions.Item label="Down Payment">{downPayment.format(true)}</Descriptions.Item>
                { loan.terms.adjustments ? <Descriptions.Item label="Total Adjustments"><LongCurrency value={loan.terms.adjustments} /></Descriptions.Item> : null }
                <Descriptions.Item label="Loan Amount">{ currency(salesPrice).subtract(downPayment).add(adjustments).format(true) }</Descriptions.Item>
                <Descriptions.Item label="Payment Frequency">Monthly</Descriptions.Item>
                <Descriptions.Item label="Payment Terms">{length.format()} {lengthUnit}</Descriptions.Item>
                <Descriptions.Item label="Payment Amount"><LongCurrency value={paymentAmount} /></Descriptions.Item>
                <Descriptions.Item label="Extra Application" className="title-caps">{loan.terms.extraApplication ? loan.terms.extraApplication.replaceAll('-', ' ') : '-'}</Descriptions.Item>
                <Descriptions.Item label="Interest Rate">{interestRate.format()}%</Descriptions.Item>
                <Descriptions.Item label="Interest Schedule" className="title-caps">{loan.terms.interestSchedule.replaceAll('-', ' ')}</Descriptions.Item>
                { isAccruesDaily ? <Descriptions.Item label="Interest Formula" className="title-caps">{ loan.terms.interestFormula ? loan.terms.interestFormula.replaceAll('/', ' / ') : '-' }</Descriptions.Item> : null}
                { isAccruesDaily ? <Descriptions.Item label="First Payment Basis" className="title-caps">{ loan.terms.firstPaymentBasis ? loan.terms.firstPaymentBasis.replaceAll('-', ' ') : '-' }</Descriptions.Item> : null }
                { isAccruesDaily && loan.terms.firstPaymentBasis === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate ? <Descriptions.Item label="Down Payment Date"><SimpleDate date={downPaymentDate?.toISOString()} /></Descriptions.Item> : null }
                { !isAccruesDaily ? <Descriptions.Item label="Total of Payments">{schedule ? currency(schedule.totalPayments).format(true) : '-'}</Descriptions.Item> : null}
                { !isAccruesDaily ? <Descriptions.Item label="Total Interest">{schedule ? currency(schedule.totalInterest).format(true) : '-'}</Descriptions.Item> : null}
                <Descriptions.Item label="Closing Date"><SimpleDate date={closingDate.toISOString()} /></Descriptions.Item>
                <Descriptions.Item label="First Payment Date"><SimpleDate date={firstPaymentDate.toISOString()} /></Descriptions.Item>
                <Descriptions.Item label="Days Until Late">{loan.lateFeeConfig.daysUntilLate}</Descriptions.Item>
                <Descriptions.Item label="Late Fee Application">{lateFeeApplication}</Descriptions.Item>
                <Descriptions.Item label="Late Fee Charge Type">{loan.lateFeeConfig.chargeType === LoanLateFeeChargeType.Fixed ? 'Fixed Amount' : 'Percentage'}</Descriptions.Item>
                {loan.lateFeeConfig.chargeType === LoanLateFeeChargeType.Fixed ? <Descriptions.Item label="Late Fee Fixed">{currency(loan.lateFeeConfig.fixed!).format(true)}</Descriptions.Item> : null}
                {loan.lateFeeConfig.chargeType === LoanLateFeeChargeType.Percent ? <Descriptions.Item label="Late Fee Percentage">{currency(loan.lateFeeConfig.percentage!).format()}%</Descriptions.Item> : null}
                {loan.lateFeeConfig.chargeType === LoanLateFeeChargeType.Percent ? <Descriptions.Item label="Min Late Fee">{minLateFee.format(true)}</Descriptions.Item> : null}
                {loan.lateFeeConfig.chargeType === LoanLateFeeChargeType.Percent ? <Descriptions.Item label="Max Late Fee">{maxLateFee.format(true)}</Descriptions.Item> : null}
                <Descriptions.Item label="Online Payments">{loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? 'Enabled' : 'Disabled'}</Descriptions.Item>
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Statement Descriptor">{loan.onlinePaymentConfig.statementDescriptor.toUpperCase()}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Platform Fee Payee" className="title-caps">{loan.onlinePaymentConfig.platformFeePayee.replaceAll('-', ' ')}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Allow Principal Only" className="title-caps">{loan.onlinePaymentConfig.allowPrincipalOnly ? 'Yes' : 'No'}</Descriptions.Item> : null}
                {loan.onlinePaymentConfig && loan.onlinePaymentConfig.enabled ? <Descriptions.Item label="Allow Auto Draft" className="title-caps">{loan.onlinePaymentConfig.allowAutoDraft ? 'Yes' : 'No'}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Existing Loan">Yes</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Principal Paid">{currency(loan.terms.existing.principalPaid).format(true)}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Interest Paid">{currency(loan.terms.existing.interestPaid).format(true)}</Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Interest Paid YTD"><LongCurrency value={loan.terms.existing.interestPaidYtd} /></Descriptions.Item> : null}
                {loan.terms.wasExisting && isAccruesDaily ? <Descriptions.Item label="Unpaid Interest"><LongCurrency value={loan.terms.existing.unpaidInterest} /></Descriptions.Item> : null}
                {loan.terms.wasExisting && !isAccruesDaily ? <Descriptions.Item label="Last Paid In Full Payment">{loan.terms.existing.lastPaidInFullPayment}</Descriptions.Item> : null}
                {loan.terms.wasExisting && isAccruesDaily ? <Descriptions.Item label="Last Payment Date"><SimpleDate date={lastPaymentDate.toISOString()} /></Descriptions.Item> : null}
                {loan.terms.wasExisting ? <Descriptions.Item label="Next Payment Date"><SimpleDate date={nextPaymentDate.toISOString()} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Starting Balance"><LongCurrency value={loan.escrow.balance} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Payment Amount"><LongCurrency value={loan.escrow.paymentAmount} /></Descriptions.Item> : null}
                {loan.escrow ? <Descriptions.Item label="Escrow Application Step" className="title-caps">{loan.escrow.applicationStep.replace('-', ' ')}</Descriptions.Item> : null}
            </Descriptions>
        );
    }

    get amortizationSchedule() {
        if (this.state.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            return (
                <Alert
                    type="info"
                    showIcon
                    message="Interest Accruing Daily"
                    description="When interest accrues daily, generating an amortization schedule requires making several assumptions. For example, it would have to assume the buyer pays on the due date. As such, we are unable to generate an amortization schedule at this moment. We will continue to research how this can be a feature in the future."
                    style={{ marginBottom: '16px' }}
                />
            );
        }

        return (
            <Spin spinning={typeof this.state.schedule === 'undefined'}>
                <Collapse defaultActiveKey={[]} collapsible={this.state.loadingError ? 'disabled' : 'header'}>
                    <Collapse.Panel key="collapse-schedule" header="Amortization Schedule">
                        <Suspense fallback={null}>
                            <LoanScheduleTable interestSchedule={this.state.loan.terms?.interestSchedule || LoanTermsInterestSchedule.FollowsPayments} schedule={this.state.schedule!} showDate showEscrow={typeof this.state.loan?.escrow !== 'undefined'} />
                        </Suspense>
                    </Collapse.Panel>
                </Collapse>
            </Spin>
        );
    }

    get bottomButtons() {
        return (
            <Row style={{ marginTop: '10px' }}>
                <Col>
                    <Button.Group>
                        <Button icon={<LeftOutlined />} onClick={this.props.goBack} disabled={this.props.isSaving}>Previous</Button>
                        <Button type="primary" onClick={this.props.goNext} disabled={this.props.isSaving || this.state.loadingError} loading={this.props.isSaving}>
                            Save <SaveOutlined />
                        </Button>
                    </Button.Group>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.details}
                {this.amortizationSchedule}

                {this.bottomButtons}
            </React.Fragment>
        );
    }
}

export const NewLoanReviewStep = connect(mapStateToProps)(NewLoanFormReviewStepBase);

import { createStore, applyMiddleware, compose } from 'redux';
import { useDispatch } from 'react-redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import { reducers } from './reducers';

const logger = createLogger({ collapsed: true });
const persistConfig = { key: 'LendiomStore', blacklist: ['projects', 'inventories', 'tracks', 'tracts', 'clients', 'loans', 'permission'], storage };
const persistReducers = persistCombineReducers(persistConfig, { ...reducers });

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistReducers, composeEnhancers(applyMiddleware(thunk, logger)));
export const persistor = persistStore(store);
export type GlobalState = ReturnType<typeof persistReducers>;
export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

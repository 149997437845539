import React from 'react';
import dayjs from 'dayjs';
import { TableOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Descriptions, Button } from 'antd';

import { ILoan, ILoanTransaction, LoanTermsInterestSchedule, LoanTransactionStatus } from 'models/loan';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { trackUmami } from 'utils/umami';

import { TransactionToTable } from './transactionTableExpandedRow';

interface IViewTransactionDetailsModalProps {
    loan: Partial<ILoan>;
    transaction?: ILoanTransaction;
    isVisible: boolean;
    close: () => void;
}

interface IViewTransactionDetailsModalState { }

export class ViewTransactionDetailsModal extends React.PureComponent<IViewTransactionDetailsModalProps, IViewTransactionDetailsModalState> {
    state: Readonly<IViewTransactionDetailsModalState> = {};

    get transactionDetails() {
        const { loan, transaction } = this.props;

        if (!loan || !transaction) {
            return null;
        }

        const statusChangedAt = dayjs(transaction.statusChangedAt);
        trackUmami('View Loan Transaction Details');

        return (
            <Row>
                <Col span={24}>
                    <Descriptions column={2}>
                        <Descriptions.Item label="Date"><SimpleDate date={transaction.date} tooltip /></Descriptions.Item>
                        <Descriptions.Item label="Total Collected"><LongCurrency value={transaction.totalAmount} tooltip /></Descriptions.Item>
                        { transaction.paidViaStripe && transaction.stripeInvoiceId ? <Descriptions.Item label="Total"><LongCurrency value={transaction.amount} tooltip /></Descriptions.Item> : null }
                        { loan.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="To Unpaid Interest"><LongCurrency value={transaction.totalToUnpaidInterest} tooltip /></Descriptions.Item> : null }
                        <Descriptions.Item label="To Interest"><LongCurrency value={transaction.totalToInterest} tooltip /></Descriptions.Item>
                        <Descriptions.Item label="To Principal"><LongCurrency value={transaction.totalToPrincipal} tooltip /></Descriptions.Item>
                        { transaction.totalToEscrow && transaction.totalToEscrow !== '0' ? <Descriptions.Item label="To Escrow"><LongCurrency value={transaction.totalToEscrow} tooltip /></Descriptions.Item> : null }
                        <Descriptions.Item label="To Fees"><LongCurrency value={transaction.totalToFees} tooltip /></Descriptions.Item>
                        { transaction.paidViaStripe && transaction.stripeInvoiceId ? <Descriptions.Item label="To Platform"><LongCurrency value={transaction.totalToPlatform} tooltip /></Descriptions.Item> : null }
                        <Descriptions.Item label="Type" className="title-caps">{ transaction.type.replace('-', ' ') }</Descriptions.Item>
                        { transaction.method ? <Descriptions.Item label="Method" className="title-caps">{ transaction.method.replace('-', ' ') }</Descriptions.Item> : null }
                        { transaction.status ? <Descriptions.Item label="Status" className="title-caps">{ transaction.status.replace('-', ' ') }{ transaction.stripeRefundId ? ' (Refunded)' : null }</Descriptions.Item> : null }
                        { transaction.statusChangedAt && statusChangedAt.year() !== 0 ? <Descriptions.Item label="Status Date"><SimpleDate date={transaction.statusChangedAt} tooltip /></Descriptions.Item> : null }
                        { transaction.status === LoanTransactionStatus.Reversed || transaction.status === LoanTransactionStatus.Failure ? <Descriptions.Item label="Reversal Date"><SimpleDate date={transaction.reversedAt} tooltip /></Descriptions.Item> : null }
                        { transaction.status === LoanTransactionStatus.Reversed || transaction.status === LoanTransactionStatus.Failure ? <Descriptions.Item label="Reversal Reason" className="title-caps" span={2}>{ transaction.reversalReason.replace('-', ' ') }</Descriptions.Item> : null }
                        { transaction.status === LoanTransactionStatus.Reversed || transaction.status === LoanTransactionStatus.Failure ? <Descriptions.Item label="Reversal Comment" span={2}>{ transaction.reversalCommentText }</Descriptions.Item> : null }
                        <Descriptions.Item label="Created At"><SimpleDate date={transaction.createdAt} tooltip /> </Descriptions.Item>
                        { transaction.stripeInvoiceId ? <Descriptions.Item label="Stripe Invoice"><a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/${ process.env.NODE_ENV === 'production' ? '' : 'test/' }invoices/${ transaction.stripeInvoiceId }`}>View Invoice</a></Descriptions.Item> : null}
                        { transaction.commentText ? <Descriptions.Item label="Comment" span={2}>{ transaction.commentText }</Descriptions.Item> : null }
                    </Descriptions>
                </Col>

                { transaction.toPayments ?
                    <Col span={24}>
                        <TransactionToTable transaction={transaction} />
                    </Col>
                : null }
            </Row>
        );
    }

    render() {
        return (
            <Modal
                open={this.props.isVisible}
                title={<span><TableOutlined /> View Transaction Details</span>}
                footer={
                    <Button type="primary" onClick={this.props.close}>
                        Close
                    </Button>
                }
                onCancel={this.props.close}
            >
                {this.transactionDetails}
            </Modal>
        );
    }
}
